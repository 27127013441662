import React, { createContext, useState, useEffect, useContext } from 'react';
import { setStorage, getStorage } from "../modals/Storage";
import { API_ACCESS_URL } from "../modals/Constants";

const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
    const [balances, setBalances] = useState({
        accountBalance: 0,
        accountVaultBalance: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to fetch balances
    const fetchBalances = async (url, authSecret) => {
        try {
            setIsLoading(true);
            setError(null);
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Route': 'route-account-info',
                    'AuthToken': authSecret,
                },
            });

            const data = await res.json();

            if (!data.error) {
                setBalances({
                    accountBalance: data.data[0]["account_balance"],
                    accountVaultBalance: data.data[0]["account_vault_balance"],
                });
            } else {
                setError(data.error);
            }
        } catch (err) {
            console.error('Error fetching balances:', err);
            setError('Error fetching balances');
        } finally {
            setIsLoading(false);
        }
    };

    let accountId = "";
    let accountAuthSecret = "";

    if (getStorage("uid")) {
      accountId = getStorage("uid");
      accountAuthSecret = getStorage("secret");
    } else {
      accountId = "guest";
      accountAuthSecret = "guest";
    }

    useEffect(() => {   
      fetchBalances(API_ACCESS_URL + "?USER_ID=" + accountId, accountAuthSecret);
    }, []);  

    return (
        <BalanceContext.Provider value={{ balances, isLoading, error, fetchBalances }}>
            {children}
        </BalanceContext.Provider>
    );
};

export const useBalance = () => {
    return useContext(BalanceContext);
};
