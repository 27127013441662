import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import LoadingDialog from '../dialogs/LoadingDialog/dialog';
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import LogoutDailog from "../other-components/LogoutDailog";
import { openNewPage, copyText, AVATARS_ARR } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import '../../MainStyle.css';
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import { useBalance } from '../other-components/BalanceContext';

function Account() {
  const toastDialogRef = useRef(null);
  const {balances,fetchBalances } = useBalance();
  const navigate = useNavigate();
  const [isDialogActive, setDialogActive] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [notificationcount, setnotificationcount] = useState(false);
  const onToastChange = (data, msg) => { };
  const balance = balances?.accountBalance;
  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    showToast("Copy successful", 2500, 0, 1);
  };

  const signOutAccount = () => {
    setDialogActive(true);
  };


    useEffect(() => {
      if (!getStorage("uid")) {
        navigate('/login', { replace: true });
      }
    }, []);
    
  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    } else {
      getNotificationDetails();
    }
  }, []);

  const handleConfirm = () => {
    setDialogActive(false);
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  const handleCancel = () => {
    setDialogActive(false ); 
  };

  const refreshBalance = () => {
    showToast("Refresh successfully", 2500, 0, 1);
    fetchBalances(API_ACCESS_URL + "?USER_ID=" + getStorage('uid'), getStorage('secret'));
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  function getNotificationDetails() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-notifications",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);
        if (data.status_code === "success") {
          setnotificationcount(data.t_noofnotifications);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    requestAPI(API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1");
  }

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div className={[
        general.height100vh,
        general.respWidth,
        general.overflowScrollY,
        general.appContentsBackground,
        general.hideScrollBar,
      ].join(" ")}>
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />
        <div className="zoomAnimView">
          <div className={[styles.rowView, styles.alignFlexStart, styles.accountView].join(" ")}>
            <div className="h-w-70 ovf-hidden br-50" onClick={() => navigate("/main/Settings", { replace: false })}>
              {getStorage("avatarId") ? (
                <img
                  src={AVATARS_ARR[Number(getStorage("avatarId")) - 1]["avatarURL"]}
                  className="h-w-70"
                  alt="icon"
                />
              ) : (
                ""
              )}
            </div>

            <div className="col-view mg-l-15">
              <Link className="row-view txt-deco-n" to={"/vip"}>
                <div className="cl-white ft-wgt-500 ft-sz-1p25rem">
                  {getStorage("uname")}
                </div>
                {getStorage("accountLevel") ? (
                  <img
                    src={require(`../media/icons/vip_${getStorage("accountLevel")}_bedge.png`)}
                    className="w-65-p mg-l-10"
                    alt="icon"
                  />
                ) : null}
              </Link>

              <div className={general.AccountViewTopbarInfo} onClick={() => copyMyId()}>
                UID &nbsp; | &nbsp; {getStorage("uid")}
                <svg 
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="4"
                  width="16"
                >
                  <path
                    d="M13 12.4316V7.8125C13 6.2592 14.2592 5 15.8125 5H40.1875C41.7408 5 43 6.2592 43 7.8125V32.1875C43 33.7408 41.7408 35 40.1875 35H35.5163"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M32.1875 13H7.8125C6.2592 13 5 14.2592 5 15.8125V40.1875C5 41.7408 6.2592 43 7.8125 43H32.1875C33.7408 43 35 41.7408 35 40.1875V15.8125C35 14.2592 33.7408 13 32.1875 13Z"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="cl-white ft-sz-14 mg-t-5">
                Last Active: {getStorage("accountLastActive")}
              </div>
            </div>
          </div>

          <div className={[styles.accountOptionsView, styles.colView].join(" ")}>
            <div className="w-100 z-i-1">
              <div className={[styles.colView, styles.accountBalanceView].join(" ")}>
                <span className="cl-black">Account Balance:</span>
                <span className="cl-black ft-sz-23" style={{ display: "flex", alignItems: "center", minHeight:"1.58667rem", fontWeight:"700", fontSize:"20px" }}>
                  ₹{balance}
                  <img
                    src={require("../media/icons/refresh_icon.png")}
                    alt="Refresh"
                    onClick={refreshBalance}
                    style={{ width: "24px", height: "24px", cursor: "pointer", marginLeft: "10px" }}
                  />
                </span>
                <div className="line-hv-grey mg-t-10"></div>

                <div className={[general.rowView, general.spaceBetween, general.pd10px15px].join(" ")}>
                  <Link className={[general.textDecoNone, general.colView, general.viewCenter].join(" ")} to={"/recharge"}>
                    <img
                      src={require("../media/icons/deposit_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className={[general.colorGrey, general.fontS15Px].join(" ")}>
                      Deposit
                    </span>
                  </Link>

                  <Link className={[general.textDecoNone, general.colView, general.viewCenter].join(" ")} to={"/withdraw"}>
                    <img
                      src={require("../media/icons/withdrawl_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className={[general.colorGrey, general.fontS15Px].join(" ")}>
                      Withdraw
                    </span>
                  </Link>

                  <div className={[general.colView, general.viewCenter].join(" ")} onClick={() => openNewPage(getStorage("appDownloadURL"))}>
                    <img
                      src={require("../media/icons/download_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className={[general.colorGrey, general.fontS15Px].join(" ")}>
                      Download
                    </span>
                  </div>

                  <Link className={[general.textDecoNone, general.colView, general.viewCenter].join(" ")} to={"/vip"}>
                    <img
                      src={require("../media/icons/vip_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className={[general.colorGrey, general.fontS15Px].join(" ")}>
                      Vip
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className={[styles.accountShortCutOptionsView]}>
              <Link className={[general.rowView]} to={"/rechargerecords"}>
                <img
                  src={require("../media/icons/deposit_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[general.fontS17Px, general.colorBlack].join(" ")}>
                    Deposit
                  </span>
                  <span className={[general.fontS12Px, general.colorGrey].join(" ")}>
                    My Deposit History
                  </span>
                </div>
              </Link>

              <Link className="txt-deco-n row-view pd-15-10 br-10 bg-white" to={"/withdrawrecords"}>
                <img
                  src={require("../media/icons/withdrawl_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[general.fontS17Px, general.colorBlack].join(" ")}>
                    Withdraw
                  </span>
                  <span className={[general.fontS12Px, general.colorGrey].join(" ")}>
                    My Withdraw History
                  </span>
                </div>
              </Link>

              <Link className="txt-deco-n row-view pd-15-10 br-10 bg-white" to={"/myorders"}>
                <img
                  src={require("../media/icons/bet_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[general.fontS17Px, general.colorBlack].join(" ")}>
                    Bet
                  </span>
                  <span className={[general.fontS12Px, general.colorGrey].join(" ")}>
                    My Bet History
                  </span>
                </div>
              </Link>

              <Link className="txt-deco-n row-view pd-15-10 br-10 bg-white" to={"/transactions"}>
                <img
                  src={require("../media/icons/transactions_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[general.fontS17Px, general.colorBlack].join(" ")}>
                    Transactions
                  </span>
                  <span className={[general.fontS12Px, general.colorGrey].join(" ")}>
                    My Transactions History
                  </span>
                </div>
              </Link>
            </div>

            <div className={[styles.accountMoreOptionsView].join(" ")}>
              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                to={"/notification"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/notification_icon.png")}
                    alt="icon"
                  />
                  <span>Notification</span>
                </div>
                <div className="notify-count">{notificationcount > 0 ? notificationcount : "0"}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#666"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>
              <div className="line-hv-grey mg-t-8"></div>
              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                to={"/redeemgiftcard"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/gift_icon.png")}
                    alt="icon"
                  />
                  <span>Gifts</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#666"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>
              <div className="line-hv-grey mg-t-8"></div>
              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                to={"/redeemgiftcard"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/statistics_icon.png")}
                    alt="icon"
                  />
                  <span>Game Statistics</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#666"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>
              <div className="line-hv-grey mg-t-8"></div>
              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/language_icon.png")}
                    alt="icon"
                  />
                  <span>Language</span>
                </div>
                <span>English</span>
              </Link>
            </div>

            <div className="serviceCenter__container">
      <p>Service center</p>
      <div className="serviceCenter__container-items">
        <Link style={{textDecoration:"none"}}
        to={"/main/Settings"} >
        <div className="serviceCenter__container-items__item">
          <svg className="svg-icon icon-settingCenter">
            <use xlinkHref="#icon-settingCenter"></use>
          </svg>
          <span>Settings</span>
        </div>
        </Link>
        <Link style={{textDecoration:"none"}}
        to={"/main/Feedback"} >
        <div className="serviceCenter__container-items__item">
          <svg className="svg-icon icon-feedback">
            <use xlinkHref="#icon-feedback"></use>
          </svg>
          <span>Feedback</span>
        </div>
        </Link>
        <Link style={{textDecoration:"none"}}
        to={"/notification"} >
        <div className="serviceCenter__container-items__item">
          <svg className="svg-icon icon-notificationCenter">
            <use xlinkHref="#icon-notificationCenter"></use>
          </svg>
          <span>Notification</span>
        </div>
        </Link>
        <div className="serviceCenter__container-items__item">
          <svg className="svg-icon icon-serverTicket">
            <use xlinkHref="#icon-serverTicket"></use>
          </svg>
          <span>Customer Service</span>
        </div>
        <div className="serviceCenter__container-items__item">
          <svg className="svg-icon icon-guide">
            <use xlinkHref="#icon-guide"></use>
          </svg>
          <span>Beginner's Guide</span>
        </div>
        <div className="serviceCenter__container-items__item">
          <svg className="svg-icon icon-about">
            <use xlinkHref="#icon-about"></use>
          </svg>
          <span>About us</span>
        </div>
      </div>
    </div>

            <div className={[general.pd10px]}>
              <div
                className={[
                  general.Accountlogout,
                ].join(" ")}
                onClick={() => signOutAccount()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 48 48" fill="none">
                  <path
                    d="M14.5 8C13.8406 8.37652 13.2062 8.79103 12.6 9.24051C11.5625 10.0097 10.6074  10.8814 9.75 11.8402C6.79377 15.1463 5 19.4891 5 24.2455C5 34.6033 13.5066 43 24 43C34.4934 43 43 34.6033 43 24.2455C43 19.4891 41.2062 15.1463 38.25 11.8402C37.3926 10.8814 36.4375 10.0097 35.4 9.24051C34.7938 8.79103 34.1594 8.37652 33.5 8"
                    stroke="#f95959"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24 4V24"
                    stroke="#f95959"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="v-center">
                  <span className="mg-l-10">Log Out</span>
                </div>
              </div>
              <LogoutDailog
                isActive={isDialogActive}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="account" />
      </div>
    </div>
  );
}

export default Account;