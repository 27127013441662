import React, { useRef, useState, useEffect } from "react";
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import {
  WEBSITE_NAME,
  API_ACCESS_URL,
  AVATARS_ARR,
  getRandomInt,
  openNewPage,
} from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import NoticeDialog from "../dialogs/NoticeDialog2";
import SlideShow from "../other-components/SlideShow";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import ImportantNotice from "../other-components/ImportantNotice/Notice";
import ToastDialog from "../dialogs/ToastDialog/toast";
import { Box, Avatar, Typography, Grid } from "@mui/material";
import GameLoaderDailog from "../other-components/GameLoaderDailog";
import "../../MainStyle.css";
import { useBalance } from "../other-components/BalanceContext";

function Home() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [isLoadingShow, setLoadingShow] = useState(false);
  const { balances, fetchBalances } = useBalance();
  const [viewBoxNumber, setViewBoxNumber] = useState(0);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [pageConst, setConstants] = useState({
    accountId: "",
    accountBalance: 0,
    isDataLoaded: false,
    recordList: [],
    sliderImages: [],
    winnersRecordList: [],
  });
  const balance = balances?.accountBalance;
  const [gameLaunchUrl, setgameurl] = useState("");
  const [isApiConfigCorrect, setApiState] = useState(false);
  const profitList = [
    {
      name: "Mem***EFJ",
      rank: "NO1",
      rankImg: require("../media/icons/no1badge.png"),
      price: "₹4,105,463,048.82",
      avatar: "/assets/avatar-ea3b8ee9.png",
    },
    {
      name: "Mem***DEC",
      rank: "NO2",
      rankImg: require("../media/icons/no2badge.png"),
      price: "₹721,133,223.44",
      avatar: "/assets/avatar-ea3b8ee9.png",
    },
    {
      name: "Mem***HVK",
      rank: "NO3",
      rankImg: require("../media/icons/no3badge.png"),
      price: "₹533,806,333.20",
      avatar: "/assets/avatar-ea3b8ee9.png",
    },
    {
      name: "Mem***XTT",
      rank: "NO4",
      price: "₹454,286,093.24",
      avatar: "/assets/avatar-ea3b8ee9.png",
    },
    {
      name: "Mem***EME",
      rank: "NO5",
      price: "₹432,234,011.72",
      avatar: "/assets/avatar-ea3b8ee9.png",
    },
  ];

  

  const imageOptions = [
    "http://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_202307140102511fow.png",
    "https://ossimg.91admin123admin.com/91club/vendorlogo/vendorlogo_20240102165352mtql.png",
    "https://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_2023071401023322dy.png",
    "https://ossimg.91admin123admin.com/91club/vendorlogo/vendorlogo_202405081133481rmp.png",
    "https://ossimg.91admin123admin.com/91club/vendorlogo/vendorlogo_202411190309432kab.png",
    "http://ossimg.91admin123admin.com/91club/lotterycategory/lotterycategory_202307140102511fow.png",
  ];
  
  // Function to generate a random amount for txt2
  const generateRandomAmount = () => {
    const randomValue = Math.random();
    if (randomValue < 0.95) {
      return (Math.random() * (1000 - 5) + 5).toFixed(2);
    }

    return (Math.random() * (5000 - 1000) + 1000).toFixed(2);
  };
  
  const generateRandomWinner = () => {
    return {
      txt: `Mem***${Math.random().toString(36).substring(2, 5).toUpperCase()}`,
      image: AVATARS_ARR[Math.floor(Math.random() * 9)]["avatarURL"], 
      txt2: generateRandomAmount(), 
      image1: imageOptions[Math.floor(Math.random() * imageOptions.length)], 
    };
  };
  
  const winners = Array.from({ length: 6 }, () => generateRandomWinner());
  
  
  const [selectedGame, setSelectedGame] = useState(null);
  const [currentWinners, setCurrentWinners] = useState(winners);
  const [isDialogActive, setDialogActive] = useState(false);
  const [noticeDialogConst, setNoticeDialogConst] = useState({
    noticeTitle: "",
    noticeNote: "",
    noticeDialogShow: false,
  });

  // toast component
  const onToastChange = (data, msg) => {};

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const refreshData = () => {
    setConstants((previousState) => {
      return { ...previousState, isDataLoaded: false };
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWinners((prevWinners) => {
        const lastItem = prevWinners.pop();
        return [lastItem, ...prevWinners];
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const updateSliderImagesArr = (data) => {
    if (data.length > 0) {
      setConstants((previousState) => {
        return { ...previousState, sliderImages: data };
      });
    }
  };

  const showNotice = (data) => {
    if (data.length > 0) {
      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeTitle: data[0] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeNote: data[1] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeDialogShow: true };
      });
    } else {
      setNoticeDialogConst((previousState) => {
        return {
          ...previousState,
          noticeTitle: `💯${WEBSITE_NAME} is a trusted and profitable platform. <br> 🎧24x7 customer support. <br> 🥇No 1. Gaming platform. <br> 📊 High Sallary for agents. <br><br> 👉 Telegram 🔗 <a href="${getStorage(
            "telegramURL"
          )}">Click Here</a> 🔗 <br><br> ⭐ Start Earning High Today ⭐ `,
        };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeDialogShow: true };
      });
    }
  };

  const updateNoticeDialogState = (data) => {
    setNoticeDialogConst((previousState) => {
      return { ...previousState, noticeDialogShow: data };
    });
  };

  function getAccountInfo(accountId, accountAuthSecret) {
    const fecthApiData = async (url, authSecret) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-account-info",
            AuthToken: authSecret,
          },
        });
        const data = await res.json();
        setLoadingShow(false);

        if (data.status_code == "success") {
          let accountAvatarId = data.data[0]["account_avatar_id"];
          let accountUserName = data.data[0]["account_user_name"];
          let accountBalance = data.data[0]["account_balance"];
          let accountWinningBalance = data.data[0]["account_w_balance"];
          let accountCommissionBalance = data.data[0]["account_c_balance"];
          let accountLevel = data.data[0]["account_level"];
          let accountLastActive = data.data[0]["account_last_active"];

          let service_app_status = data.data[0]["service_app_status"];
          let service_min_recharge = data.data[0]["service_min_recharge"];
          let service_min_withdraw = data.data[0]["service_min_withdraw"];
          let service_recharge_option = data.data[0]["service_recharge_option"];
          let service_telegram_url = data.data[0]["service_telegram_url"];
          let service_payment_url = data.data[0]["service_payment_url"];
          let service_livechat_url = data.data[0]["service_livechat_url"];
          let service_imp_message = data.data[0]["service_imp_message"];
          let service_app_download_url =
            data.data[0]["service_app_download_url"];

          setStorage("avatarId", accountAvatarId);
          setStorage("uname", accountUserName);
          setStorage("balance", accountBalance);
          setStorage("cbalance", accountCommissionBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("accountLevel", accountLevel);
          setStorage("accountLastActive", accountLastActive);
          setStorage("minwithdraw", service_min_withdraw);
          setStorage("minrecharge", service_min_recharge);
          setStorage("rechargeoptions", service_recharge_option);
          setStorage("telegramURL", service_telegram_url);
          setStorage("liveChatURL", service_livechat_url);
          setStorage("paymentURL", service_payment_url);
          setStorage("impMessage", service_imp_message);
          setStorage("appDownloadURL", service_app_download_url);
          // setStorage("winnersArr", JSON.stringify(data.winnersArr));
          setConstants({
            ...pageConst,
            accountBalance: accountBalance,
            isDataLoaded: true,
          });

          if (service_app_status == "OFF") {
            navigate("/um", { replace: true });
          } else {
            updateWinnersList(data.winnersArr);
            showNotice(data.noticeArr);
            updateSliderImagesArr(data.slideShowList);
          }
        } else if (data.status_code == "authorization_error") {
          if (deleteStorage()) {
            navigate("/login", { replace: true });
          }
        }
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if (!isLoadingShow && !pageConst.isDataLoaded) {
      setLoadingShow(true);
      fecthApiData(API_ACCESS_URL + "?USER_ID=" + accountId, accountAuthSecret);
    }
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  useEffect(() => {
    if (selectedGame) {
      const { gameId, gameType, provider } = selectedGame;

      switch (provider) {
        case "jdb": {
          const iv = "f1ab7cea8106a3e4";
          const key = "b4d70df8d5c2857c";
          const uid = getStorage("uid");
          const serverUrl = "https://xgoallottery.in";
          const parent = "sxmapiz";
          const gType = gameId;
          const baseUrl = "https://sxm.in.net/jdb/post";
          const mType = gameType;

          setgameurl(
            `${baseUrl}?iv=${iv}&key=${key}&uid=${uid}&serverUrl=${serverUrl}&parent=${parent}&gType=${gType}&mType=${mType}`
          );
          break;
          
        }
        case "jili": {
          const agentId = "Glexer_Seamless";
          const agentKey = "d0f6061be07bb62a9aff47c7962df9a4cf26bf0c";
          const uid = getStorage("uid");
          const referrerUrl = "https://xgoallottery.in";
          const gameid = gameId;
          const baseUrl = "https://sxm.serverprovider.in.net/post";

          setgameurl(
            `${baseUrl}?gameId=${gameid}&mobile=${uid}&agentId=${agentId}&agentKey=${agentKey}&referrerUrl=${referrerUrl}`
          );
          break;
        }
        default:
          setgameurl("");
          break;
      }
    }
  }, [selectedGame]);

  const openGameById = () => {
    if (gameLaunchUrl !== "") {
      setIsLoadingSpinner(true);
      setDialogActive(false);

      const UpdateNow = async () => {
        setTimeout(async () => {
          const requestAPI = async (url, formData) => {
            try {
              const res = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Route: "route-transfer-balances-gameapi",
                  AuthToken: getStorage("secret"),
                },
                body: JSON.stringify(formData),
              });

              const data = await res.json();
              console.log("API Response:", data);

              if (data.status_code === "authorization_error") {
                showToast("Authorization Error!", 2500, 0, 2);
              } else if (data.status_code === "balance_error") {
                showToast("Error: 502 Balance can't be empty", 2500, 0, 2);
              } else if (data.status_code === "success") {
                setApiState(true);
              }
            } catch (error) {
              console.error("API Request Error:", error);
              showToast("505: Technical Error", 2500, 0, 2);
            }
          };

          if (balance >= 0) {
            const formData = {
              USER_ID: getStorage("uid"),
              NEW_BALANCE: balance,
            };

            await requestAPI(API_ACCESS_URL, formData);
          }
        }, 1000);
      };

      UpdateNow();

      setTimeout(() => {
        setIsLoadingSpinner(false);
        window.open(gameLaunchUrl, "_blank");
      }, 3000);
    } else {
      showToast("Game configuration is not complete.", 2500, 0, 2);
    }
  };

  const OpenGameHandler = (gameId, gameType, provider) => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      setSelectedGame({ gameId, gameType, provider });
      setDialogActive(true);
    }
  };

  const handleCancel = () => {
    setDialogActive(false);
    setIsLoadingSpinner(false);
  };
  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const updateWinnersList = (data) => {
    let tempData = [];

    tempData.push(
      <div key={61}>
        <div className={[styles.winnersListStageView]}>
          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[1]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no2_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no2_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[1]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[1]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--90">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[0]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no1_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no1_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[0]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[0]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[2]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no3_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no3_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[2]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[2]["winning_amount"]}
            </div>
          </div>
        </div>
      </div>
    );

    for (let i = 3; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className={[
            general.rowView,
            general.spaceBetween,
            styles.winnerPersonView,
            i > 3 ? general.mgT10px : "",
          ].join(" ")}
        >
          <div className="row-view">
            <span className={[general.fontS20Px, general.width20px].join(" ")}>
              {i + 1}
            </span>

            <div className="h-w-45 ovf-hidden br-50 mg-l-40">
              <img
                src={AVATARS_ARR[Number(data[i]["avatar_id"])]["avatarURL"]}
                className="h-w-45"
                alt="icon"
              />
            </div>
            <span className={[general.fontS16Px, general.mgL10px].join(" ")}>
              {data[i]["mobile_num"]}
            </span>
          </div>
          <span className={[styles.winnerWonAmountView]}>
            ₹{data[i]["winning_amount"]}
          </span>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, winnersRecordList: tempData };
    });
  };

  const openGame = (url) => {
    if (getStorage("uid")) {
      navigate("/" + url, { replace: false });
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    deleteStorage();
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    let accountId = "";
    let accountAuthSecret = "";

    if (getStorage("uid")) {
      accountId = getStorage("uid");
      accountAuthSecret = getStorage("secret");
    } else {
      accountId = "guest";
      accountAuthSecret = "guest";
    }

    getAccountInfo(accountId, accountAuthSecret);
  }, [pageConst.sliderImages, pageConst.winnersList, isApiConfigCorrect]);

  const minigames = [
    {
      gameId: 9,
      gameType: 9023,
      imageUrl: require("../media/jdb/9023.png"),
      provider: "jdb",
    },
    {
      gameId: 9,
      gameType: 9014,
      imageUrl: require("../media/jdb/9014.png"),
      provider: "jdb",
    },
    {
      gameId: 92,
      gameType: 92022,
      imageUrl: require("../media/jdb/92022.png"),
      provider: "jdb",
    },
    {
      gameId: 9,
      gameType: 9018,
      imageUrl: require("../media/jdb/9018.png"),
      provider: "jdb",
    },
    {
      gameId: 9,
      gameType: 9019,
      imageUrl: require("../media/jdb/9019.png"),
      provider: "jdb",
    },
    {
      gameId: 9,
      gameType: 9016,
      imageUrl: require("../media/jdb/9016.png"),
      provider: "jdb",
    },
    {
      gameId: 9,
      gameType: 9017,
      imageUrl: require("../media/jdb/9017.png"),
      provider: "jdb",
    },
    {
      gameId: 30,
      gameType: 30009,
      imageUrl: require("../media/jdb/30009.png"),
      provider: "jdb",
    },
  ];

  const slots = [
    {
      gameId: 14,
      gameType: 14036,
      imageUrl: require("../media/jdb/14036.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14027,
      imageUrl: require("../media/jdb/14027.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14045,
      imageUrl: require("../media/jdb/14045.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14054,
      imageUrl: require("../media/jdb/14054.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14065,
      imageUrl: require("../media/jdb/14065.png"),
      provider: "jdb",
    },

    {
      gameId: 14,
      gameType: 14042,
      imageUrl: require("../media/jdb/14042.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14080,
      imageUrl: require("../media/jdb/14080.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14041,
      imageUrl: require("../media/jdb/14041.png"),
      provider: "jdb",
    },
    {
      gameId: 14,
      gameType: 14055,
      imageUrl: require("../media/jdb/14055.png"),
      provider: "jdb",
    },
  ]
  const platformsuggestions = [
    {
      gameId: 9,
      gameType: 9023,
      imageUrl: require("../media/jdb/9023.png"),
      provider: "jdb",
      oddspercentage: "97.4",
    },
    {
      gameId: 92,
      gameType: 92022,
      imageUrl: require("../media/jdb/92022.png"),
      provider: "jdb",
      oddspercentage: "97.8",
    },
    {
      gameId: 9,
      gameType: 9014,
      imageUrl: require("../media/jdb/9014.png"),
      provider: "jdb",
      oddspercentage: "98.7",
    },
    {
      gameId: 9,
      gameType: 9019,
      imageUrl: require("../media/jdb/9019.png"),
      provider: "jdb",
      oddspercentage: "98.7",
    },
    {
      gameId: 51,
      gameType: 0,
      imageUrl: require("../media/jili/51.png"),
      provider: "jili",
      oddspercentage: "96.2",
    },
    {
      gameId: 235,
      gameType: 0,
      imageUrl: require("../media/jili/235.png"),
      provider: "jili",
      oddspercentage: "97.9",
    },
  ];

  const populargames = [
    {
      gameId: 109,
      gameType: 0,
      imageUrl: require("../media/jili/109.png"),
      provider: "jili",
      oddspercentage: "97.4",
    },
    {
      gameId: 27,
      gameType: 0,
      imageUrl: require("../media/jili/27.png"),
      provider: "jili",
      oddspercentage: "97.8",
    },
    {
      gameId: 47,
      gameType: 0,
      imageUrl: require("../media/jili/47.png"),
      provider: "jili",
      oddspercentage: "98.7",
    },
    {
      gameId: 35,
      gameType: 0,
      imageUrl: require("../media/jili/35.png"),
      provider: "jili",
      oddspercentage: "98.7",
    },

    {
      gameId: 49,
      gameType: 0,
      imageUrl: require("../media/jili/49.png"),
      provider: "jili",
      oddspercentage: "97.9",
    },
    {
      gameId: 82,
      gameType: 0,
      imageUrl: require("../media/jili/82.png"),
      provider: "jili",
      oddspercentage: "97.9",
    },
    {
      gameId: 74,
      gameType: 0,
      imageUrl: require("../media/jili/74.png"),
      provider: "jili",
      oddspercentage: "97.9",
    },
    {
      gameId: 45,
      gameType: 0,
      imageUrl: require("../media/jili/45.png"),
      provider: "jili",
      oddspercentage: "97.9",
    },
  ];

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <NoticeDialog
          intentData={noticeDialogConst}
          updateState={updateNoticeDialogState}
        />
        <GameLoaderDailog
          isActive={isDialogActive}
          onConfirm={openGameById}
          onCancel={handleCancel}
        />
        <div className={[styles.homeView, general.posRelative].join(" ")}>
          <div
            className={[
              styles.topBar,
              general.rowView,
              general.spaceBetween,
              general.width100,
              general.appContentsBackground,
            ].join(" ")}
          >
            <img
              className={`h-40-p mg-l-10`}
              src="/config/app_logo_fill.png"
              alt="icon"
            />

            <div>
              <svg
                onClick={() => openNewPage("#/notification")}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 61 60"
                width="35"
                height="35"
                fill="#f95959"
              >
                <path
                  opacity="0.4"
                  d="M43 51.25H18C10.5 51.25 5.5 47.5 5.5 38.75V21.25C5.5 12.5 10.5 8.75 18 8.75H43C50.5 8.75 55.5 12.5 55.5 21.25V38.75C55.5 47.5 50.5 51.25 43 51.25Z"
                  fill="#f95959"
                />
                <path
                  d="M30.4987 32.1818C28.3987 32.1818 26.2737 31.5318 24.6487 30.2068L16.8237 23.9568C16.4598 23.6408 16.2319 23.1966 16.1876 22.7167C16.1433 22.2369 16.286 21.7584 16.5858 21.3812C16.8857 21.0039 17.3196 20.757 17.7971 20.6919C18.2746 20.6268 18.7588 20.7486 19.1487 21.0318L26.9737 27.2818C28.8737 28.8068 32.0987 28.8068 33.9987 27.2818L41.8237 21.0318C42.6237 20.3818 43.8237 20.5068 44.4487 21.3318C45.0987 22.1318 44.9737 23.3318 44.1487 23.9568L36.3237 30.2068C34.7237 31.5318 32.5987 32.1818 30.4987 32.1818Z"
                  fill="#f95959"
                />
              </svg>
              <svg
                onClick={() => openNewPage(getStorage("appDownloadURL"))}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="50"
                height="35"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.9999 29.0001L12 17.0001H19.9999V6.00012H27.9999V17.0001H35.9999L23.9999 29.0001Z"
                  fill="#f95959"
                  stroke="#f95959"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 37H6"
                  stroke="#f95959"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M34 44H14"
                  stroke="#f95959"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>

          <div className={[general.pd15px]}>
            <SlideShow dataList={pageConst.sliderImages} />
          </div>

          <div className="pd-0-15">
            <ImportantNotice />
          </div>
          {isLoadingSpinner && (
            <div className="body-spinner-loader">
              <div className="lds-hourglass"></div>
            </div>
          )}
          <div
            className={[
              general.rowView,
              general.alignFlexStart,
              general.mgT20px,
              styles.pd10px,
            ].join(" ")}
          >
            <div className={[styles.homeGameCategory]}>
              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 0 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(0)}
              >
                <img
                  src={require("../media/icons/backgrounds/13.png")}
                  alt="icon"
                />
                <span>Lottery</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 1 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(1)}
              >
                <img
                  src={require("../media/icons/backgrounds/14.png")}
                  alt="icon"
                />
                <span>Mini games</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 5 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(5)}
              >
                <img
                  src={require("../media/icons/backgrounds/22.png")}
                  alt="icon"
                />
                <span>Popular</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 3 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(3)}
              >
                <img
                  src={require("../media/icons/backgrounds/23.png")}
                  alt="icon"
                />
                <span>Slots</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 4 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(4)}
              >
                <img
                  src={require("../media/icons/backgrounds/24.png")}
                  alt="icon"
                />
                <span>Fishing</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 5 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(5)}
              >
                <img
                  src={require("../media/icons/backgrounds/25.png")}
                  alt="icon"
                />
                <span>PVC</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  viewBoxNumber == 6 ? styles.bgActive : styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(6)}
              >
                <img
                  src={require("../media/icons/backgrounds/26.png")}
                  alt="icon"
                />
                <span>Casino</span>
              </div>
            </div>

            {/* in-build games */}
            <div
              className={[
                styles.flex,
                styles.colView,
                styles.stickyView,
                viewBoxNumber != 0 && viewBoxNumber != 2 && viewBoxNumber != 7
                  ? styles.hideView
                  : "",
              ].join(" ")}
            >
              <div
                className="ps-rl txt-deco-n"
                onClick={() => openGame("home/AllLotteryGames/WinGo?id=1")}
              >
                <div className={[styles.homeGameViewBox]}>
                  <img
                    className="h-80-p ps-ab ps-rgt-10 max-height"
                    src={require("../Games/Wingo/icons/4.png")}
                  />
                  <div
                    className="col-view ps-ab ps-lf ps-tp pd-15"
                    style={{ top: "-5px" }}
                  >
                    <span className="ft-sz-20 ft-wgt-700 cl-white ">
                      Win Go
                    </span>
                    <span className="ft-sz-12 cl-white mg-t-5 opacity-07">
                      Guess Number
                    </span>
                    <span className="ft-sz-12 cl-white opacity-07">
                      Green/Red/Purple to win
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="ps-rl txt-deco-n mg-t-10"
                onClick={() => openGame("home/AllLotteryGames/WinGo?id=2")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../Games/Wingo/icons/4.png")}
                />
                <div
                  className="col-view ps-ab ps-lf ps-tp pd-15"
                  style={{ top: "-5px" }}
                >
                  <span className="ft-sz-20 ft-wgt-700 cl-white ">
                    Win Go 3Min
                  </span>
                  <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                    Guess Number
                  </span>
                  <span className="ft-sz-12 cl-white opacity-07">
                    Big/Small/Odd/Even
                  </span>
                </div>
              </div>

              <div
                className="ps-rl txt-deco-n mg-t-10"
                onClick={() => openGame("home/AllLotteryGames/K3?id=1")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../icons/dice_lottery_icon.png")}
                />
                <div
                  className="col-view ps-ab ps-lf ps-tp pd-15"
                  style={{ top: "-5px" }}
                >
                  <span className="ft-sz-20 ft-wgt-700 cl-white ">
                    K3 Lottery
                  </span>
                  <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                    Guess Number
                  </span>
                  <span className="ft-sz-12 cl-white opacity-07">
                    Green/Red/Purple to win
                  </span>
                </div>
              </div>
              <div
                className="ps-rl txt-deco-n mg-t-10"
                onClick={() => openGame("home/AllLotteryGames/K3?id=2")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../icons/dice_lottery_icon.png")}
                />
                <div
                  className="col-view ps-ab ps-lf ps-tp pd-15"
                  style={{ top: "-5px" }}
                >
                  <span className="ft-sz-20 ft-wgt-700 cl-white ">
                    K3 Lottery 3Min
                  </span>
                  <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                    Drop balls
                  </span>
                  <span className="ft-sz-12 cl-white opacity-07">
                    Hit Multiplier to win
                  </span>
                </div>
              </div>
              <div
                className="ps-rl txt-deco-n mg-t-10"
                onClick={() => openGame("Wheelocity")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../icons/trxlottery_icon.png")}
                />
                <div
                  className="col-view ps-ab ps-lf ps-tp pd-15"
                  style={{ top: "-5px" }}
                >
                  <span className="ft-sz-23 ft-wgt-700 cl-white">
                    Wheelocity
                  </span>
                  <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                    Guess Animal Number
                  </span>
                  <span className="ft-sz-12 cl-white opacity-07">
                    Elephant/Tiger/Crown to win
                  </span>
                </div>
              </div>
              <Link
                to="/home/AllGames?type=lottery"
                style={{ textDecoration: "none" }}
              >
                <button className="look_all">
                  <img
                    src={require("../media/icons/viewallicon.png")}
                    alt="View All Icon"
                  />{" "}
                  View All
                </button>
              </Link>
            </div>

            {/* 3rd party games */}
            <div style={{width:"100%"}}
              className={[
                styles.stickyView,
                viewBoxNumber == 0 ? styles.hideView : "",
              ].join(" ")}
            >
              {/* mini games */}
              <div
                className={[
                  styles.homeAdditionalGamesView,
                  viewBoxNumber !== 1 ? styles.hideView : "",
                ].join(" ")}
              >
                {minigames.map(({ gameId, gameType, imageUrl, provider }) => (
                  <div
                    key={gameId}
                    onClick={() => OpenGameHandler(gameId, gameType, provider)}
                  >
                    <img src={imageUrl} alt={`game-icon-${gameId}`} />
                  </div>
                ))}
              </div>
              {viewBoxNumber === 1 && (
                <Link
                  to="/home/AllGames?type=mini_games"
                  style={{ textDecoration: "none" }}
                >
                  <button className="look_all">
                    <img
                      src={require("../media/icons/viewallicon.png")}
                      alt="View All Icon"
                    />{" "}
                    View All
                  </button>
                </Link>
              )}
              {/* SLOTS */}
              <div
                className={[
                  styles.platformrec,
                  viewBoxNumber != 3 ? styles.hideView : "",
                ].join(" ")}
              >
                <div className={[styles.title].join(" ")}>
                 <img
                 src="https://www.jdbgaming.com/assets/logo-gradient-b7a535e1.svg" alt="JILI"
                 />
                 <span style={{marginLeft:"5px"}}>Provider <b>Enchanced RTP</b></span>
                </div>
                <div className={[styles.homeAdditionalGamesView3].join(" ")}>
                  {slots.map(
                    ({
                      gameId,
                      gameType,
                      imageUrl,
                      provider,
                      oddspercentage,
                    }) => (
                      <div
                        key={gameId}
                        onClick={() =>
                          OpenGameHandler(gameId, gameType, provider)
                        }
                      >
                        <img src={imageUrl} alt={`game-icon-${gameId}`} />
                      </div>
                    )
                  )}
                   </div>
                  <div
                    className={[styles.title].join(" ")}
                  >
                    <img
                 src="https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/logo_jili_us-en.png" alt="JILI"
                 />
                 <span style={{marginLeft:"5px"}}>Provider <b>Enchanced RTP</b></span>
                 </div>
                 <div className={[styles.homeAdditionalGamesView3].join(" ")}>
                  {populargames.map(
                    ({
                      gameId,
                      gameType,
                      imageUrl,
                      provider,
                      oddspercentage,
                    }) => (
                      <div
                        key={gameId}
                        onClick={() =>
                          OpenGameHandler(gameId, gameType, provider)
                        }
                      >
                        <img src={imageUrl} alt={`game-icon-${gameId}`} />
                      </div>
                    )
                  )}
               </div>
               {viewBoxNumber === 3 && (
                <Link
                  to="/home/AllGames?type=slots"
                  style={{ textDecoration: "none" }}
                >
                  <button className="look_all">
                    <img
                      src={require("../media/icons/viewallicon.png")}
                      alt="View All Icon"
                    />{" "}
                    View All
                  </button>
                </Link>
              )}
              </div>
              

              {/* rummy games */}
              <div
                className={[
                  styles.homeAdditionalGamesView,
                  viewBoxNumber != 4 ? styles.hideView : "",
                ].join(" ")}
                onClick={() => showToast("Coming soon!")}
              >
                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhzldaiHbPO4tsq1eoIK1LeeXY9tJpqM3JeIqvsmdxu9QJjDp3kAGqt5Q0VaJnJSsemhjof4A2k3aRsw3DQP6DtJjL2RRglZ7wXzQqZMM7pTRJ555rF2MKDA4T_uYNdueOOJHNttyL949EUAALPlv5PE3NvA0MoeLyNFOP5G_AHRpmtGCvgddbHP3Ts-2g/s1600/vendorlogo_202401161815294l5u.webp"
                    alt="icon"
                  />
                </div>

                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgtjUS1d1BwyARwM_CikTdgpjr4aFUdaA8M_kvCHw9hcDxRgW6lWUDal0epM3qvxkX82gFh5RrM6fz9GTBblLYiTp4ZOVk1qkjVc7snIYLqo5fP_wjwPtOqxD4UFIoEX2Rhk9_y2za4lcHtQGZ2knkaU6mFYuukfs9Hm_tL56sEgsS4IK7Sj1JmSFV-gOA/s1600/vendorlogo_202401161815398gx3.webp"
                    alt="icon"
                  />
                </div>
              </div>

              {/* popular*/}
              <div
                className={[
                  styles.platformrec,
                  viewBoxNumber != 5 ? styles.hideView : "",
                ].join(" ")}
              >
                <div className={[styles.title].join(" ")}>
                  <svg
                    width="30"
                    height="25"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="gameRec"
                  >
                    <path
                      d="M23.6259 7.87715L25.7577 12.1408C26.045 12.7304 26.8161 13.2898 27.4662 13.4108L31.3216 14.0458C33.786 14.454 34.3606 16.2381 32.5916 18.0222L29.5829 21.0309C29.0839 21.5298 28.7967 22.5126 28.963 23.2232L29.8248 26.9425C30.5052 29.8757 28.9328 31.0247 26.3474 29.4826L22.7339 27.3356C22.0837 26.9425 20.9951 26.9425 20.345 27.3356L16.7315 29.4826C14.1461 31.0096 12.5737 29.8757 13.2541 26.9425L14.1159 23.2232C14.2822 22.5277 13.9949 21.545 13.496 21.0309L10.4872 18.0222C8.71829 16.2532 9.29282 14.4691 11.7573 14.0458L15.6127 13.4108C16.2628 13.305 17.0339 12.7304 17.3212 12.1408L19.453 7.87715C20.5869 5.5639 22.4617 5.5639 23.6259 7.87715Z"
                      fill="#FE6868"
                    />
                    <path
                      d="M12.4531 8.69355H3.3815C2.76161 8.69355 2.24756 8.17949 2.24756 7.5596C2.24756 6.93971 2.76161 6.42566 3.3815 6.42566H12.4531C13.073 6.42566 13.587 6.93971 13.587 7.5596C13.587 8.17949 13.073 8.69355 12.4531 8.69355ZM7.91728 29.8605H3.3815C2.76161 29.8605 2.24756 29.3465 2.24756 28.7266C2.24756 28.1067 2.76161 27.5926 3.3815 27.5926H7.91728C8.53717 27.5926 9.05123 28.1067 9.05123 28.7266C9.05123 29.3465 8.53717 29.8605 7.91728 29.8605ZM4.89343 19.277H3.3815C2.76161 19.277 2.24756 18.763 2.24756 18.1431C2.24756 17.5232 2.76161 17.0091 3.3815 17.0091H4.89343C5.51332 17.0091 6.02738 17.5232 6.02738 18.1431C6.02738 18.763 5.51332 19.277 4.89343 19.277Z"
                      fill="#FE6868"
                    />
                  </svg>
                  <span>Platform recommendation</span>
                </div>
                <div className={[styles.homeAdditionalGamesView2].join(" ")}>
                  {platformsuggestions.map(
                    ({
                      gameId,
                      gameType,
                      imageUrl,
                      provider,
                      oddspercentage,
                    }) => (
                      <div
                        key={gameId}
                        onClick={() =>
                          OpenGameHandler(gameId, gameType, provider)
                        }
                      >
                        <img src={imageUrl} alt={`game-icon-${gameId}`} />
                        <div className="odds-calculation">
                          odds of {oddspercentage}%
                        </div>
                      </div>
                    )
                  )}
                  <div
                    style={{ marginBottom: "-30px" }}
                    className={[styles.title].join(" ")}
                  >
                    <svg
                      width="30"
                      height="25"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="gameRec"
                    >
                      <path
                        d="M23.6259 7.87715L25.7577 12.1408C26.045 12.7304 26.8161 13.2898 27.4662 13.4108L31.3216 14.0458C33.786 14.454 34.3606 16.2381 32.5916 18.0222L29.5829 21.0309C29.0839 21.5298 28.7967 22.5126 28.963 23.2232L29.8248 26.9425C30.5052 29.8757 28.9328 31.0247 26.3474 29.4826L22.7339 27.3356C22.0837 26.9425 20.9951 26.9425 20.345 27.3356L16.7315 29.4826C14.1461 31.0096 12.5737 29.8757 13.2541 26.9425L14.1159 23.2232C14.2822 22.5277 13.9949 21.545 13.496 21.0309L10.4872 18.0222C8.71829 16.2532 9.29282 14.4691 11.7573 14.0458L15.6127 13.4108C16.2628 13.305 17.0339 12.7304 17.3212 12.1408L19.453 7.87715C20.5869 5.5639 22.4617 5.5639 23.6259 7.87715Z"
                        fill="#FE6868"
                      />
                      <path
                        d="M12.4531 8.69355H3.3815C2.76161 8.69355 2.24756 8.17949 2.24756 7.5596C2.24756 6.93971 2.76161 6.42566 3.3815 6.42566H12.4531C13.073 6.42566 13.587 6.93971 13.587 7.5596C13.587 8.17949 13.073 8.69355 12.4531 8.69355ZM7.91728 29.8605H3.3815C2.76161 29.8605 2.24756 29.3465 2.24756 28.7266C2.24756 28.1067 2.76161 27.5926 3.3815 27.5926H7.91728C8.53717 27.5926 9.05123 28.1067 9.05123 28.7266C9.05123 29.3465 8.53717 29.8605 7.91728 29.8605ZM4.89343 19.277H3.3815C2.76161 19.277 2.24756 18.763 2.24756 18.1431C2.24756 17.5232 2.76161 17.0091 3.3815 17.0091H4.89343C5.51332 17.0091 6.02738 17.5232 6.02738 18.1431C6.02738 18.763 5.51332 19.277 4.89343 19.277Z"
                        fill="#FE6868"
                      />
                    </svg>
                    <span>Popular</span>
                  </div>
                  {populargames.map(
                    ({
                      gameId,
                      gameType,
                      imageUrl,
                      provider,
                      oddspercentage,
                    }) => (
                      <div
                        key={gameId}
                        onClick={() =>
                          OpenGameHandler(gameId, gameType, provider)
                        }
                      >
                        <img src={imageUrl} alt={`game-icon-${gameId}`} />
                        <div className="odds-calculation">
                          odds of {oddspercentage}%
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {/* Sports games */}
              <div
                className={[
                  styles.homeAdditionalGamesView,
                  viewBoxNumber != 6 ? styles.hideView : "",
                ].join(" ")}
                onClick={() => showToast("Coming soon!")}
              >
                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh5eL9UxL9r7bnXgHkmnWqrWmvuU-mxdyLRb3SWh8aqQAMSvIvwdm_1fq1QIs27cgdnYScx28Ns0ayLVz_3ocan4PKx3CNSci69CjlNAO-FeFwjQWdMXJzxutEiB5vPVXa_MWujI7MpUmnaWUbdNocD2wx_EnDOapU9iju2LFAuyJ_tzh46eAHOK4b7LVs/s1600/vendorlogo_20240116180850im3d.webp"
                    alt="icon"
                  />
                </div>

                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgjjXNO5yvUbL55OknSyGBv7sjlLbeh_nRF5Tah6BJAntTnn5HTuesip35apDCHGhLIOVxbgls723ZIA2HG_gDv_Y15Bz7dZeMZhPuEJfUDmOhdKqKmN5MPYC_ity112COEUJ6QOJSSL3prDZN5SM2CydIR88Q_y1KhWvAyay-0jrsd8gyAIwIEGWnChfU/s1600/vendorlogo_20240116181011v5fb.webp"
                    alt="icon"
                  />
                </div>

                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiuO1OXb-HzG__MTNGweU_sgMr0uAxHS96_RACrJHhF34GgTV4fk1zxvaM0ZDOuTJz1O1Bc0IRIjfucsKj8rhuydrg6V2b1ticCQ2f3I5JLfeCczbQK7EoD-ikJOWaEXxuBsAICO-3aNhxSah6LyO6Oxkys_kmBakAJ3ilUbIXu83vcRJTsE_wUmYIlp5s/s1600/vendorlogo_20240116181140kbrq.webp"
                    alt="icon"
                  />
                </div>

                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjTGcoSk6KOPSvb7Av3wC3n4ksJ8RYiaAyvPZzf8pvWyx13iDGzsasYYDIJKXfNfHsNnKwtyC11AgAdHu5wlZ6d4uZiqmiyBKbOdUoUuqEX0B0NOC6uPTHyUb9Zkbg5k1BsBWiR3_r84Z8Xy4rlkmlIOS7ghJ3VOQ4ZyZonRD9oMlZUCLweuvI39Jqi8BA/s1600/vendorlogo_20240116181151vd4w.webp"
                    alt="icon"
                  />
                </div>

                <div>
                  <img
                    src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgFpXUGFtEct2gewiuzo8EVmEhqEn4d3W_7QXd4oQBO6Rt5ozIuxLkc7KMEaJRfGAvWpAiyhRizF7JTgg4tbYyPt_C_lCc2DVDKeCUR-bi2qeyurCT0AR8XNsKV3x6-d_4HMJhNiET4ZxkuVIysAQN1kwJ6b3AyhIlWCyx7rO5rLN5RLQsELsjmEvZGRF4/s1600/vendorlogo_20240116181202hhjt.webp"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Winning Information */}
          <div className="min-h mg-t-20">
            <Box display="flex" alignItems="center" mt={2} mb={-1}>
              <div
                className={[
                  styles.homeAdditionalGamesTitle,
                  general.Black,
                ].join(" ")}
              >
                <span></span>
                Winning Information
              </div>
            </Box>
            <Box sx={{ mt: 2, margin: 1 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5px",
          marginRight: "5px",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 1 }}
        >
          {currentWinners.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              mb={2}
              sx={{
                opacity: 1,
                transform: "translateY(0)",
                animation: `fadeInOut 2s ease-in-out ${index * 0.3}s`,
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  height: "120%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2.5,
                      mt: "7px",
                      ml: "2px",
                    }}
                  >
                    <Box sx={{ position: "relative",display:"flex", justifyContent:"center" }}>
                      <img
                        src={item.image}
                        alt=""
                        style={{
                          width:"50px",
                          height: "50px",
                          borderRadius: "50%",
                          border: "0.5px solid white",
                          margin: 1,
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{ color: "#1E2637", fontSize: "13px" }}
                    >
                      {item.txt}
                    </Typography>
                  </Box>

                  <Box sx={{ flexGrow: 1 }} />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: "7px",
                      gap: 1,
                    }}
                  >
                    <img
                      src={item.image1}
                      alt=""
                      style={{
                        width: "70px",
                        height: "50px",
                        borderRadius: "2px",
                        background:
                          "linear-gradient(180deg, #f95959 0%, #ff9a8e 100%)",
                          borderRadius:"8px"
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "left",
                        marginRight: 4,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#1E2637",
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Receive ₹{item.txt2}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1E2637",
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Winning Amount
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
          </div>
        </div>
        <div
          className={[styles.homeAdditionalGamesTitle, general.Black].join(" ")}
        >
          <span></span>
          Today's earnings chart
        </div>
        <div className="min-h">
          <Box
            sx={{
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              {profitList.map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  sx={{
                    padding: "8px 0",
                  }}
                >
                  <Avatar
                    src={item.avatar}
                    alt={item.name}
                    sx={{ width: 40, height: 40 }}
                  />
                  <Box mr={4} flex={1}>
                    <Typography variant="body2" sx={{ color: "black" }}>
                      {item.name}
                    </Typography>
                    <Box display="flex" alignItems="center" ml={3}>
                      {item.rankImg && (
                        <img
                          src={item.rankImg}
                          alt={item.rank}
                          style={{
                            width: "15px",
                            height: "20px",
                            marginRight: "4px",
                          }}
                        />
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            index === 0
                              ? "#ffcc00"
                              : index === 1
                              ? "#c0c0c0"
                              : index === 2
                              ? "#cd7f32"
                              : "orange",
                        }}
                      >
                        {item.rank}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1" sx={{ color: "black" }}>
                    {item.price}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
        <BottomNavbar activeBar="home" />
      </div>
    </div>
  );
}

export default Home;
