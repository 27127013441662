import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { copyText } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Activity() {
  const [pageConst, setGameData] = useState({
    pageTitle: "Activity",
    toastDialogShow: false,
    toastMessage: "",
  });

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setGameData((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setGameData((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    updateToastDialogState(true, "ID Copied!");
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  const activityList = [
    {
      imgSrc: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240902112453yokb.png",
      description: "91 Club Partner Rewards",
    },
    {
      imgSrc: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240821101004fj8u.png",
      description: "91CLUB Daily Luck Spin",
    },
    {
      imgSrc: "https://ossimg.91admin123admin.com/91club/banner/Banner_202403021314318bw3.png",
      description: "New Member First Deposit Bonus",
    },
    {
      imgSrc: "https://ossimg.91admin123admin.com/91club/banner/Banner_20240706170759bs74.png",
      description: "91CLUB Tips and Trick Avoid Scammer",
    },

    {
      imgSrc: "https://ossimg.91admin123admin.com/91club/banner/Banner_20241223121322dia1.png",
      description: "Merry Christmas & Happy New Year 2025",
    },
    {
      imgSrc: "https://ossimg.91admin123admin.com/91club/banner/Banner_2024020413014726oj.png",
      description: "91CLUB Official Channel",
    },
  ];

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />

        <div className={[general.posRelative].join(" ")}>
          <div
            className={[
              general.colView,
              general.alignCenter,
              general.pd10px15px,
              general.bgCustomColor1,
            ].join(" ")}
          >
            <div className="col-view">
              <p className="ft-sz-25 cl-white">Activity</p>
              <p className="ft-sz-14 cl-white">
                Please remember to follow the event page.
              </p>
              <p className="ft-sz-14 cl-white">
                We will launch user feedback activities from time to time.
              </p>
            </div>
          </div>

          <div
            className={[general.colView, styles.activityContentsView].join(" ")}
          >
            <div
              className={[
                styles.activityOptionView,
                general.width100,
                general.rowView,
                general.spaceBetween,
                general.colorGrey,
              ].join(" ")}
            >
               <Link to="/DailyTasks" style={{ textDecoration: "none", display: "block" }}>
              <div>
                <img
                  src={require("../media/icons/activity_award_icon.png")}
                  alt="icon"
                />
                <span style={{ fontSize: "12px", color: "rgb(136, 136, 136)" }}>Activity<br></br>Award</span>
              </div>
              </Link>
              <Link to="/invitationBonus" style={{ textDecoration: "none", display: "block" }}>
              <div>
                <img
                  src={require("../media/icons/invitation_bonus_icon.png")}
                  alt="icon"
                />
                <span style={{ fontSize: "12px", color: "rgb(136, 136, 136)" }}>Invitation<br></br>Bonus</span>
              </div>
              </Link>
              <Link to="/DailyTasks" style={{ textDecoration: "none", display: "block" }}>
              <div>
                <img
                  src={require("../media/icons/betting_rebate_icon.png")}
                  alt="icon"
                />
                <span style={{ fontSize: "12px", color: "rgb(136, 136, 136)" }}>Betting<br></br>Rebate</span>
              </div>
              </Link>
              <Link to="/DailyTasks" style={{ textDecoration: "none", display: "block" }}>
              <div>
                <img
                  src={require("../media/icons/superjackpot_icon.png")}
                  alt="icon"
                />
                <span style={{ fontSize: "12px", color: "rgb(136, 136, 136)" }}>Super<br></br>Jackpot</span>
              </div>
              </Link>
            </div>

            <div className={[general.gridView2, general.mgT10px].join(" ")}>
              <Link
                className={[
                  general.textDecoNone,
                  general.colView,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                to={"/redeemgiftcard"}
              >
                <img
                  src={require("../icons/othericons/1.png")}
                  className="w-100"
                  alt="icon"
                />
                <div className="col-view pd-15-10 mg-t-10">
                  <span
                    className={[general.fontS18Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Gifts
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(
                      " "
                    )}
                  >
                    Enter the redemption code to receive gift rewards
                  </span>
                </div>
              </Link>

              <Link
                className={[
                  general.textDecoNone,
                  general.colView,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                to={"/dailycheckin"}
              >
                <img
                  src={require("../icons/othericons/2.png")}
                  className="w-100"
                  alt="icon"
                />
                <div className="col-view pd-15-10 mg-t-10">
                  <span
                    className={[general.fontS18Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Attendance bonus
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(
                      " "
                    )}
                  >
                    The more consecutive days you sign in, the higher the reward
                    will be.
                  </span>
                </div>
              </Link>
            </div>

            <div>
      {activityList.map((activity, index) => (
        <div
          key={index}
          style={{
            boxShadow: "rgb(220 220 220) 0px 0.08rem 0.13333rem 0.02667rem",
          }}
          className={[
            styles.activityListView,
            general.bgCustomColor4,
            general.mgT10px,
            general.colorBlack,
            general.fontW600,
          ].join(" ")}
        >
          <img src={activity.imgSrc} alt="activity image" />
          <span
            style={{
              color: "black",
              fontWeight: 700,
            }}
          >
            {activity.description}
          </span>
        </div>
      ))}
    </div>
          </div>
        </div>

        <BottomNavbar activeBar="activity" />
      </div>
    </div>
  );
}

export default Activity;