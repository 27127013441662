import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from "react";
import styles from "./styles.module.css";
import { Success, Fail } from '@react-vant/icons';  

const ToastDialog = forwardRef(({ onToastChange }, ref) => {
  const dialogRef = useRef(null);
  const [toastMessage, setToastMessage] = useState("");
  const [dialogTimeout, setDialogTimeout] = useState(0);
  const [dialogMode, setDialogMode] = useState(2);
  const [stylemode, setstylemode] = useState(0);
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useImperativeHandle(ref, () => ({
    openDialog: async (message = "", timeout = 2500, delay = 0, mode = 0, stylemode = 0) => {
      if (delay > 0) {
        await sleep(delay);
      }

      if (message !== "") {
        // Add message to toast with line breaks
        setToastMessage(message);
      }

      if (timeout > 0) {
        setDialogTimeout(timeout);
      }

      if (stylemode > 0) {
        setstylemode(stylemode);
      }

      if (mode > 0) {
        setDialogMode(mode);
      }
      const element = dialogRef.current;
      if (element) {
        element.classList.remove(styles.dialogViewClose);
        element.classList.remove(styles.dialogViewHide);
        element.classList.add(styles.dialogViewActive);
      }
    },
  }));

  const closeDialog = () => {
    const element = dialogRef.current;
    if (element && element.classList.contains(styles.dialogViewActive)) {
      element.classList.remove(styles.dialogViewActive);
      element.classList.remove(styles.dialogViewClose);
      element.classList.add(styles.dialogViewHide);
      setDialogTimeout(0);
    }
  };

  const onAnimationEnd = () => {
    console.log("anim end");
    const element = dialogRef.current;
    if (element && element.classList.contains(styles.dialogViewHide)) {
      element.classList.remove(styles.dialogViewActive);
      element.classList.remove(styles.dialogViewHide);
      element.classList.add(styles.dialogViewClose);
      setDialogTimeout(0);
    }
  };

  useEffect(() => {
    if (dialogTimeout > 0) {
      setTimeout(() => closeDialog(), dialogTimeout);
    }
  }, [dialogTimeout]);

  return (
    <div
      className={[styles.dialogView, styles.dialogViewClose].join(" ")}
      onClick={() => closeDialog()}
      onAnimationEnd={onAnimationEnd}
      ref={dialogRef}
    >
      <div className={[styles.dialogContentsView]}>
      <div className={stylemode === 1 ? styles.dialogContentsGame : styles.dialogContents}>
      {stylemode !== 1 && (
  dialogMode === 1 ? (
    <Success style={{ color: '#fff', fontSize: '40px' }} />
  ) : (
    <Fail style={{ color: '#fff', fontSize: '40px' }} />
  )
)}
  <div>{toastMessage}</div>
</div>
      </div>
    </div>
  );
});

export default ToastDialog;
