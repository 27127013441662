import React from "react";
import { getStorage } from "../../modals/Storage";
import styles from "../ImportantNotice/Notice.module.css";

const ImportantNotice = () => {
  return (
    <div className={[styles.noticeView]}>
      <div className={[styles.noticeIconView]}>
        <img src={require("../../media/icons/notice_icon.png")} alt="icon" />
      </div>

      <div className={[styles.noticeBox]}>{getStorage("impMessage")} </div>
      <button className={[styles.hotIcon]}><i className="fas fa-fire"></i> Detail</button>
      
    </div>
  );
};

export default ImportantNotice;
