import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import TopBar from "../other-components/TopBar";
import { getStorage, setStorage } from "../modals/Storage";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import "../../MainStyle.css";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function ActivityReferBonus() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [countuserinvitee, setuserinvitee] = useState([]); // State for invitees
  const [data, setData] = useState([]); // State for referral bonus data
  const [responseData, setresponseData] = useState([]);
  const toastDialogRef = useRef(null);

  const [pageConst, setConstants] = useState({
    pageTitle: "Invitation Bonus",
  });

  const topBarClickAction = (data) => {};
  const onToastChange = (data, msg) => {};
  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  function getAllMembers() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-allmember-records",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);
        if (data.status_code === "success") {
          setresponseData(data);
          setuserinvitee(data.countuserinvitee);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  function getReferBonus() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-activity-bonus",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const responseData = await res.json();
        updateLoadingStatus(false);
        if (responseData.status_code === "success") {
          setData(responseData.data); 
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      getAllMembers();
      getReferBonus();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className="zoomAnimView">
          <div
            className={[
              general.viewCenter,
              general.pd10px,
              general.bgCustomColor4,
            ].join(" ")}
          >
            <TopBar
              intentData={pageConst}
              multiBtn={true}
              multiBtn1=""
              multiBtn2=""
              updateState={topBarClickAction}
            />
          </div>

          <div
            className={[
              styles.invitationDetailView,
              general.bgCustomColor1,
            ].join(" ")}
          >
            <div style={{ display: "inline-grid", padding: "30px" }}>
              <span className="cl-white ft-sz-20 mg-t--20">
                Invite Friends And Deposit
              </span>
              <span className="cl-white ft-sz-15 mg-t-20">
                Invite friends to register and recharge to
              </span>
              <span className="cl-white ft-sz-15 mg-t-5">receive rewards</span>
              <span className="cl-white ft-sz-15 mg-t-5">Activity date</span>
              <span className="cl-white ft-sz-20">2020-01-01 - 2099-12-31</span>
            </div>
          </div>

          <div className="pd-15 mg-t--70">
            <div
              className="w-100 row-view sb-view a-flex-start br-10 bg-white ovf-hidden"
              style={{
                height: "120px",
                boxShadow: "0 .10667rem .21333rem #d0d0ed5c",
              }}
            >
              <div className="w-100 col-view">
                <Link
                  to="/invitationBonus/Rule"
                  style={{ textDecoration: "none", display: "block" }}
                >
                  <div className="w-100 col-view a-center pd-10 activity-rule-parent">
                    <span className="activiy-rules mg-t-55 cl-grey ft-sz-14">
                      Invitation reward rules
                    </span>
                  </div>
                </Link>
              </div>

              <div className="w-100 col-view">
                <Link
                  to="/invitationBonus/Record"
                  style={{ textDecoration: "none", display: "block" }}
                >
                  <div className="w-100 col-view a-center pd-10 activity-record-parent">
                    <span className="activiy-record mg-t-55 cl-grey ft-sz-14">
                      Invitation Record
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            {/* Bonus container */}
            <div className="bonus-container">
  {data.map((item) => {
    const tUsersDeposited = responseData.t_usersdeposited;
    let countForSlNum = null;
    if (tUsersDeposited && tUsersDeposited[item.sl_num]) {
      countForSlNum = tUsersDeposited[item.sl_num].count;
    }

    return (
      <div className="bonus-items" key={item.id}>
        <div className="bonus-head">
          <div className="left">
            Bonus
            <div className="deco-1">{item.sl_num}</div>
          </div>
          <div className="right">₹{item.rewardamount}</div>
        </div>
        <div className="bonus-detail">
          <div className="text">Number of invitees</div>
          <div className="people">{countuserinvitee}</div>
        </div>
        <div className="bonus-detail">
          <div className="text">Recharge per people</div>
          <div className="num">₹{item.requiredrechargeam}</div>
        </div>
        <div className="line"></div>
        <div className="task-container">
          <div className="task">
            <div className="peoplelevel">{countuserinvitee} / {item.requiredinvitee}</div>
            <div className="text">Number of invitees</div>
          </div>
          <div className="task2">
            <div className="rechargeval">{countForSlNum ? countForSlNum : "0"} / {countuserinvitee}</div>
            <div className="text">Deposit Number</div>
          </div>
        </div>
        <div className="btn">Unfinished</div>
      </div>
    );
  })}
</div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityReferBonus;
