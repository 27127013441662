import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/screens/Home';
import Promotion from './components/screens/Promotion';
import Wallet from './components/screens/Wallet';
import Recharge from './components/screens/Recharge';
import Account from './components/screens/Account';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import ForgotPassword from './components/screens/ForgotPassword';
import ChangePassword from './components/screens/ChangePassword';
import GameRecords from './components/screens/GameRecords';
import Transactions from './components/screens/Transactions';
import AllBankCards from './components/screens/AllBankCards';
import AddBankCard from './components/screens/AddBankCard';
import AddCryptoAddress from './components/screens/AddCryptoAddress';
import MyOrders from './components/screens/MyOrders';
import Withdraw from './components/screens/Withdraw';
import WithdrawRecords from './components/screens/WithdrawRecords';
import RechargeRecords from './components/screens/RechargeRecords';
import InviteLink from './components/screens/InviteLink';
import Rewards from './components/screens/Rewards';
import AgentReports from './components/screens/AgentReports';
import WingoHandler from './components/Games/Wingo/Handler';
import K3Handler from './components/Games/k3Lottery/Handler';
import AndarBahar from './components/Games/AndarBahar/Game';
import UnderMaintenance from './components/screens/UnderMaintenance';
import Wheelocity from './components/Games/Wheelocity/Game';
import DeviceManager from './components/screens/DeviceManager';
import UpdateAccount from './components/screens/UpdateAccount';
import ChangeAvatar from './components/screens/ChangeAvatar';
import ChangeName from './components/screens/ChangeName';
import AllMembers from './components/screens/AllMembers';
import PromotionRules from './components/screens/PromotionRule';
import RedeemGiftCard from './components/screens/RedeemGiftcard';
import Activity from './components/screens/Activity';
import Invitationrewardrule from './components/screens/InvitationRewardRule';
import HelplineAgent from './components/screens/HelplineAgent'
import DailyCheckIn from './components/screens/DailyCheckIn';
import GoldMiner from './components/Games/GoldMiner/Game';
import SplashScreen from './components/screens/SplashScreen';
import FirstDepositBonus from './components/screens/FirstDepositBonus';
import NewSubordinate from './components/screens/NewSubordinate';
import Notification from './components/screens/Notification';
import TeamReport from './components/screens/TeamReport';
import Vip from './components/screens/Vip';
import Feedback from './components/screens/Feedback';
import CustomerHelp from './components/screens/CustomerSupport';
import UserAgreement from './components/screens/UserAgreement';
import ActivityReferBonus from './components/screens/ActivityReferBonus';
import AllGames from './components/screens/AllGames';
import CustomerSupport from './components/other-components/CustomerSupport'; 
import { BalanceProvider } from './components/other-components/BalanceContext';

function App() {
  return (
    <BalanceProvider>
      <Router>
      <CustomerSupport />
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/promotion' element={<Promotion />} />
          <Route path='/wallet' element={<Wallet />} />
          <Route path='/recharge' element={<Recharge />} />
          <Route path='/main' element={<Account />} />
          <Route path='/activity' element={<Activity />} />
          <Route path='/gamerecords' element={<GameRecords />} />
          <Route path='/myorders' element={<MyOrders />} />
          <Route path='/transactions' element={<Transactions />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/changepassword' element={<ChangePassword />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/withdraw' element={<Withdraw />} />
          <Route path='/bankcards' element={<AllBankCards />} />
          <Route path='/addbankcard' element={<AddBankCard />} />
          <Route path='/addcryptoaddress' element={<AddCryptoAddress />} />
          <Route path='/withdrawrecords' element={<WithdrawRecords />} />
          <Route path='/rechargerecords' element={<RechargeRecords />} />
          <Route path='/invitelink' element={<InviteLink />} />
          <Route path='/rewards' element={<Rewards />} />
          <Route path='/agentreports' element={<AgentReports />} />
          <Route path='/devicemanager' element={<DeviceManager />} />
          <Route path='/main/Settings' element={<UpdateAccount />} />
          <Route path='/changeavatar' element={<ChangeAvatar />} />
          <Route path='/changename' element={<ChangeName />} />
          <Route path='/allmembers' element={<AllMembers />} />
          <Route path='/promotion/PromotionRule' element={<PromotionRules />} />
          <Route path='/promotion/TeamReport' element={<TeamReport />} />
          <Route path='/promotion/Server' element={<HelplineAgent />} />
          <Route path='/newsubordinate' element={<NewSubordinate />} />
          <Route path='/redeemgiftcard' element={<RedeemGiftCard />} />
          <Route path='/dailycheckin' element={<DailyCheckIn />} />
          <Route path='/firstdepositbonus' element={<FirstDepositBonus />} />
          <Route path='/invitationBonus/Rule' element={<Invitationrewardrule />} />
          <Route path='/vip' element={<Vip />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='/home/AllGames' element={<AllGames />} />
          <Route path='/user-agreement' element={<UserAgreement />} />
          <Route path='/invitationBonus' element={<ActivityReferBonus />} />
          <Route path='/home/AllLotteryGames/WinGo' element={<WingoHandler />} />
          <Route path='/home/AllLotteryGames/K3' element={<K3Handler />} />
          <Route path='/main/Feedback' element={<Feedback />} />
          <Route path='/main/CustomerSupport' element={<CustomerHelp />} />
          <Route path='/AndarBahar' element={<AndarBahar />} />
          <Route path='/Wheelocity' element={<Wheelocity />} />
          <Route path='/GoldMiner' element={<GoldMiner />} />
          <Route path='/um' element={<UnderMaintenance />} />
          {/* default page */}
          <Route path='/' element={<SplashScreen />} />
        </Routes>
      </Router>
    </BalanceProvider>
  );
}

export default App;
