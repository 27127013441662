import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import BettingDialog from "../../dialogs/BettingDialog";
import MaintenanceDialog from "../../dialogs/MaintenanceDialog";
import ToastDialog from "../../dialogs/ToastDialog/toast";
import WinDialog from "../../dialogs/WinLoseDialog/dialog";
import RuleDialog from "../../dialogs/WingoRuleDialog";
import { setStorage, getStorage } from "../../modals/Storage";
import { API_ACCESS_URL, openNewPage } from "../../modals/Constants";
import "../../../MainStyle.css";
import "../../css/WingoGame.css";
import styles from "../Wingo/Game.module.css";

import ImportantNotice from "../../other-components/ImportantNotice/Notice";
import LoadingDialog from "./components/LoadingDialog/dialog";

function Wingo10Min() {
  const navigate = useNavigate();

  // audio references
  const audio1Ref = useRef(null);
  const audio2Ref = useRef(null);

  const toastDialogRef = useRef(null);
  const [remainingSec, setCount] = useState(60);
  const [disableTime, setDisable] = useState(30);
  const [randomNumAnimationTime, setRandomNumAnimationTime] = useState(0);
  const [randomNumAnimation, setRandomNumAnimation] = useState(-1);

  const [isMusicOn, setIsMusicOn] = useState(getStorage("isMusicOn"));
  const [playComponent, setPlayComponent] = useState(0);

  const [recordsPageNum, setRecordsPageNum] = useState(1);
  const [recordsTotalPageNum, setRecordsTotalPageNum] = useState(0);

  const [selectedRecord, setSelectedRecord] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setGameData] = useState({
    pageTitle: "Wingo 5Min",
    gamePeriodId: "",
    gameCode: "WINGO5MIN",
    remainingSec1: 0,
    remainingSec2: 0,
    remainingMin1: 0,
    remainingMin2: 0,
    isControlEnable: true,
    requestAccountData: true,
    maintenanceDialogShow: false,
    ruleDialogShow: false,
    winDialogShow: false,
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    gameShortRecordList: [],
    mygameRecordsList: [],
  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingDialogShow: false,
    bettingDialogTitle: "Green",
    bettingDialogCurrentGame: "Wingo Go 10Min",
    bettingDialogTheme: "dlg-thm-green",
    gameBetRoutePath: "route-bet-common",
    gameSelectedBet: "",
    gameSelectedMultiply: "1",
  });

  const [winDialogConst, setWinDialogConst] = useState({
    winDialogShow: false,
    constPeriodId: "",
    constWinAmount: 0,
    constWinDialogResult: "",
    constWinDialogState: "",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === "visible";
    if (isVisible) {
      getGameData(recordsPageNum);
    }
  }, []);

  const updateSelectedRecord = (data) => {
    if (selectedRecord != 0 && selectedRecord == data) {
      setSelectedRecord(0);
    } else {
      setSelectedRecord(data);
    }
  };

  const updateMusicStatus = (data) => {
    setIsMusicOn(data);
    setStorage("isMusicOn", data);
  };

  const openNewGame = (data) => {
    if (data == "1") {
      navigate("/home/AllLotteryGames/WinGo?id=1", { replace: true });
    } else if (data == "2") {
      navigate("/home/AllLotteryGames/WinGo?id=2", { replace: true });
    } else if (data == "3") {
      navigate("/home/AllLotteryGames/WinGo?id=3", { replace: true });
    } else if (data == "4") {
      navigate("/home/AllLotteryGames/WinGo?id=4", { replace: true });
    }
  };

  const updatePageNum = (data) => {
    let updatedRecordsNum = recordsPageNum + Number(data);
    if (
      updatedRecordsNum >= 1 &&
      updatedRecordsNum < 500 &&
      recordsPageNum != recordsTotalPageNum
    ) {
      setRecordsPageNum(updatedRecordsNum);
      getGameData(updatedRecordsNum);
    } else if (Number(data) < 0 && updatedRecordsNum >= 1) {
      setRecordsPageNum(updatedRecordsNum);
      getGameData(updatedRecordsNum);
    }
  };

  const updateBetMultiply = (data) => {
    setBettingDialogConst((previousState) => {
      return { ...previousState, gameSelectedMultiply: data };
    });
  };

  const updatePeriod = (data) => {
    setGameData((previousState) => {
      return { ...previousState, gamePeriodId: data[0].game_period_id };
    });
  };

  const updateWinDialogState = (data) => {
    setWinDialogConst((previousState) => {
      return { ...previousState, winDialogShow: data };
    });
  };

  const updateGameControl = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isControlEnable: data };
    });
  };

  const updateReqAcntData = (data) => {
    if (data == "false") {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: false };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: true };
      });
    }
  };

  const updateRemainSec = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingSec1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingSec2: val };
      });
    }
  };

  const updateRemainMin = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingMin1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingMin2: val };
      });
    }
  };

  const updateActiveTab = (data) => {
    setGameData((previousState) => {
      return { ...previousState, tabActiveReord: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const getDialogThemeClassName = (data) => {
    let returnVal = "dlg-thm-green";

    if (data == "r" || data == "rv") {
      returnVal = "dlg-thm-red";
    } else if (data == "v") {
      returnVal = "dlg-thm-violet";
    } else if (data == "b") {
      returnVal = "dlg-thm-orange";
    } else if (data == "s") {
      returnVal = "dlg-thm-blue";
    }

    return returnVal;
  };

  const updateBettingDialogState = (e, data, selected, title) => {
    if (selected != "") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, gameSelectedBet: selected };
      });
    }

    if (
      e != null &&
      e.target.className ==
      "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"
    ) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });
    } else if (data == true && pageConst.isControlEnable) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });

      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogTitle: title };
      });

      setBettingDialogConst((previousState) => {
        return {
          ...previousState,
          bettingDialogTheme: getDialogThemeClassName(
            getColourCodeVal(selected)
          ),
        };
      });
    } else if (data == "dismiss") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: false };
      });
    }
  };

  const showGameRules = () => {
    setGameData((previousState) => {
      return { ...previousState, ruleDialogShow: true };
    });
  };

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
    } else {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateMaintenanceDialog = (data) => {
    if (data == "dismiss") {
      navigate(-1);
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, maintenanceDialogShow: true };
      });
    }
  };

  const updateGameRuleDialog = (e, data) => {
    if (
      e != null &&
      e.target.className ==
      "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"
    ) {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: data };
      });
    } else if (data == "dismiss") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: false };
      });
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode, stylemode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode, stylemode);
  };

  // callback: on bet complete
  const onBetCompleteListener = (status, message = "", extra = "") => {
    if (status == "failed") {
      showToast(message, 2500, 100, 2, 1);
    } else if (status == "success") {
      showToast(message, 2500, 100, 1, 1);
    }
  };

  const getCapitalLetter = (data) => {
    let returnVal = "";

    if (data == "red") {
      returnVal = "R";
    } else if (data == "green") {
      returnVal = "G";
    } else if (data == "violet") {
      returnVal = "V";
    } else if (data == "b") {
      returnVal = "B";
    } else if (data == "s") {
      returnVal = "S";
    } else {
      returnVal = data;
    }

    return returnVal;
  };

  const isBigSmall = (data) => {
    let returnVal = "false";

    if (data == "b" || data == "s") {
      returnVal = "true";
    }

    return returnVal;
  };

  const getBigSmall = (data) => {
    let returnVal = "";

    if (data >= 5) {
      returnVal = "B";
    } else {
      returnVal = "S";
    }

    return returnVal;
  };

  const getWinCode = (data, isWin) => {
    let returnVal = "";

    if (isWin == "loss" && data == "s") {
      returnVal = "B";
    } else if (isWin == "loss" && data == "b") {
      returnVal = "S";
    } else if (isWin == "profit") {
      returnVal = getCapitalLetter(data);
    } else {
      returnVal = "";
    }

    return returnVal;
  };

  const getColourCodeVal = (data) => {
    let returnVal = "";

    if (data == "1" || data == "3" || data == "7" || data == "9") {
      returnVal = "g";
    } else if (data == "2" || data == "4" || data == "6" || data == "8") {
      returnVal = "r";
    } else if (data == "0") {
      returnVal = "rv";
    } else if (data == "5") {
      returnVal = "gv";
    } else if (data == "red") {
      returnVal = "r";
    } else if (data == "green") {
      returnVal = "g";
    } else if (data == "violet") {
      returnVal = "v";
    } else if (data == "b" || data == "B") {
      returnVal = "b";
    } else if (data == "s" || data == "S") {
      returnVal = "s";
    }

    return returnVal;
  };

  const checkIfThisNumber = (data) => {
    return !isNaN(parseFloat(data));
  };

  const updateGameRecords = (data) => {
    let tempData = [];
    let shortCutData = [];

    for (let i = 0; i < data.length; i++) {
      let matchResult = data[i]["match_result"];

      if (i < 5) {
        shortCutData.push(
          <div key={data[i]["period_id"] * 2} className="row-view sb-view">
            {matchResult == "0" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n0_icon.png")}
                alt="icon"
              />
            ) : matchResult == "1" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n1_icon.png")}
                alt="icon"
              />
            ) : matchResult == "2" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n2_icon.png")}
                alt="icon"
              />
            ) : matchResult == "3" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n3_icon.png")}
                alt="icon"
              />
            ) : matchResult == "4" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n4_icon.png")}
                alt="icon"
              />
            ) : matchResult == "5" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n5_icon.png")}
                alt="icon"
              />
            ) : matchResult == "6" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n6_icon.png")}
                alt="icon"
              />
            ) : matchResult == "7" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n7_icon.png")}
                alt="icon"
              />
            ) : matchResult == "8" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n8_icon.png")}
                alt="icon"
              />
            ) : matchResult == "9" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n9_icon.png")}
                alt="icon"
              />
            ) : (
              ""
            )}
          </div>
        );
      }

      tempData.push(
        <div
          key={data[i]["period_id"]}
          className={[
            styles.rowView,
            styles.alignItemsCenter,
            styles.jcSpaceBet,
            styles.pd5px10px,
          ].join(" ")}
        >
          <span className="w-50-p cl-l-black-2 ft-sz-14 ft-wgt-200">
            {data[i]["period_id"]}
          </span>
          <span
            className={`v-center w-50-p ft-sz-28 ft-wgt-400 ${getColourCodeVal(matchResult) == "r"
              ? "cl-red"
              : getColourCodeVal(matchResult) == "g"
                ? "cl-green"
                : getColourCodeVal(matchResult) == "rv"
                  ? "cl-rv"
                  : "cl-gv"
              }`}
          >
            {matchResult}
          </span>
          <span
            className={`v-center w-30-p br-50 cl-l-black-2 ft-sz-14`}
          >
            {getBigSmall(matchResult) == "B" ? "Big" : "Small"}
          </span>
          <span className="v-center w-30-p">
            {getColourCodeVal(matchResult) == "rv" ? (
              <div className="row-view">
                <div className="h-w-10 br-50 bg-red"></div>
                <div className="h-w-10 br-50 bg-violet-2 mg-l-10"></div>
              </div>
            ) : getColourCodeVal(matchResult) == "gv" ? (
              <div className="row-view">
                <div className="h-w-10 br-50 bg-green"></div>
                <div className="h-w-10 br-50 bg-violet-2 mg-l-10"></div>
              </div>
            ) : getColourCodeVal(matchResult) == "r" ? (
              <div className="h-w-10 br-50 bg-red"></div>
            ) : (
              <div className="h-w-10 br-50 bg-green"></div>
            )}
          </span>
        </div>
      );
    }

    setGameData((previousState) => {
      return { ...previousState, gameRecordsList: tempData };
    });

    setGameData((previousState) => {
      return { ...previousState, gameShortRecordList: shortCutData };
    });
  };

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  };

  const updateWinLossDialog = (
    periodId,
    wonAmount,
    matchResult,
    winLossStatus
  ) => {
    let tempResult = null;

    if (winLossStatus != "wait") {
      tempResult = `
      <span>Lottery Result</span>
      <span
      style="background: ${getColourCodeVal(Number(matchResult)) == "g"
          ? "#00c282"
          : getColourCodeVal(Number(matchResult)) == "r"
            ? "#fa3c09"
            : "#0093ff"
        }; color: #FFFFFF;
        padding: 2px 8px;border-radius: 5px;">
          ${getColourCodeVal(Number(matchResult)) == "g"
          ? "Green"
          : getColourCodeVal(Number(matchResult)) == "r"
            ? "Red"
            : "Violet"
        }
      </span>
      <span
      style="background: ${getColourCodeVal(Number(matchResult)) == "g"
          ? "#00c282"
          : getColourCodeVal(Number(matchResult)) == "r"
            ? "#fa3c09"
            : "#0093ff"
        }; color: #FFFFFF;
        height: 25px; width: 25px;border-radius: 50%;">${matchResult}</span>
      <span
      style="background: ${getColourCodeVal(Number(matchResult)) == "g"
          ? "#00c282"
          : getColourCodeVal(Number(matchResult)) == "r"
            ? "#fa3c09"
            : "#0093ff"
        }; color: #FFFFFF;
        padding: 2px 8px;border-radius: 5px;">${getBigSmall(Number(matchResult)) == "S" ? "Small" : "Big"
        }</span>
  `;

      setWinDialogConst((previousState) => {
        return {
          ...previousState,
          constPeriodId: "win 1min " + periodId,
        };
      });

      if (winLossStatus == "profit") {
        setWinDialogConst((previousState) => {
          return { ...previousState, constWinAmount: wonAmount };
        });

        setWinDialogConst((previousState) => {
          return { ...previousState, constWinDialogState: "win" };
        });
      } else {
        setWinDialogConst((previousState) => {
          return { ...previousState, constWinAmount: "0" };
        });

        setWinDialogConst((previousState) => {
          return { ...previousState, constWinDialogState: "loss" };
        });
      }

      setWinDialogConst((previousState) => {
        return { ...previousState, constWinDialogResult: tempResult };
      });

      setStorage("recentwingo10minbet", "");
      updateWinDialogState(true);
    }
  };

  const recentBetDetails = () => {
    setStorage("recentwingo10minbet", pageConst.gamePeriodId);

    let tempData = [];

    tempData.push(
      <div key={60} className={[styles.myGameRecordsViewItem].join(" ")}>
        <div
          className={[
            styles.myGameRecordsContents,
            styles.rowView,
            styles.jcSpaceBet,
            styles.alignItemsCenter,
          ].join(" ")}
        >
          <div
            className={[
              styles.viewCenter,
              styles.resultShortView,
              getColourCodeVal(bettingDialogConst.gameSelectedBet) == "r"
                ? styles.bgRed
                : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "g"
                  ? styles.bgGreen
                  : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "rv"
                    ? styles.bgGreen
                    : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "gv"
                      ? styles.bgGreen
                      : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "b"
                        ? styles.bgOrange
                        : styles.bgBlue,
            ].join(" ")}
          >
            {getColourCodeVal(bettingDialogConst.gameSelectedBet) == "b"
              ? "big"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "s"
                ? "small"
                : checkIfThisNumber(bettingDialogConst.gameSelectedBet)
                  ? bettingDialogConst.gameSelectedBet
                  : ""}
          </div>

          <div
            className={[styles.colView, styles.mygameShortDetailsView].join(
              " "
            )}
          >
            <div
              className={[styles.w100, styles.rowView, styles.jcSpaceBet].join(
                " "
              )}
            >
              <span className={[styles.letterSpacing1p1px]}>
                bettingDialogConst.gamePeriodId
              </span>
              <span></span>
            </div>

            <div
              className={[
                styles.w100,
                styles.rowView,
                styles.jcSpaceBet,
                styles.mgT5px,
              ].join(" ")}
            >
              <span className={[styles.fontS13Px]}>--</span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );

    let finalArr = [tempData].concat(pageConst.mygameRecordsList);

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: finalArr };
    });
  };

  const updateMyGameRecords = (data) => {
    if (data.length > 0) {
      let i = 0;
      if (
        i == 0 &&
        getStorage("recentwingo10minbet") == data[i]["m_period_id"]
      ) {
        updateWinLossDialog(
          data[0]["m_period_id"],
          data[0]["m_profit"],
          data[0]["m_result"],
          data[0]["m_status"]
        );
      }

      setGameData((previousState) => {
        return { ...previousState, mygameRecordsList: data };
      });
    }
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const randomNumberSelect = () => {
    setRandomNumAnimationTime(30);
  };

  function getGameData(pageNum) {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-setup-common",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setCount(data.gamedata[0].game_remain_seconds);
          setDisable(data.gamedata[0].game_disable_time);
          updateGameRecords(data.matchrecords);
          updateMyGameRecords(data.mymatchrecords);
          setRecordsTotalPageNum(data.total_match_pages);
          setStorage("balance", data.account_balance);
        } else if (data.status_code == "under_maintenance") {
          updateMaintenanceDialog("true");
        } else {
          showToast(
            "Something went wrong! Please try again or login!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    fecthApiData(
      API_ACCESS_URL +
      "?USER_ID=" +
      getStorage("uid") +
      "&PROJECT_NAME=" +
      pageConst.gameCode +
      "&PAGE=" +
      pageNum
    );
  }

  function setUpTimer(data) {
    if (data == "true") {
      updateReqAcntData("true");
    }

    if (pageConst.requestAccountData == true) {
      updateReqAcntData("false");
      getGameData(recordsPageNum);
    }
  }

  const playAudio = (audioRef) => {
    if (audioRef.current) {
      audioRef.current.play().catch(error => {
        console.error('Playback failed:', error);
      });
    }
  }

  useEffect(() => {
    if (isMusicOn == "true" && playComponent > 1 && playComponent <= 4) {
      playAudio(audio1Ref);
    } else if (isMusicOn == "true" && playComponent == 1) {
      playAudio(audio2Ref);
    }
  }, [playComponent, setIsMusicOn]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    setUpTimer("false");

    const milliSecInterval = setInterval(() => {
      if (randomNumAnimationTime > 0) {
        let randBetNum = getRandomNum(0, 10, "int");

        setRandomNumAnimation(randBetNum);
        setRandomNumAnimationTime(randomNumAnimationTime - 1);
        if (randomNumAnimationTime == 1) {
          setRandomNumAnimation(-1);
          updateBettingDialogState(
            null,
            true,
            randBetNum,
            "Select " + randBetNum
          );
        }
      }
    }, 100);

    const interval = setInterval(() => {
      if (remainingSec >= 0) {
        setCount(remainingSec - 1);

        if (remainingSec <= 0) {
          setPlayComponent(0);
          setUpTimer("true");
          setCount(60);
        } else {
          function splitIntoArray(num) {
            return Array.from(String(num), Number);
          }

          const minutes = Math.floor(remainingSec / 60);
          const seconds = remainingSec % 60;

          if (minutes < 10) {
            updateRemainMin("1", 0);
            updateRemainMin("2", minutes);
          } else {
            var numArr = splitIntoArray(minutes);
            updateRemainMin("1", numArr[0]);
            updateRemainMin("2", numArr[1]);
          }

          if (seconds < 10) {
            if (seconds <= 4) {
              setPlayComponent(seconds);
            }

            updateRemainSec("1", 0);
            updateRemainSec("2", seconds);
          } else {
            var numArr = splitIntoArray(seconds);
            updateRemainSec("1", numArr[0]);
            updateRemainSec("2", numArr[1]);
          }

          if (remainingSec < disableTime && pageConst.isControlEnable == true) {
            updateGameControl(false);
          } else if (
            remainingSec > disableTime &&
            pageConst.isControlEnable == false
          ) {
            updateGameControl(true);
          }
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(milliSecInterval);
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [remainingSec, randomNumAnimationTime, selectedRecord]);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
        <audio src={require("../../media/audio/1.mp3")} ref={audio1Ref} />
        <audio src={require("../../media/audio/2.mp3")} ref={audio2Ref} />

        <LoadingDialog intentData={showLoading} />
        <WinDialog
          intentData={winDialogConst}
          updateState={updateWinDialogState}
        />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />
        <BettingDialog
          gameCode={pageConst.gameCode}
          dialogData={bettingDialogConst}
          updateState={updateBettingDialogState}
          onBetCompleteListener={onBetCompleteListener}
          recentBetDetails={recentBetDetails}
        />
        <MaintenanceDialog
          intentData={pageConst}
          updateState={updateMaintenanceDialog}
        />

        <div className={[styles.colView, styles.bgGrey].join(" ")}>
          <div className={[styles.borderBLR50px, styles.bgCustom].join(" ")}>
            <div className={[styles.GameTopBar, styles.bgCustom].join(" ")}>
              <Link onClick={() => navigate(-1)}>
                <img
                  src={require("../Wingo/icons/angle_left_white_icon.png")}
                  alt="icon"
                />
              </Link>

              <img src="/config/app_logo.png" alt="icon" />

              <div className="row-view v-center">
                <img
                  className="h-27-p"
                  src={require("../../icons/live_chat_icon.png")}
                  onClick={() => openNewPage(getStorage("liveChatURL"))}
                />

                <img
                  className={`h-27-p mg-l-10 ${isMusicOn == "false" ? "hide-v" : ""
                    }`}
                  src={require("../../icons/music_on_icon.png")}
                  onClick={() => updateMusicStatus("false")}
                />
                <img
                  className={`h-27-p mg-l-10 ${isMusicOn == "true" ? "hide-v" : ""
                    }`}
                  src={require("../../icons/music_off_icon.png")}
                  onClick={() => updateMusicStatus("true")}
                />
              </div>
            </div>

            <div
              className={[styles.bgCustom, styles.pd15px, styles.mgT60px].join(
                " "
              )}
            >
              <div className="col-view v-center pd-20 br-15 bg-white">
                <div className="row-view">
                  <p className="cl-black ft-sz-20 wallet-balance-s">
                    ₹{Number(getStorage("balance")).toLocaleString()}
                  </p>
                  <img
                    className="h-25-p mg-l-10"
                    src={require("../../media/icons/refresh_icon.png")}
                    alt="icon"
                    onClick={() => refreshData("true")}
                  />
                </div>
                <div style={{ display: "flex", marginRight: "40px" }}>
                  <svg
                    style={{ marginRight: "6px" }}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="30"
                  >
                    <path
                      opacity="0.5"
                      d="M24.7493 6.58594L24.7494 23.2826H10.4327C6.98268 23.2826 4.16602 26.0993 4.16602 29.5493V13.0693C4.16602 11.0859 5.38268 9.31927 7.23268 8.61927L20.466 3.61927C22.5327 2.8526 24.7493 4.36927 24.7493 6.58594ZM37.5977 23.2826V26.7159C37.5977 27.6326 36.8643 28.3826 35.931 28.4159H32.6643C30.8643 28.4159 29.2143 27.0993 29.0643 25.2993C28.9643 24.2493 29.3643 23.2659 30.0643 22.5826C30.681 21.9493 31.531 21.5826 32.4643 21.5826H35.931C36.8643 21.6159 37.5977 22.3659 37.5977 23.2826Z"
                      fill="#f95959"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M29.066 25.2993C28.966 24.2493 29.366 23.266 30.066 22.5827C30.6827 21.9493 31.5327 21.5827 32.466 21.5827H35.8327V19.1827C35.8327 15.7327 33.016 12.916 29.566 12.916H10.4327C6.98268 12.916 4.16602 15.7327 4.16602 19.1827V30.3993C4.16602 33.8493 6.98268 36.666 10.4327 36.666H29.566C33.016 36.666 35.8327 33.8493 35.8327 30.3993V28.416H32.666C30.866 28.416 29.216 27.0993 29.066 25.2993ZM22.4167 22.5H10.75C10.0667 22.5 9.5 21.9333 9.5 21.25C9.5 20.5667 10.0667 20 10.75 20H22.4167C23.1 20 23.6667 20.5667 23.6667 21.25C23.6667 21.9333 23.1 22.5 22.4167 22.5Z"
                      fill="#f95959"
                    ></path>
                  </svg>
                  <p className="row-view ft-sz-16 cl-drk-black">
                    Wallet Balance
                  </p>
                </div>

                <div className="res-wallet-btns flex-spacebtw mg-t-15">
                  <Link
                    className="v-center cl-white ft-wgt-500 br-20 bg-red-grad-2"
                    to={"/withdraw"}
                    style={{ background: "#FB5B5B" }}
                  >
                    Withdraw
                  </Link>
                  <Link
                    className="v-center cl-white ft-wgt-500 br-20"
                    to={"/recharge"}
                    style={{ background: "#18B660" }}
                  >
                    Deposit
                  </Link>
                </div>
              </div>
            </div>

            <div className={[styles.pd0px15px]}>
              <ImportantNotice />
            </div>

            <div className={[styles.pd0px15px, styles.mgT15px].join(" ")}>
              <div className={[styles.GameOptionsChooseView]}>
                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                  ].join(" ")}
                  onClick={() => openNewGame("1")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/2.png")}
                    alt="icon"
                  />
                  <span>Win Go</span>
                  <span>30s</span>
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                  ].join(" ")}
                  onClick={() => openNewGame("2")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/2.png")}
                    alt="icon"
                  />
                  <span>Win Go</span>
                  <span>1Min</span>
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                  ].join(" ")}
                  onClick={() => openNewGame("3")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/2.png")}
                    alt="icon"
                  />
                  <span>Win Go</span>
                  <span>3Min</span>
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                    styles.GameOptionsSelectedView,
                  ].join(" ")}
                  onClick={() => openNewGame("4")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/1.png")}
                    alt="icon"
                  />
                  <span>Win Go</span>
                  <span>5Min</span>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-15">
            <div className={[styles.GameBettingView, styles.mgT25px].join(" ")}>
              <div className="col-view pd-10">
                <div
                  className="w-fit-content txt-deco-n pd-5-10 ft-sz-13 cl-white ft-wgt-500 br-5 br-a-l-white bg-transparent flex-align-center"
                  onClick={() => showGameRules()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="23"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <path
                      d="M23.67 3H12.33C6.66 3 5.25 4.515 5.25 10.56V27.45C5.25 31.44 7.44 32.385 10.095 29.535L10.11 29.52C11.34 28.215 13.215 28.32 14.28 29.745L15.795 31.77C17.01 33.375 18.975 33.375 20.19 31.77L21.705 29.745C22.785 28.305 24.66 28.2 25.89 29.52C28.56 32.37 30.735 31.425 30.735 27.435V10.56C30.75 4.515 29.34 3 23.67 3ZM11.67 18C10.845 18 10.17 17.325 10.17 16.5C10.17 15.675 10.845 15 11.67 15C12.495 15 13.17 15.675 13.17 16.5C13.17 17.325 12.495 18 11.67 18ZM11.67 12C10.845 12 10.17 11.325 10.17 10.5C10.17 9.675 10.845 9 11.67 9C12.495 9 13.17 9.675 13.17 10.5C13.17 11.325 12.495 12 11.67 12ZM24.345 17.625H16.095C15.48 17.625 14.97 17.115 14.97 16.5C14.97 15.885 15.48 15.375 16.095 15.375H24.345C24.96 15.375 25.47 15.885 25.47 16.5C25.47 17.115 24.96 17.625 24.345 17.625ZM24.345 11.625H16.095C15.48 11.625 14.97 11.115 14.97 10.5C14.97 9.885 15.48 9.375 16.095 9.375H24.345C24.96 9.375 25.47 9.885 25.47 10.5C25.47 11.115 24.96 11.625 24.345 11.625Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  How to play
                </div>
                <p className="cl-white ft-sz-14 mg-t-10">
                  {pageConst.pageTitle}
                </p>
                <div className="g-v-5 mg-t-15">
                  {pageConst.gameShortRecordList}
                </div>
              </div>

              <div className="col-view pd-10">
                <div className="col-view a-right">
                  <span className="cl-white ft-sz-14">Time Remaining</span>
                  <div
                    className={[styles.countDownTimerView, styles.mgT10px].join(
                      " "
                    )}
                  >
                    <span className="bg-white">{pageConst.remainingMin1}</span>
                    <span className="bg-white">{pageConst.remainingMin2}</span>:
                    <span className="bg-white">{pageConst.remainingSec1}</span>
                    <span className="bg-white">{pageConst.remainingSec2}</span>
                  </div>

                  <span className="res-ft-sz-18 cl-white ltr-s-1p8 ft-wgt-500 mg-t-10">
                    {pageConst.gamePeriodId}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={[styles.pd10px15px].join(" ")}>
            <div
              className={[
                styles.positionRel,
                styles.bgWhite,
                styles.borderR10px,
                !pageConst.isControlEnable
                  ? playComponent <= 0
                    ? "disable-view"
                    : ""
                  : "",
              ].join(" ")}
            >
              <div
                className={[
                  styles.viewCenter,
                  styles.GameTimeoutView,
                  !pageConst.isControlEnable
                    ? playComponent <= 4 && playComponent > 0
                      ? ""
                      : styles.hideView
                    : styles.hideView,
                ].join(" ")}
              >
                <h1 className="v-center br-10">0</h1>
                <h1 className="v-center br-10 mg-l-15">
                  {playComponent}
                </h1>
              </div>

              <div
                className={[
                  styles.positionRel,
                  styles.joinColorView,
                  !pageConst.isControlEnable
                    ? playComponent <= 0
                      ? "disable-view"
                      : ""
                    : "",
                ].join(" ")}
              >
                <div
                  className="v-center w-100 cl-white bg-green"
                  onClick={() =>
                    updateBettingDialogState(null, true, "green", "Join Green")
                  }
                >
                  Green
                </div>

                <div
                  className="v-center w-100 cl-white bg-violet"
                  onClick={() =>
                    updateBettingDialogState(
                      null,
                      true,
                      "violet",
                      "Join Violet"
                    )
                  }
                >
                  Violet
                </div>

                <div
                  className="v-center w-100 cl-white bg-red"
                  onClick={() =>
                    updateBettingDialogState(null, true, "red", "Join Red")
                  }
                >
                  Red
                </div>
              </div>

              <div className={[styles.pd0px15px].join(" ")}>
                <div
                  className={[
                    styles.positionRel,
                    styles.joinNumView,
                    !pageConst.isControlEnable
                      ? playComponent <= 0
                        ? "disable-view"
                        : ""
                      : "",
                  ].join(" ")}
                >
                  <img
                    className={[
                      randomNumAnimation == 0
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n0_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "0", "Select 0")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 1
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n1_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "1", "Select 1")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 2
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n2_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "2", "Select 2")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 3
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n3_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "3", "Select 3")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 4
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n4_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "4", "Select 4")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 5
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n5_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "5", "Select 5")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 6
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n6_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "6", "Select 6")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 7
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n7_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "7", "Select 7")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 8
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n8_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "8", "Select 8")
                    }
                  />
                  <img
                    className={[
                      randomNumAnimation == 9
                        ? styles.randomNumSelectedAnim
                        : "",
                    ]}
                    src={require("../Wingo/icons/n9_icon.png")}
                    alt="icon"
                    onClick={() =>
                      updateBettingDialogState(null, true, "9", "Select 9")
                    }
                  />
                </div>
              </div>

              <div className={[styles.joinRandomView]}>
                <div onClick={() => randomNumberSelect()}>Random</div>
                <div
                  className={[
                    bettingDialogConst.gameSelectedMultiply == "1"
                      ? styles.bgActiveGreen
                      : "",
                  ]}
                  onClick={() => updateBetMultiply(1)}
                >
                  X1
                </div>
                <div
                  className={
                    bettingDialogConst.gameSelectedMultiply == "5"
                      ? styles.bgActiveGreen
                      : ""
                  }
                  onClick={() => updateBetMultiply(5)}
                >
                  X5
                </div>
                <div
                  className={[
                    bettingDialogConst.gameSelectedMultiply == "10"
                      ? styles.bgActiveGreen
                      : "",
                  ]}
                  onClick={() => updateBetMultiply(10)}
                >
                  X10
                </div>
                <div
                  className={[
                    bettingDialogConst.gameSelectedMultiply == "30"
                      ? styles.bgActiveGreen
                      : "",
                  ]}
                  onClick={() => updateBetMultiply(30)}
                >
                  X30
                </div>
                <div
                  className={[
                    bettingDialogConst.gameSelectedMultiply == "50"
                      ? styles.bgActiveGreen
                      : "",
                  ]}
                  onClick={() => updateBetMultiply(50)}
                >
                  X50
                </div>
              </div>

              <div className={[styles.joinBigSmallView]}>
                <div
                  className={[styles.bgOrange]}
                  onClick={() =>
                    updateBettingDialogState(null, true, "b", "Join Big")
                  }
                >
                  Big
                </div>

                <div
                  className={[styles.bgBlue]}
                  onClick={() =>
                    updateBettingDialogState(null, true, "s", "Join Small")
                  }
                >
                  Small
                </div>
              </div>
            </div>
          </div>

          <div className="pd-0-15 mg-t-20">
            <div className={[styles.tabOptionsView]}>
              <div
                className={[
                  styles.viewCenter,
                  styles.tabOptionViewItem,
                  pageConst.tabActiveReord == "everyoneorder"
                    ? styles.tabSelectedView
                    : "",
                ].join(" ")}
                onClick={() => updateActiveTab("everyoneorder")}
              >
                Game History
              </div>
              <div
                className={[
                  styles.viewCenter,
                  styles.mgL10px,
                  styles.tabOptionViewItem,
                  pageConst.tabActiveReord == "myorder"
                    ? styles.tabSelectedView
                    : "",
                ].join(" ")}
                onClick={() => updateActiveTab("myorder")}
              >
                My History
              </div>
            </div>

            <div className="col-view br-10 mg-t-25 mg-b-15 bg-white">
              <div
                className={`col-view min-h ${pageConst.tabActiveReord != "everyoneorder" ? "hide-v" : ""
                  }`}
              >
                <div
                  className={[
                    styles.rowView,
                    styles.jcSpaceBet,
                    styles.fontS14p5Px,
                    styles.pd10px15px,
                    styles.colorWhite,
                    styles.borderTLR10px,
                    styles.bgCustom1,
                  ].join(" ")}
                >
                  <span>Period</span>
                  <span className={[styles.mgL25px]}>Number</span>
                  <span>Big Small</span>
                  <span>Color</span>
                </div>

                <div
                  className={[
                    styles.colView,
                    styles.pd10px5px,
                    styles.gameResultListView,
                  ].join(" ")}
                >
                  {pageConst.gameRecordsList}
                </div>

                <div
                  className={[
                    styles.loadMoreBtnsView,
                    styles.viewCenter,
                    styles.mgT10px,
                  ].join(" ")}
                >
                  <div
                    className={[
                      recordsPageNum > 1
                        ? styles.selectedView
                        : styles.notSelectedView,
                    ]}
                    onClick={() => updatePageNum("-1")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#FFFFFF"
                    >
                      <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                    </svg>
                  </div>

                  <div
                    className={[styles.pageNumShowView, styles.fontS14Px].join(
                      " "
                    )}
                  >
                    {recordsPageNum}/{recordsTotalPageNum}
                  </div>

                  <div
                    className={[
                      recordsPageNum == recordsTotalPageNum
                        ? styles.notSelectedView
                        : styles.selectedView,
                    ]}
                    onClick={() => updatePageNum("+1")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="tranf-rotate-180"
                      fill="#FFFFFF"
                    >
                      <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className={`col-view min-h ${pageConst.tabActiveReord != "myorder" ? "hide-v" : ""
                  }`}
              >
                <div className={[styles.myGameRecordsView]}>
                  {pageConst.mygameRecordsList.map((data, index) => (
                    <div
                      key={data["m_order_id"]}
                      className={[styles.myGameRecordsViewItem].join(" ")}
                    >
                      <div
                        className={[
                          styles.myGameRecordsContents,
                          styles.rowView,
                          styles.jcSpaceBet,
                          styles.alignItemsCenter,
                        ].join(" ")}
                        onClick={() => updateSelectedRecord(data["m_order_id"])}
                      >
                        <div
                          className={[
                            styles.viewCenter,
                            styles.resultShortView,
                            getColourCodeVal(data["m_color"]) == "r"
                              ? styles.bgRed
                              : getColourCodeVal(data["m_color"]) == "g"
                                ? styles.bgGreen
                                : getColourCodeVal(data["m_color"]) == "rv"
                                  ? styles.bgGreen
                                  : getColourCodeVal(data["m_color"]) == "gv"
                                    ? styles.bgGreen
                                    : getColourCodeVal(data["m_color"]) == "b"
                                      ? styles.bgOrange
                                      : styles.bgBlue,
                          ].join(" ")}
                        >
                          {getColourCodeVal(data["m_color"]) == "b"
                            ? "big"
                            : getColourCodeVal(data["m_color"]) == "s"
                              ? "small"
                              : checkIfThisNumber(data["m_color"])
                                ? data["m_color"]
                                : ""}
                        </div>

                        <div
                          className={[
                            styles.colView,
                            styles.mygameShortDetailsView,
                          ].join(" ")}
                        >
                          <div
                            className={[
                              styles.w100,
                              styles.rowView,
                              styles.jcSpaceBet,
                            ].join(" ")}
                          >
                            <span className={[styles.letterSpacing1p1px]}>
                              {data["m_period_id"]}
                            </span>
                            <span
                              className={[
                                styles.gameShortDetailsResult,
                                data.m_status == "profit"
                                  ? styles.gameShortDetailsResultGreen
                                  : data.m_status == "loss"
                                    ? styles.gameShortDetailsResultRed
                                    : "",
                              ].join(" ")}
                            >
                              {data.m_status == "profit"
                                ? "success"
                                : data.m_status == "loss"
                                  ? "Failed"
                                  : ""}
                            </span>
                          </div>

                          <div
                            className={[
                              styles.w100,
                              styles.rowView,
                              styles.jcSpaceBet,
                              styles.mgT5px,
                            ].join(" ")}
                          >
                            <span className={[styles.fontS13Px]}>
                              {data.m_time_stamp}
                            </span>
                            <span
                              className={[
                                data.m_status == "profit"
                                  ? styles.colorGreen
                                  : styles.colorRed,
                              ]}
                            >
                              {data.m_status == "profit"
                                ? "₹" + data.m_profit
                                : data.m_status == "loss"
                                  ? "-₹" + data.m_invested
                                  : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={[
                          styles.mygameRecordsDetails,
                          data["m_order_id"] != selectedRecord
                            ? styles.hideView
                            : "",
                        ].join(" ")}
                      >
                        <span className={[styles.mygameRecordsDetailsTitle]}>
                          Details
                        </span>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT10px,
                          ].join(" ")}
                        >
                          <span>Order Id</span>
                          <span>{data["m_order_id"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Period</span>
                          <span>{data["m_period_id"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Purchase Amount</span>
                          <span>₹{data["m_cost"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Quantity</span>
                          <span>{data["m_color_lot"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Tax</span>
                          <span className={[styles.colorRed]}>
                            ₹{data["m_fee"]}
                          </span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Result</span>
                          <span>{data["m_result"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Status</span>
                          <span>{data["m_status"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Win/Loss</span>
                          <span
                            className={[
                              data.m_status == "profit"
                                ? styles.colorGreen
                                : styles.colorRed,
                            ]}
                          >
                            {data.m_status == "profit"
                              ? "₹" + data.m_profit
                              : data.m_status == "loss"
                                ? "-₹" + data.m_invested
                                : ""}
                          </span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Order Time</span>
                          <span>{data["m_time_stamp"]}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <Link
                  className="w-100 v-center pd-10 mg-t-15 br-15 cl-golden txt-deco-n"
                  to={"/myorders"}
                >
                  View All Records
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wingo10Min;