import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar2";
import "../../MainStyle.css";
import { getStorage } from "../modals/Storage";
import general from "../css/General.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function CustomerSupport() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Customer Support",
    recordList: [],
  });

  const topBarClickAction = (data) => {};

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <div className="zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={true}
            multiBtn1=""
            multiBtn2=""
            background={1}
            updateState={topBarClickAction}
          />

            <div className="mg-t-55">
              <div className="cs-helpline-head-img">
                <img src={require("../media/icons/agent-helpline-img.png")} />
              </div>
              <div className="cs-helpline-content">
                <div>
                  <Link to={"https://t.me/JinxWebs"} style={{ textDecoration: "none" }}>
                    <div className="item">
                      <div className="title">
                        <FontAwesomeIcon
                          style={{
                            width: "35px",
                            height: "40px",
                            color: "#0088cc",
                          }}
                          icon={faTelegram}
                        />
                        <span>Telegram</span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        className="tranf-rotate-180"
                        fill="rgb(168, 168, 168)"
                      >
                        <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CustomerSupport;
