import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar2";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL, copyText } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import ToastDialog from "../dialogs/ToastDialog/toast";
import general from "../css/General.module.css";

function WithdrawlRecords() {
  const toastDialogRef = useRef(null);
  const navigate = useNavigate();
  const listViewRef = useRef();
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Withdraw Records",
    pageCount: 1,
    recordList: [],
  });

  // toast component
  const onToastChange = (data, msg) => {};

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const onIDClicked = (id) => {
    if (id != "") {
      copyText(id);
      showToast("ID Copied!", 2500, 0, 1);
    }
  };

  const topBarClickAction = (data) => {
    if (data == "multiBtn2") {
      navigate("/addbankcard", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const onScroll = () => {
    if (listViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        let newPageVal = pageConst.pageCount + 1;
        setConstants((previousState) => {
          return { ...previousState, pageCount: newPageVal };
        });
      }
    }
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pr-v w-100 col-view pd-15 br-5 mg-t-15 bg-white cl-grey"
        >
          <div className="row-view sb-view ft-sz-15">
            <div className="pd-2-8 br-5 cl-white bg-red-grad-9">Withdraw</div>

            <div
              className={`br-5 ${
                data[i]["w_status"] == "success"
                  ? "cl-green"
                  : data[i]["w_status"] == "rejected"
                  ? "cl-red"
                  : "cl-primary"
              }`}
            >
              {data[i]["w_status"] == "success"
                ? "Completed"
                : data[i]["w_status"] == "rejected"
                ? "Cancelled"
                : "Processing"}
            </div>
          </div>

          <span
            className="row-view sb-view ft-sz-15 mg-t-10"
            style={{ display: "flex", alignItems: "baseline" }}
          >
            <span>Balance:</span>
            <span
              className="cl-norm-orange"
              style={{
                display: "flex",
                alignItems: "baseline",
                marginLeft: "5px",
              }}
            >
              <span style={{ fontSize: "18px" }}>₹</span>
              <span style={{ fontSize: "18px",font: "inherit", fontFamily:"'Roboto', 'Inter', 'sans-serif'", fontWeight: "700" }}>
                 {data[i]["w_request"]} 
              </span>
            </span>
          </span>

          <div className="row-view sb-view ft-sz-14 mg-t-10">
            <span>Type:</span>
            <span>BANK CARD</span>
          </div>

          <div className="row-view sb-view ft-sz-14 mg-t-10">
            <span>Time</span>
            <span>{data[i]["w_time"]}</span>
          </div>

          <div className="row-view sb-view ft-sz-14 mg-t-10">
            <span className="ft-sz-15 mg-t-5">Order number</span>

            <div
              className="row-view"
              onClick={() => onIDClicked(data[i]["w_uniq_id"])}
            >
              <span>{data[i]["w_uniq_id"]}</span>
              <img
                className="h-w-15 mg-l-10"
                src={require("../media/icons/copy_icon.png")}
              />
            </div>
          </div>
          <div className="sb-view ft-sz-14 mg-t-10">
            <span>Remarks</span>
            {data[i]["w_admin_remarks"] ? <textarea disabled className="remarks-withdraw">{data[i]["w_admin_remarks"]}</textarea> : ""}
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getWithdawRecords() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-withdraw-records",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(
      API_ACCESS_URL +
        "?USER_ID=" +
        getStorage("uid") +
        "&PAGE_NUM=" +
        pageConst.pageCount
    );
  }

  useEffect(() => {
    getWithdawRecords(pageConst.pageCount);
    const listInnerElement = listViewRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
        ref={listViewRef}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.colView, general.mgT50px].join(" ")}>
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">{pageConst.recordList}</div>
            <div className="TeamReport__C-body-end">No more</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawlRecords;
