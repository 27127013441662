import React from 'react';
import { Link } from 'react-router-dom';
import { getCookie } from '../modals/Cookie';
import '../../MainStyle.css';
function CustomerSupport() {
    return (
        <Link style={{textDecoration:"none"}}
                to={"/main/CustomerSupport"} >
        <div
            className="customer"
            id="customerId"
            style={{
                '--f13b4d11-currentFontFamily': "'Roboto', 'Inter', sans-serif",
                '--f6a705e1-currentFontFamily': 'bahnschrift',
            }}
        >
            <img
                className=""
                data-origin="/assets/png/icon_sevice-9f0c8455.png"
                src={require("../media/icons/icon_sevice.png")}
                alt="Service Icon" 
            />
        </div>
        </Link>
    );
}

export default CustomerSupport;