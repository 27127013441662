import React from "react";
import VerticalPicker from "../other-components/VerticalPicker";
import { Box } from "@mui/material";

const TierPickerBody = ({ Tier, setTier }) => {
  // Define the tier options
  const tierOptions = ["All", "Tier 1", "Tier 2", "Tier 3"];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <VerticalPicker
        initialValue={Tier} // Use the Tier prop as the initial value
        onChange={setTier}
        options={tierOptions}
      />
    </Box>
  );
};

export default TierPickerBody;