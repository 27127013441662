import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar2";
import "../../MainStyle.css";
import { getStorage } from "../modals/Storage";
import general from "../css/General.module.css";

function PromotionRules() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Rules",
    recordList: [],
  });

  const topBarClickAction = (data) => {};

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <div className="zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={true}
            multiBtn1=""
            multiBtn2=""
            updateState={topBarClickAction}
          />

          <div className="col-view mg-b-15">
            <div className="col-view v-center w-100 pd-5-15 mg-t-45">
              <div className="promotion-main">
                <div className="promotion-main__title">
                  <h1>【Promotion partner】program</h1>
                  <p>This activity is valid for a long time</p>
                </div>
                <div className="promotion-box ruleHead">
                  <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">01</div>
                  <div className="promotion-txt">
                    <p>
                      There are 6 subordinate levels in inviting friends, if A
                      invites B, then B is a level 1 subordinate of A. If B
                      invites C, then C is a level 1 subordinate of B and also a
                      level 2 subordinate of A. If C invites D, then D is a
                      level 1 subordinate of C, at the same time a level 2
                      subordinate of B and also a level 3 subordinate of A.
                    </p>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">02</div>
                  <div className="promotion-txt">
                    <p>
                      When inviting friends to register, you must send the
                      invitation link provided or enter the invitation code
                      manually so that your friends become your level 1
                      subordinates.
                    </p>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">03</div>
                  <div className="promotion-txt">
                    <p>
                      The invitee registers via the inviter's invitation code
                      and completes the deposit, shortly after that the
                      commission will be received immediately.
                    </p>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                                  <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">04</div>
                  <div className="promotion-txt">
                    <p>
                      The calculation of yesterday's commission starts every
                      morning at 01:00. After the commission calculation is
                      completed, the commission is rewarded to the wallet and
                      can be viewed through the commission collection record.
                    </p>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                                  <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">05</div>
                  <div className="promotion-txt">
                    <p>
                      Commission rates vary depending on your agency level on
                      that day. Number of Teams: How many downline deposits you
                      have to date. Team Deposits: The total number of deposits
                      made by your downline in one day. Team Deposit: Your
                      downline deposits within one day.
                    </p>
                  </div>
                </div>
                <div className="promotion-grade">
                  <div className="promotion-grade-th">
                    <div className="item">Rebate level</div>
                    <div className="item">Team Number</div>
                    <div className="item">Team Betting</div>
                    <div className="item">Team Deposit</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L0</span>
                      </div>
                    </div>
                    <div className="item">0</div>
                    <div className="item">0</div>
                    <div className="item">0</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L1</span>
                      </div>
                    </div>
                    <div className="item">5</div>
                    <div className="item">500K</div>
                    <div className="item">100K</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L2</span>
                      </div>
                    </div>
                    <div className="item">10</div>
                    <div className=" item">1,000K</div>
                    <div className="item">200K</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L3</span>
                      </div>
                    </div>
                    <div className="item">15</div>
                    <div className="item">2.50M</div>
                    <div className="item">500K</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L4</span>
                      </div>
                    </div>
                    <div className="item">20</div>
                    <div className="item">3.50M</div>
                    <div className="item">700K</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L5</span>
                      </div>
                    </div>
                    <div className="item">25</div>
                    <div className="item">5M</div>
                    <div className="item">1,000K</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L6</span>
                      </div>
                    </div>
                    <div className="item">30</div>
                    <div className="item">10M</div>
                    <div className="item">2M</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L7</span>
                      </div>
                    </div>
                    <div className="item">100</div>
                    <div className="item">100M</div>
                    <div className="item">20M</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L8</span>
                      </div>
                    </div>
                    <div className="item">500</div>
                    <div className="item">500M</div>
                    <div className="item">100M</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L9</span>
                      </div>
                    </div>
                    <div className="item">1000</div>
                    <div className="item">1,000M</div>
                    <div className="item">200M</div>
                  </div>
                  <div className="promotion-grade-tr">
                    <div className="item">
                      <div className="icon-LV">
                        <span className="txt">L10</span>
                      </div>
                    </div>
                    <div className="item">5000</div>
                    <div className="item">1,500M</div>
                    <div className="item">300M</div>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                                  <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">06</div>
                  <div className="promotion-txt">
                    <p>
                      The commission percentage depends on the membership level.
                      The higher the membership level, the higher the bonus
                      percentage. Different game types also have different
                      payout percentages.
                    </p>
                    <div className="txt">Soon &gt;&gt;</div>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                                  <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">07</div>
                  <div className="promotion-txt">
                    <p>
                      TOP20 commission rankings will be randomly awarded with a
                      separate bonus.
                    </p>
                  </div>
                </div>
                <div className="promotion-box ruleHead">
                                  <svg
                    className="svg-icon icon-ruleHead"
                    viewBox="0 0 489 60"
                    fill="#f95959"
                  >
                    <path
                      d="M-2 -0.0310078V-4H492V-0.0310078C485 -0.0310078 470.65 -0.0310078 463 4.43411C454.5 9.39535 450 12.8682 439 35.1938C429.492 54.4913 413.5 59.6693 408 60H83C66 60 53.5 42.1395 50.5 36.186C47.5 30.2326 44.0048 21.3075 33.5 9.89147C23 -1.51938 8 -0.0310078 -2 -0.0310078Z"
                      fill="var(--sheet_nva_color, var(--bg_color_L3))"
                    ></path>
                  </svg>
                  <div className="promotion-title">08</div>
                  <div className="promotion-txt">
                    <p>
                      The final interpretation of this activity belongs to
                      [Web Name].
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionRules;
