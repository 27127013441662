import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar2";
import { getStorage } from "../modals/Storage";
import "../../MainStyle.css";
import general from "../css/General.module.css";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

function InvitationRewardRules() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [pageConst] = useState({
    pageTitle: "Invitation Link",
  });

  const topBarClickAction = (data) => {};

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      getReferBonus();
    }
  }, []);

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };
  function getReferBonus() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-activity-bonus",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const responseData = await res.json();
        updateLoadingStatus(false);
        if (responseData.status_code === "success") {
            setTableData(responseData.data); 
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }
  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />

        <div className={[general.colView, general.mgB10px].join(" ")}>
          <div
            className={[
              general.viewCenter,
              general.colView,
              general.pd5px15px,
              general.mgT50px,
            ].join(" ")}
          >
            <LoadingDialog intentData={showLoading} />
            <>
              <div className="tip-container">
                <div className="tip">
                  Invite friends and recharge to get additional platform rewards!
                </div>
                <div className="tip">
                  After being claimed, the rewards will be directly distributed to the{" "}
                  <b>vault wallet</b> balance within 10 minutes.
                </div>
              </div>

              <div className="tablecontainer">
                <div className="tableconatiner-wrapper">
                  {/* Table Header */}
                  <div className="tableconatiner-titlebox">
                    <div className="tableconatiner-title">Invite Account</div>
                    <div className="tableconatiner-title">Deposit Amount</div>
                    <div className="tableconatiner-title">Bonus</div>
                  </div>

                  {/* Table Rows */}
                  {tableData.length > 0 ? (
                    tableData.map((data, index) => (
                      <div className="tableconatiner-row" key={index}>
                        <div className="tableconatiner-cell">{data.requiredinvitee}People</div>
                        <div className="tableconatiner-cell">₹{parseFloat(data.requiredrechargeam).toFixed(2)}</div>
                        <div className="tableconatiner-cell">₹{parseFloat(data.rewardamount).toFixed(2)}</div>
                      </div>
                    ))
                  ) : (
                    <div className="tableconatiner-row">
                      <div className="tableconatiner-cell" colSpan="3">
                        No data available
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="rule-container">
                <div className="head">Rules</div>
                <div className="text-container">
                    <div>Only when the number of invited accounts is reached and each account can meet the recharge amount can you receive the bonus.</div>
                </div>
                <div className="text-container">
                    <div>The invitation account meets the requirements, but the recharge amount of the account does not meet the requirements, and the bonus cannot be claimed.</div>
                </div>
                <div className="text-container">
                    <div>Please claim the event bonus within the event period. All bonuses will be cleared after the event expires.</div>
                </div>
                <div className="text-container">
                    <div>Please complete the task within the event period. After the event expires, the invitation record will be cleared.</div>
                </div>
              </div>
              
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationRewardRules;
