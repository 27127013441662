import React from "react";
import { Box, Button, Typography } from "@mui/material";

const TierPickerHeader = ({ onCancel, onConfirm }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "7px 16px", // Adjusted padding for better spacing
      backgroundColor: "#fff",
      borderRadius: "8px 8px 0 0", // Add a rounded corner for the top
    }}
  >
    <Button
      onClick={onCancel}
      sx={{
        color: "grey",
        textTransform: "none",
        fontWeight: "normal",
      }}
    >
      Cancel
    </Button>

    <Button
      onClick={onConfirm}
      sx={{
        color: "#f95959",
        textTransform: "none",
        fontWeight: "normal",
      }}
    >
      Confirm
    </Button>
  </Box>
);

export default TierPickerHeader;
