import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import { API_ACCESS_URL, getURLParam } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function AddBankCard() {
  const navigate = useNavigate();
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [isBankValCorrect, setInBankCorrect] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Add Bank Card",
    inAccountNum: "",
    inBeneficiary: "",
    inIFSCCode: "",
    inBankName: "",
    inBranchName: "",
    inPrimaryOption: "true",
    bankCardMethod: "",
    tabActiveReord: "bank",
    bankList: [],
    recordList: [],
  });
  const onToastChange = (data, msg) => { };
  const toastDialogRef = useRef(null);
  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };
  const topBarClickAction = (data) => {};

  const updateActiveTab = (data) => {
    setConstants((previousState) => {
      return { ...previousState, tabActiveReord: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const checkForInputVal = (beneficiary, accountNum, ifscCode) => {
    if (beneficiary.length > 3 && accountNum.length > 5) {
      if (pageConst.tabActiveReord != "upi" && ifscCode.length > 4) {
        setInValCorrect(true);
      } else if (pageConst.tabActiveReord == "upi") {
        setInValCorrect(true);
      } else {
        setInValCorrect(false);
      }
    } else {
      setInValCorrect(false);
    }
  };

  const onInputValChange = (source, data) => {
    if (source == "beneficiary") {
      checkForInputVal(data, pageConst.inAccountNum, pageConst.inIFSCCode);

      setConstants((previousState) => {
        return { ...previousState, inBeneficiary: data };
      });
    }

    if (source == "accountNum") {
      checkForInputVal(pageConst.inBeneficiary, data, pageConst.inIFSCCode);

      setConstants((previousState) => {
        return { ...previousState, inAccountNum: data };
      });
    }

    if (source == "ifscCode") {
      checkForInputVal(pageConst.inBeneficiary, pageConst.inAccountNum, data);

      setConstants((previousState) => {
        return { ...previousState, inIFSCCode: data };
      });
    }
  };

  const setPrimaryOption = (data) => {
    setConstants((previousState) => {
      return { ...previousState, inPrimaryOption: data };
    });
  };

  const verifyIFSCCode = () => {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url);
        const data = await res.json();

        updateLoadingStatus(false);
        if (data != "Not Found" && data["BANK"] != "" && data["BRANCH"] != "") {
          setInBankCorrect(true);
          setConstants({
            ...pageConst,
            inBankName: data["BANK"],
            inBranchName: data["BRANCH"],
          });
          checkForInputVal(
            pageConst.inBeneficiary,
            pageConst.inAccountNum,
            pageConst.inIFSCCode
          );
        } else {
          setInBankCorrect(false);
          checkForInputVal(
            pageConst.inBeneficiary,
            pageConst.inAccountNum,
            pageConst.inIFSCCode
          );
          updateToastDialogState(true, "Invalid IFSC Code! Please try again!");
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast("There was a technical issue! Please try again!");
      }
    };

    if (pageConst.inIFSCCode.length > 4) {
      updateLoadingStatus(true);
      fecthApiData("https://ifsc.razorpay.com/" + pageConst.inIFSCCode);
    } else {
      updateToastDialogState(true, "Please enter IFSC Code!");
    }
  };

  function addNewBankCard() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-add-bankcard",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          navigate(-1);
        } else if (data.status_code == "already_exist") {
          showToast("Already exist", 2500, 0, 1);
        } else if (data.status_code == "limit_reached") {
          showToast(
            "Limit reached. You can't add more. Please contact CS for further info"
          );
        } else {
          showToast("Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (isInputValCorrect) {
      updateLoadingStatus(true);
      fecthApiData(
        API_ACCESS_URL +
          "?USER_ID=" +
          getStorage("uid") +
          "&BENEFICIARY_NAME=" +
          pageConst.inBeneficiary +
          "&USER_BANK_NAME=" +
          pageConst.inBankName +
          "&USER_BANK_ACCOUNT=" +
          pageConst.inAccountNum +
          "&USER_BANK_IFSC_CODE=" +
          pageConst.inIFSCCode +
          "&IS_PRIMARY=" +
          pageConst.inPrimaryOption +
          "&CARD_METHOD=" +
          pageConst.tabActiveReord
      );
    }
  }

  const getBankList = () => {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-get-banklist",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        setConstants((previousState) => {
          return { ...previousState, bankList: data.data.banklist };
        });
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(API_ACCESS_URL);
  };

  useEffect(() => {
    // getBankList();
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={false}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
         <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <LoadingDialog intentData={showLoading} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            {/* <div className="tab-slct-v w-100 mg-t-10">
              <div
                className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${
                  pageConst.tabActiveReord == "upi" ? "active" : ""
                }`}
                onClick={() => updateActiveTab("upi")}
              >
                UPI
              </div>
              <div
                className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${
                  pageConst.tabActiveReord == "bank" ? "active" : ""
                }`}
                onClick={() => updateActiveTab("bank")}
              >
                BANK
              </div>
            </div> */}

            <div
              className={[
                general.colView,
                general.pd10px15px,
                general.mgT15px,
                pageConst.tabActiveReord == "upi" ? general.hideView : "",
              ].join(" ")}
            >
              {/* <div>
                <select
                  className={[
                    general.height50px,
                    general.inputSelectBox,
                    general.borderRadius10px,
                    general.bgCustomColor6,
                  ].join(" ")}
                >
                  {pageConst.bankList.map((data, index) => (
                    <option>{data["bankName"]}</option>
                  ))}
                </select>
              </div> */}

              <div
                className={[
                  styles.inputView,
                  general.colView,
                  general.width100,
                ].join(" ")}
              >
                <span
                  className={[general.fontS13Px, general.colorGrey].join(
                    " "
                  )}
                >
                  Beneficiary Name:
                </span>

                <input
                  type="text"
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.mgT10px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                  placeholder="Please enter full name"
                  autoComplete="new-password"
                  onChange={(e) =>
                    onInputValChange("beneficiary", e.target.value)
                  }
                ></input>
              </div>

              <div
                className={[
                  styles.inputView,
                  general.colView,
                  general.width100,
                  general.mgT30px,
                ].join(" ")}
              >
                <span
                  className={[general.fontS13Px, general.colorGrey].join(
                    " "
                  )}
                >
                  Account Number:
                </span>

                <input
                  type="text"
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.mgT10px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                  placeholder="Please enter account number"
                  autoComplete="new-password"
                  onChange={(e) =>
                    onInputValChange("accountNum", e.target.value)
                  }
                ></input>
              </div>

              <div
                className={[
                  styles.inputView,
                  general.colView,
                  general.width100,
                  general.mgT30px,
                ].join(" ")}
              >
                <span
                  className={[general.fontS13Px, general.colorGrey].join(
                    " "
                  )}
                >
                  IFSC:
                </span>

                <div
                  className={[
                    general.width100,
                    general.rowView,
                    general.mgT10px,
                  ].join(" ")}
                >
                  <input
                    type="text"
                    className={[
                      general.inputBox,
                      general.height50px,
                      general.borderRadius10px,
                      general.bgWhite,
                    ].join(" ")}
                    placeholder="Please enter IFSC code"
                    autoComplete="new-password"
                    onChange={(e) =>
                      onInputValChange("ifscCode", e.target.value)
                    }
                    readOnly={isBankValCorrect ? true : false}
                  ></input>

                  <div
                    className={[
                      general.height50px,
                      general.width125px,
                      general.viewCenter,
                      general.colorWhite,
                      general.fontS17Px,
                      general.mgL5px,
                      general.borderRadius,
                      general.bgCustomColor2,
                    ].join(" ")}
                    onClick={() => verifyIFSCCode()}
                  >
                    Verify
                  </div>
                </div>
              </div>

              <div
                className={[
                  general.colView,
                  general.mgT10px,
                  pageConst.inBankName == "" ? general.hideView : "",
                ].join(" ")}
              >
                <p
                  className={[general.fontS13Px, general.colorGrey].join(
                    " "
                  )}
                >
                  Bank Name: {pageConst.inBankName}
                </p>
              </div>

              <div className="w-100 mg-t-25">
                <div
                  className={[
                    general.height50px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT10px,
                    general.borderRadius,
                    isInputValCorrect
                      ? isBankValCorrect
                        ? general.bgCustomColor2
                        : general.bgDisable
                      : general.bgDisable,
                  ].join(" ")}
                  onClick={() => addNewBankCard()}
                >
                  Save
                </div>
              </div>
            </div>

            <div
              className={`col-view pd-18 br-10 mg-t-15 ${
                pageConst.tabActiveReord == "bank" ? "hide-v" : ""
              }`}
            >
              <div className="col-view w-100 pd-5-15 mg-t-20">
                <span
                  className={[general.fontS13Px, general.colorLightWhite].join(
                    " "
                  )}
                >
                  Beneficiary Name:
                </span>
                <input
                  type="text"
                  className="cutm-inp mg-t-5"
                  placeholder="Please Input"
                  onChange={(e) =>
                    onInputValChange("beneficiary", e.target.value)
                  }
                ></input>
              </div>

              <div className="line-hv-grey mg-t-5"></div>

              <div className="col-view w-100 pd-5-15 mg-t-20">
                <span
                  className={[general.fontS13Px, general.colorLightWhite].join(
                    " "
                  )}
                >
                  UPI Id:
                </span>
                <input
                  type="text"
                  className="cutm-inp mg-t-5"
                  placeholder="Please Input"
                  onChange={(e) =>
                    onInputValChange("accountNum", e.target.value)
                  }
                ></input>
              </div>

              <div className="line-hv-grey mg-t-5"></div>

              <div className="col-view w-100 pd-5-15 mg-t-20">
                <span
                  className={[general.fontS13Px, general.colorLightWhite].join(
                    " "
                  )}
                >
                  Set as Primary:
                </span>
                <select className="cutm-inp mg-t-10">
                  <option value="Yes" onClick={() => setPrimaryOption("true")}>
                    Yes
                  </option>
                  <option value="No" onClick={() => setPrimaryOption("false")}>
                    No
                  </option>
                </select>
              </div>

              <div className="line-hv-grey mg-t-5"></div>

              <div className="w-100 pd-5-15 mg-t-25">
                <div
                  className={`w-100 h-50-p ft-sz-20 v-center br-10 cl-white ${
                    isInputValCorrect ? "bg-red-grad-2" : "bg-grey-2"
                  }`}
                  onClick={() => addNewBankCard()}
                >
                  Add
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBankCard;
