import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import {
  API_ACCESS_URL,
  IP_ACCESS_URL,
  generateAuthToken,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import TopBar from "../other-components/AuthTopBar/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import SliderCaptcha from "../other-components/SliderCaptcha";

function Login() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [isPasswordTypeShow, setPasswordType] = useState(false);
  const [tabActive, setTabActive] = useState("phone");
  const [isPhone, setIsPhone] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [userIP, setUserIP] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Login",
    inMobileNum: "",
    inPassword: "",
    toastMessage: "",
    isSessionExist: true,
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }

    if (data == "multiBtn2") {
      navigate("/register", { replace: false });
    }
  };

  const updatePasswordtype = () => {
    if (isPasswordTypeShow) {
      setPasswordType(false);
    } else {
      setPasswordType(true);
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  // toast component
  const onToastChange = (data, msg) => {};

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const onInputValChange = (source, data) => {
    if (source == "mobile") {
      setIsPhone(data.length > 0);
      setConstants((previousState) => {
        return { ...previousState, inMobileNum: data };
      });
    }
    if (source == "email") {
      setIsEmail(data.length > 0);
      setConstants((previousState) => {
        return { ...previousState, inMobileNum: data };
      });
    }
    if (source == "password") {
      setConstants((previousState) => {
        return { ...previousState, inPassword: data };
      });
    }
  };
  useEffect(() => {
    if (isPhone) {
      setInValCorrect(true);
    } else {
      setInValCorrect(false);
    }
  }, [isPhone, isEmail]);

  const rememberme = () => {
    setIsChecked(!isChecked);
  };

  let loginButtonStyle = isInputValCorrect
    ? { background: "linear-gradient(90deg, #f95959 0%, #ff9a8e 100%)" }
    : {
        background: "linear-gradient(90deg, #CCCEDC 15.38%, #CDCFDD 98.73%)",
        color: "#768096",
      };

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setUserIP(data.ip));
  }, []);

  const handleOnCaptchaSolved = (number, password, ip) => {
    setShowCaptcha(false);
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-login",
            AuthToken: generateAuthToken(pageConst.inMobileNum, ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(true);
        if (data.status_code == "user_not_exist") {
          setTimeout(() => {
            updateLoadingStatus(false);
          }, 2000);
          showToast("Error: 502 Wrong account or password", 3000, 2500);
        } else if (data.status_code == "invalid_mobile_num") {
          setTimeout(() => {
            updateLoadingStatus(false);
          }, 2000);
          showToast("Error: 147 Wrong number", 3000, 2500);
        } else if (data.status_code == "invalid_params") {
          setTimeout(() => {
            updateLoadingStatus(false);
          }, 2000);
          showToast("Error: 147 Wrong number or password", 3000, 2500);
        } else if (data.status_code == "password_error") {
          setTimeout(() => {
            updateLoadingStatus(false);
          }, 2000);
          showToast("Error: 147 Wrong password", 3000, 2500);
        } else if (data.status_code == "success") {
          setStorage("uid", data.data[0].account_id, 30);
          setStorage("mobile", data.data[0].account_mobile_num, 30);
          setStorage("balance", data.data[0].account_balance, 30);
          setStorage("secret", data.data[0].auth_secret_key, 30);
          setStorage("isMusicOn", "true");
          navigate("/home", { replace: true });
        } else {
          showToast("Something went wrong! Please try again!");
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast("There was a technical issue! Please try again!");
      }
    }

    if (isInputValCorrect) {
      const formData = {
        LOGIN_ID: pageConst.inMobileNum,
        LOGIN_PASSWORD: pageConst.inPassword,
      };
      requestAPI(API_ACCESS_URL, formData);
      updateLoadingStatus(true);
    }
  };

  useEffect(() => {
    if (getStorage("uid")) {
      navigate("/home", { replace: true });
    } else {
      setConstants((previousState) => {
        return { ...previousState, isSessionExist: false };
      });
    }
  }, []);

  const handleOnCaptchaFailed = () => {
    setShowCaptcha(false);
    showToast("Error: 30 Verification timed out, please try again");
  };

  const handleOnCaptchaRefresh = () => {
    showToast("success", 3000, 500,1);
  };


  return (
    <div
      className={[
        general.viewCenter,
        general.appBackground,
        pageConst.isSessionExist == true ? general.hideView : "",
      ].join(" ")}
    >
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.posRelative]}>
          <TopBar />

          <div
            className={[
              styles.loginView,
              general.colView,
              general.flexDirColumn,
              general.width100,
              general.mgT50px,
            ].join(" ")}
          >
            <div
              className={[
                general.colView,
                general.pd15px20px,
                general.bgCustomColor1,
              ].join(" ")}
            >
              <span
                className={[
                  general.colorWhite,
                  general.fontS20Px,
                  pageConst.pageTitle == "" ? general.hideView : "",
                ].join(" ")}
              >
                {pageConst.pageTitle}
              </span>
              <span
                className={[
                  general.colorWhite,
                  general.fontS13Px,
                  general.mgT10px,
                ].join(" ")}
              >
                Please enter your mobile number to continue.<br></br>
                If you need support then please contact us.
              </span>
            </div>

            <div
              className={[styles.loginTabSelectView, styles.width100].join(" ")}
            >
              <div
                className={[
                  general.colView,
                  general.alignCenter,
                  general.width100,
                  general.pd10px,
                  tabActive === "phone" ? styles.loginTabSelectedView : "",
                ].join(" ")}
                onClick={() => setTabActive("phone")}
              >
                {tabActive === "phone" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon icon-phone"
                    viewBox="0 0 48 48"
                    fill="#f95959"
                  >
                    <path
                      opacity="0.6"
                      d="M7.20001 9.19999C7.20001 5.88628 9.8863 3.19998 13.2 3.19998H34.8C38.1137 3.19998 40.8 5.88627 40.8 9.19998V28H7.20001V9.19999Z"
                      fill="#f95959"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M40.8 29.6H7.20001V38.8C7.20001 42.1137 9.88631 44.8 13.2 44.8H34.8C38.1137 44.8 40.8 42.1137 40.8 38.8V29.6ZM20 33.6C19.1164 33.6 18.4 34.3163 18.4 35.2C18.4 36.0836 19.1164 36.8 20 36.8H28C28.8837 36.8 29.6 36.0836 29.6 35.2C29.6 34.3163 28.8837 33.6 28 33.6H20Z"
                      fill="#f95959"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon icon-phone"
                    viewBox="0 0 48 48"
                    fill="#768096"
                  >
                    <path
                      opacity="0.6"
                      d="M7.20001 9.19999C7.20001 5.88628 9.8863 3.19998 13.2 3.19998H34.8C38.1137 3.19998 40.8 5.88627 40.8 9.19998V28H7.20001V9.19999Z"
                      fill="#768096"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M40.8 29.6H7.20001V38.8C7.20001 42.1137 9.88631 44.8 13.2 44.8H34.8C38.1137 44.8 40.8 42.1137 40.8 38.8V29.6ZM20 33.6C19.1164 33.6 18.4 34.3163 18.4 35.2C18.4 36.0836 19.1164 36.8 20 36.8H28C28.8837 36.8 29.6 36.0836 29.6 35.2C29.6 34.3163 28.8837 33.6 28 33.6H20Z"
                      fill="#768096"
                    ></path>
                  </svg>
                )}
                <span
                  className={
                    tabActive === "phone" ? "activeTabText" : "inactiveTabText"
                  }
                >
                  phone number
                </span>
              </div>

              <div
                className={[
                  general.colView,
                  general.alignCenter,
                  general.width100,
                  general.pd10px,
                  tabActive === "email" ? styles.loginTabSelectedView : "",
                ].join(" ")}
                onClick={() => setTabActive("email")}
              >
                {tabActive === "email" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    fill="#f95959"
                    className="svg-icon icon-phone"
                  >
                    <path
                      d="M17 23.7768L2.36511 38C2.1234 37.4588 1.99911 36.875 2 36.285V12.6171C2.00034 12.0625 2.11154 11.5133 2.32741 11L17 23.7768Z"
                      fill="#f95959"
                    ></path>
                    <path
                      d="M23.0524 26.6409L4 9.69183C4.69859 9.24036 5.5142 9.00002 6.34774 9H41.6508C42.4853 8.99887 43.3019 9.24019 44 9.69423L28.6605 23.3414L28.56 23.4268L24.9443 26.6414C24.6841 26.8724 24.3473 27.0001 23.9982 27C23.6492 26.9999 23.3124 26.8721 23.0524 26.6409Z"
                      fill="#f95959"
                    ></path>
                    <path
                      d="M29.4114 25.2188L43.9245 39.3748C43.2439 39.7856 42.4588 40.0022 41.6583 40H6.26378C5.4641 40.0021 4.67982 39.7855 4 39.3748L18.5122 25.2188L23.0862 29.2161C23.3262 29.4266 23.6376 29.5431 23.9603 29.5431C24.2831 29.5431 24.5944 29.4266 24.8344 29.2161L29.4114 25.2188ZM46 12.5838V35.7657C46.0007 36.343 45.8796 36.9142 45.6442 37.4439L31.3624 23.5136L45.6805 11C45.8913 11.5027 45.9998 12.0407 46 12.5838Z"
                      fill="#f95959"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    fill="#768096"
                    className="svg-icon icon-phone"
                  >
                    <path
                      d="M17 23.7768L2.36511 38C2.1234 37.4588 1.99911 36.875 2 36.285V12.6171C2.00034 12.0625 2.11154 11.5133 2.32741 11L17 23.7768Z"
                      fill="#768096"
                    ></path>
                    <path
                      d="M23.0524 26.6409L4 9.69183C4.69859 9.24036 5.5142 9.00002 6.34774 9H41.6508C42.4853 8.99887 43.3019 9.24019 44 9.69423L28.6605 23.3414L28.56 23.4268L24.9443 26.6414C24.6841 26.8724 24.3473 27.0001 23.9982 27C23.6492 26.9999 23.3124 26.8721 23.0524 26.6409Z"
                      fill="#768096"
                    ></path>
                    <path
                      d="M29.4114 25.2188L43.9245 39.3748C43.2439 39.7856 42.4588 40.0022 41.6583 40H6.26378C5.4641 40.0021 4.67982 39.7855 4 39.3748L18.5122 25.2188L23.0862 29.2161C23.3262 29.4266 23.6376 29.5431 23.9603 29.5431C24.2831 29.5431 24.5944 29.4266 24.8344 29.2161L29.4114 25.2188ZM46 12.5838V35.7657C46.0007 36.343 45.8796 36.9142 45.6442 37.4439L31.3624 23.5136L45.6805 11C45.8913 11.5027 45.9998 12.0407 46 12.5838Z"
                      fill="#768096"
                    ></path>
                  </svg>
                )}
                <span
                  className={
                    tabActive === "email" ? "activeTabText" : "inactiveTabText"
                  }
                >
                  Email Login
                </span>
              </div>
            </div>

            {/* Conditional Content Rendering Based on Tab */}
            {tabActive === "phone" && (
              <div
                className={[
                  styles.inputView,
                  general.colView,
                  general.width100,
                  general.mgT20px,
                ].join(" ")}
              >
                <div className={[general.rowView]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-icon icon-phone"
                    viewBox="0 0 48 48"
                    fill="#f95959"
                  >
                    <path
                      opacity="0.6"
                      d="M7.20001 9.19999C7.20001 5.88628 9.8863 3.19998 13.2 3.19998H34.8C38.1137 3.19998 40.8 5.88627 40.8 9.19998V28H7.20001V9.19999Z"
                      fill="#f95959"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M40.8 29.6H7.20001V38.8C7.20001 42.1137 9.88631 44.8 13.2 44.8H34.8C38.1137 44.8 40.8 42.1137 40.8 38.8V29.6ZM20 33.6C19.1164 33.6 18.4 34.3163 18.4 35.2C18.4 36.0836 19.1164 36.8 20 36.8H28C28.8837 36.8 29.6 36.0836 29.6 35.2C29.6 34.3163 28.8837 33.6 28 33.6H20Z"
                      fill="#f95959"
                    ></path>
                  </svg>
                  <label>Phone Number</label>
                </div>

                <div
                  className={[
                    general.width100,
                    general.rowView,
                    general.spaceBetween,
                    general.mgT10px,
                  ].join(" ")}
                >
                  <div
                    className={[
                      general.viewCenter,
                      general.height50px,
                      general.pd5px25px,
                      general.borderRadius10px,
                      general.bgWhite,
                    ].join(" ")}
                  >
                    <span className={[general.colorGrey]}>+91</span>
                    <img
                      className={[
                        general.heightWidth18px,
                        general.mgL10px,
                        general.transformRotate90deg,
                      ].join(" ")}
                      src={require("../media/icons/angle_right_icon.png")}
                      alt="icon"
                    />
                  </div>

                  <input
                    type="number"
                    className={[
                      general.inputBox,
                      general.height50px,
                      general.mgL10px,
                      general.borderRadius10px,
                      general.bgWhite,
                    ].join(" ")}
                    placeholder="Please enter phone number"
                    autoComplete="off"
                    onChange={(e) => onInputValChange("mobile", e.target.value)}
                  ></input>
                </div>
              </div>
            )}

            {tabActive === "email" && (
              <div
                className={[
                  styles.inputView,
                  general.colView,
                  general.width100,
                  general.mgT20px,
                ].join(" ")}
              >
                <div className={[general.rowView]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    fill="#f95959"
                    className="svg-icon icon-phone"
                  >
                    <path
                      d="M17 23.7768L2.36511 38C2.1234 37.4588 1.99911 36.875 2 36.285V12.6171C2.00034 12.0625 2.11154 11.5133 2.32741 11L17 23.7768Z"
                      fill="#f95959"
                    ></path>
                    <path
                      d="M23.0524 26.6409L4 9.69183C4.69859 9.24036 5.5142 9.00002 6.34774 9H41.6508C42.4853 8.99887 43.3019 9.24019 44 9.69423L28.6605 23.3414L28.56 23.4268L24.9443 26.6414C24.6841 26.8724 24.3473 27.0001 23.9982 27C23.6492 26.9999 23.3124 26.8721 23.0524 26.6409Z"
                      fill="#f95959"
                    ></path>
                    <path
                      d="M29.4114 25.2188L43.9245 39.3748C43.2439 39.7856 42.4588 40.0022 41.6583 40H6.26378C5.4641 40.0021 4.67982 39.7855 4 39.3748L18.5122 25.2188L23.0862 29.2161C23.3262 29.4266 23.6376 29.5431 23.9603 29.5431C24.2831 29.5431 24.5944 29.4266 24.8344 29.2161L29.4114 25.2188ZM46 12.5838V35.7657C46.0007 36.343 45.8796 36.9142 45.6442 37.4439L31.3624 23.5136L45.6805 11C45.8913 11.5027 45.9998 12.0407 46 12.5838Z"
                      fill="#f95959"
                    ></path>
                  </svg>
                  <label>Email</label>
                </div>

                <div
                  className={[
                    general.width100,
                    general.rowView,
                    general.spaceBetween,
                    general.mgT10px,
                  ].join(" ")}
                >
                  <input
                    type="email"
                    className={[
                      general.inputBox,
                      general.height50px,
                      general.borderRadius10px,
                      general.bgWhite,
                    ].join(" ")}
                    placeholder="please input your email"
                    autoComplete="off"
                    onChange={(e) => onInputValChange("email", e.target.value)}
                  ></input>
                </div>
              </div>
            )}

            {/* Password Field (Static) */}
            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
                <svg
                  class="icon-editPswIcon icon-phone"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  fill="#f95959"
                >
                  <path
                    opacity="0.6"
                    d="M24.0022 34.7C24.8668 34.7 25.696 34.3565 26.3074 33.7452C26.9187 33.1338 27.2622 32.3046 27.2622 31.44C27.2622 30.5754 26.9187 29.7462 26.3074 29.1348C25.696 28.5235 24.8668 28.18 24.0022 28.18C23.1376 28.18 22.3084 28.5235 21.697 29.1348C21.0857 29.7462 20.7422 30.5754 20.7422 31.44C20.7422 32.3046 21.0857 33.1338 21.697 33.7452C22.3084 34.3565 23.1376 34.7 24.0022 34.7Z"
                    fill="#f95959"
                  ></path>
                  <path
                    d="M33.3 18.882H14.7C6.5 18.882 4 21.382 4 29.582V33.302C4 41.502 6.5 44.002 14.7 44.002H33.3C41.5 44.002 44 41.502 44 33.302V29.582C44 21.382 41.5 18.882 33.3 18.882ZM24 37.482C20.66 37.482 17.96 34.762 17.96 31.442C17.96 28.122 20.66 25.402 24 25.402C27.34 25.402 30.04 28.122 30.04 31.442C30.04 34.762 27.34 37.482 24 37.482Z"
                    fill="#f95959"
                  ></path>
                  <path
                    opacity="0.6"
                    d="M14.238 18.9V16.56C14.238 10.7 15.898 6.8 23.998 6.8C32.098 6.8 33.758 10.7 33.758 16.56V18.9C34.778 18.92 35.698 18.96 36.558 19.08V16.56C36.558 11.16 35.258 4 23.998 4C12.738 4 11.438 11.16 11.438 16.56V19.06C12.278 18.96 13.218 18.9 14.238 18.9Z"
                    fill="#f95959"
                  ></path>
                </svg>

                <label>Password</label>
              </div>

              <div className={[general.posRelative, general.mgT10px].join(" ")}>
                <input
                  type={isPasswordTypeShow ? "text" : "password"}
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                  placeholder="Password (≥6 characters)"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("password", e.target.value)}
                ></input>

                <div
                  className="ps-ab ps-tp-50 t-form-y ps-rgt-10"
                  onClick={(e) => updatePasswordtype(e)}
                >
                  {isPasswordTypeShow ? (
                    <img
                      className="h-w-22"
                      src={require("../icons/eye_open_icon.png")}
                    />
                  ) : (
                    <img
                      className="h-w-22"
                      src={require("../icons/eye_closed_icon.png")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              role="checkbox"
              className="van-checkbox"
              tabIndex="0"
              onClick={rememberme}
              aria-checked={isChecked}
            >
              <div
                className={`van-checkbox__icon van-checkbox__icon--round ${
                  isChecked ? "van-checkbox__icon--checked" : ""
                }`}
              >
                <i className="van-badge__wrapper van-icon van-icon-success"></i>
              </div>
              <span className="van-checkbox__label">Remember password</span>
            </div>

            <div
              className={[general.pd15px]}
            >
              <div
                style={loginButtonStyle}
                className={[
                  general.height50px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS25Px,
                  general.mgT10px,
                  general.borderRadius30px,
                  general.ltspacing4px,
                ].join(" ")}
                onClick={() => setShowCaptcha(true)}
              >
                {showCaptcha && (
                  <div className={styles.captchaContainer}>
                    <SliderCaptcha
                      onSuccess={handleOnCaptchaSolved}
                      onFail={handleOnCaptchaFailed}
                      onRefresh={handleOnCaptchaRefresh}
                    />
                  </div>
                )}
                Login
              </div>

              <Link
                className={[
                  general.textDecoNone,
                  general.height50px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS20Px,
                  general.mgT10px,
                  general.borderRadius30px,
                  general.bgCustomColor3,
                ].join(" ")}
                to={"/register"}
              >
                Register
              </Link>
            </div>

            <div
              className={[
                general.rowView,
                general.spaceBetween,
                general.pd20px40px50px40px,
              ].join(" ")}
            >
              <Link
                className={[
                  general.colView,
                  general.alignCenter,
                  general.textDecoNone,
                ].join(" ")}
                to={"/forgotpassword"}
              >
                <img
                  className={[general.heightWidth45px]}
                  src={require("../media/icons/password_icon.png")}
                  alt="icon"
                />
                <span
                  className={[general.fontS14Px, general.colorGrey].join(" ")}
                >
                  Forgot Password
                </span>
              </Link>

              <Link
                className={[
                  general.colView,
                  general.alignCenter,
                  general.textDecoNone,
                ].join(" ")}
                to={"/forgotpassword"}
              >
                <img
                  className={[general.heightWidth45px]}
                  src={require("../media/icons/customer_service_icon.png")}
                  alt="icon"
                />
                <span
                  className={[general.fontS14Px, general.colorGrey].join(" ")}
                >
                  Customer Service
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
