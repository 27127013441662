import { useLocation } from 'react-router-dom';
import K3Lottery1Min from './Game';
import K3Lottery3Min from './Game2';
import K3Lottery5Min from './Game3';
import K3Lottery10Min from './Game4';

const K3LotteryHandler = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  switch (id) {
    case '1':
      return <K3Lottery1Min />;
    case '2':
      return <K3Lottery3Min />;
    case '3':
      return <K3Lottery5Min />;
    case '4':
      return <K3Lottery10Min />;
    default:
      return;
  }
};

export default K3LotteryHandler;
