import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../AuthTopBar/TopBar.module.css";
import general from "../../css/General.module.css";

const TopBar = () => {
  const navigate = useNavigate();

  return (
    <nav
      className={[
        general.respWidth,
        general.colView,
        styles.topBarView,
        general.bgCustomColor1,
      ].join(" ")}
    >
      <div
        className={[styles.headView, general.viewCenter, styles.flexContainer].join(" ")}
      >
        <Link onClick={() => navigate(-1)}>
          <img
            src={require("../../media/icons/angle_left_white_icon.png")}
            alt="icon"
          />
        </Link>

        <img src="/config/app_logo.png" alt="logo" />


      </div>
    </nav>
  );
};

export default TopBar;
