import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import {
  API_ACCESS_URL,
  IS_OTP_AVAILABLE,
  generateAuthToken,
  getURLParam,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import TopBar from "../other-components/AuthTopBar/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

function Register() {
  const navigate = useNavigate();
  const Ref = useRef(null);
  const toastDialogRef = useRef(null);
  const [resendOTPTimeLeft, setResendOTPTime] = useState(0);
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [isPasswordTypeShow, setPasswordType] = useState(false);
  const [tabActive, setTabActive] = useState("phone");
  const [isChecked, setIsChecked] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Register",
    inMobileNum: "",
    inPassword: "",
    inConfirmPassword: "",
    inInviteCode: "",
    inVerificationCode: "",
    isSessionExist: true,
    isOTPSending: false,
    resendOTPTimeLimit: 60,
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn2") {
      navigate("/login", { replace: false });
    }
  };

  const updatePasswordtype = () => {
    if (isPasswordTypeShow) {
      setPasswordType(false);
    } else {
      setPasswordType(true);
    }
  };
  const condition = () => {
    setIsChecked(!isChecked); 
  };
  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateOTPSendingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isOTPSending: data };
    });
  };

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const isMobileNumValidate = (mobilenum) => {
    if (mobilenum != "" && mobilenum != undefined && mobilenum.length == 10) {
      return true;
    } else {
      return false;
    }
  };

  const isPasswordValidate = (password, confirmPassword) => {
    if (
      password != "" &&
      password != undefined &&
      password.length >= 6 &&
      password == confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkForInputVal = (
    mobilenum,
    password,
    confirmPassword,
    inviteCode,
    verificationCode
  ) => {
    if (
      isMobileNumValidate(mobilenum) &&
      isPasswordValidate(password, confirmPassword)
    ) {
      if (IS_OTP_AVAILABLE && verificationCode.length > 4) {
        setInValCorrect(true);
      } else if (!IS_OTP_AVAILABLE) {
        setInValCorrect(true);
      }
    } else {
      setInValCorrect(false);
    }
  };

  const onInputValChange = (source, data) => {
    if (source == "mobile") {
      checkForInputVal(
        data,
        pageConst.inPassword,
        pageConst.inConfirmPassword,
        pageConst.inInviteCode,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inMobileNum: data };
      });
    }

    if (source == "password") {
      checkForInputVal(
        pageConst.inMobileNum,
        data,
        pageConst.inConfirmPassword,
        pageConst.inInviteCode,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inPassword: data };
      });
    }

    if (source == "confirmPassword") {
      checkForInputVal(
        pageConst.inMobileNum,
        pageConst.inPassword,
        data,
        pageConst.inInviteCode,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inConfirmPassword: data };
      });
    }

    if (source == "inviteCode") {
      checkForInputVal(
        pageConst.inMobileNum,
        pageConst.inPassword,
        pageConst.inConfirmPassword,
        data,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inInviteCode: data };
      });
    }

    if (source == "verificationCode") {
      checkForInputVal(
        pageConst.inMobileNum,
        pageConst.inPassword,
        pageConst.inConfirmPassword,
        pageConst.inInviteCode,
        data
      );

      setConstants((previousState) => {
        return { ...previousState, inVerificationCode: data };
      });
    }
  };

  const validateMyIP = (route) => {
    updateLoadingStatus(true);

    let ip = "192.168.56.120";

    if (!IS_OTP_AVAILABLE) {
      return sendVerificationCode(ip);
    }

    if (route == "OTP") {
      return sendVerificationCode(ip);
    } else {
      return validateSignup(ip);
    }

    // const requestAPI = async (url) => {
    //   try {
    //     const res = await fetch(url, {
    //       method: "GET",
    //     });

    //     const data = await res.json();
    //     if (data.ip != "") {
    //       if (route == "OTP") {
    //         return sendVerificationCode(data.ip);
    //       } else {
    //         return validateSignup(data.ip);
    //       }
    //     } else {
    //       return "IPUNKNOWN";
    //     }
    //   } catch (error) {
    //     return "IPOUTERROR";
    //   }
    // };

    // requestAPI(IP_ACCESS_URL);
  };

  const validateSignup = (ip) => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-create-account",
            AuthToken: generateAuthToken(pageConst.inMobileNum, ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "invalid_otp") {
          showToast(
            "OTP is incorrect ! Please enter correct OTP!"
          );
        } else if (data.status_code == "invalid_refer_code") {
          showToast(
            "Invite Code is incorrect ! Please enter correct Invite Code!"
          );
        } else if (data.status_code == "success") {
          setStorage("uid", data.data[0].account_id, 30);
          setStorage("mobile", data.data[0].account_mobile_num, 30);
          setStorage("balance", data.data[0].account_balance, 30);
          setStorage("secret", data.data[0].auth_secret_key, 30);
          setStorage("isMusicOn", "true");
          navigate("/home", { replace: true });
        } else if (data.status_code == "password_error") {
          showToast(true, "Password not correct! try again");
        } else if (data.status_code == "already_registered") {
          showToast(
            "This phone number is already registered!"
          );
        } else {
          showToast(
            "Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast(
          "There was a technical issue! Please try again!"
        );
      }
    };

    if (isInputValCorrect) {
      let inpOTP = pageConst.inVerificationCode;

      if (!IS_OTP_AVAILABLE) {
        inpOTP = "NO_OTP";
      }

      const formData = {
        SIGNUP_MOBILE: pageConst.inMobileNum,
        SIGNUP_PASSWORD: pageConst.inPassword,
        SIGNUP_OTP: inpOTP,
        SIGNUP_INVITE_CODE: pageConst.inInviteCode,
      };
      if(isChecked){
        requestAPI(API_ACCESS_URL, formData);
      }else{
        showToast(
          "Please agree to the user Agreement!"
        );
      }
      
    } else {
      updateLoadingStatus(false);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startCountDownTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setResendOTPTime(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const getDeadTime = (e) => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + e);
    return deadline;
  };

  const clearTimer = (e) => {
    setResendOTPTime(30);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startCountDownTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const sendVerificationCode = (ip) => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: "route-send-sms",
            AuthToken: generateAuthToken(pageConst.inMobileNum, ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);
        updateOTPSendingStatus(false);
console.log(data)
        if (data.status_code == "otp_error") {
          showToast(
            "We failed to send OTP ! Please try again!"
          );
        } else if (data.status_code == "already_registered") {
          showToast(
            "Already registered account"
          );
        } else if (data.status_code == "mobile_num_error") {
          showToast("Wrong phone number");
        } else if (data.status_code == "otp_limit_error") {
          showToast(
            "You've used daily limited OTP ! Please try later!"
          );
        } else if (data.status_code == "success") {
          if (IS_OTP_AVAILABLE) {
            setStorage(
              "otptimeout",
              getDeadTime(pageConst.resendOTPTimeLimit),
              30
            );
            clearTimer(getDeadTime(pageConst.resendOTPTimeLimit));
          } else {
            let ip = "192.168.56.120";
            validateSignup(ip);
          }
        } else {
          showToast(
            "Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateOTPSendingStatus(false);
        showToast(
          "There was a technical issue! Please try again!"
        );
      }
    };

    if (isMobileNumValidate(pageConst.inMobileNum)) {
      if (resendOTPTimeLeft > 0) {
        updateLoadingStatus(false);
        showToast("Please after sometime!");
      } else if (pageConst.isOTPSending == false) {
        updateOTPSendingStatus(true);
        const formData = { SMS_MOBILE: pageConst.inMobileNum };
        requestAPI(API_ACCESS_URL, formData);
      }
    } else {
      updateLoadingStatus(false);
      showToast("Wrong phone number");
    }
  };

  useEffect(() => {
    if (getStorage("uid")) {
      navigate("/home", { replace: true });
    } else {
      setConstants({ ...pageConst, isSessionExist: false });

      if (getStorage("otptimeout")) {
        let { total, seconds } = getTimeRemaining(getStorage("otptimeout"));
        if (seconds > 0) {
          clearTimer(getDeadTime(seconds));
        }
      }
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.hash.split('?')[1]);
        const referCodeValue = params.get('c');

      if (referCodeValue != null && referCodeValue != "") {
        let referCode = referCodeValue;
        
        setConstants((previousState) => {
          return { ...previousState, inInviteCode: referCode };
        });
      }
    }
  }, []);

  return (
    <div
      className={[
        general.viewCenter,
        general.appBackground,
        pageConst.isSessionExist == true ? general.hideView : "",
      ].join(" ")}
    >
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.posRelative]}>
          <TopBar />

          <div
            className={[
              styles.loginView,
              general.colView,
              general.flexDirColumn,
              general.width100,
              general.mgT50px,
            ].join(" ")}
          >
            <div
              className={[
                general.colView,
                general.pd15px20px,
                general.bgCustomColor1,
              ].join(" ")}
            >
              <span
                className={[
                  general.colorWhite,
                  general.fontS20Px,
                  pageConst.pageTitle == "" ? general.hideView : "",
                ].join(" ")}
              >
                {pageConst.pageTitle}
              </span>
              <span
                className={[
                  general.colorWhite,
                  general.fontS13Px,
                  general.mgT10px,
                ].join(" ")}
              >
                Please enter your mobile number to continue.<br></br>
                If you need support then please contact us.
              </span>
            </div>

            <div
              className={[styles.loginTabSelectView, styles.width100].join(" ")}
            >
              <div
                className={[
                  general.colView,
                  general.alignCenter,
                  general.width100,
                  general.pd10px,
                  tabActive == "phone" ? styles.loginTabSelectedView : "",
                ].join(" ")}
                onClick={() => setTabActive("phone")}
              >
                {tabActive == "phone" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon icon-phone" viewBox="0 0 48 48" fill="#f95959">
                  <path opacity="0.6" d="M7.20001 9.19999C7.20001 5.88628 9.8863 3.19998 13.2 3.19998H34.8C38.1137 3.19998 40.8 5.88627 40.8 9.19998V28H7.20001V9.19999Z" fill="#f95959"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M40.8 29.6H7.20001V38.8C7.20001 42.1137 9.88631 44.8 13.2 44.8H34.8C38.1137 44.8 40.8 42.1137 40.8 38.8V29.6ZM20 33.6C19.1164 33.6 18.4 34.3163 18.4 35.2C18.4 36.0836 19.1164 36.8 20 36.8H28C28.8837 36.8 29.6 36.0836 29.6 35.2C29.6 34.3163 28.8837 33.6 28 33.6H20Z" fill="#f95959"></path>
                </svg>
                ) : (
                  <img
                    src={require("../icons/mobile_not_active_icon.png")}
                    alt="icon"
                  />
                )}
                <span style={{color: "#f95959"}}>Register via Phone</span>
              </div>

            </div>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
                general.mgT20px,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
              <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon icon-phone" viewBox="0 0 48 48" fill="#f95959">
    <path opacity="0.6" d="M7.20001 9.19999C7.20001 5.88628 9.8863 3.19998 13.2 3.19998H34.8C38.1137 3.19998 40.8 5.88627 40.8 9.19998V28H7.20001V9.19999Z" fill="#f95959"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M40.8 29.6H7.20001V38.8C7.20001 42.1137 9.88631 44.8 13.2 44.8H34.8C38.1137 44.8 40.8 42.1137 40.8 38.8V29.6ZM20 33.6C19.1164 33.6 18.4 34.3163 18.4 35.2C18.4 36.0836 19.1164 36.8 20 36.8H28C28.8837 36.8 29.6 36.0836 29.6 35.2C29.6 34.3163 28.8837 33.6 28 33.6H20Z" fill="#f95959"></path>
  </svg>
                <label className="mg-l-10">Phone Number</label>
              </div>

              <div
      className={[
        general.width100,
        general.rowView,
        general.spaceBetween,
        general.mgT10px,
      ].join(" ")}
    >
      <div
        className={[
          general.viewCenter,
          general.height50px,
          general.pd5px25px,
          general.borderRadius10px,
          general.bgWhite,
        ].join(" ")}
      >
        <span className={[general.colorGrey]}>+91</span>
        <img
          className={[
            general.heightWidth18px,
            general.mgL10px,
            general.transformRotate90deg,
          ].join(" ")}
          src={require("../media/icons/angle_right_icon.png")}
          alt="icon"
        />
      </div>

      <input
        type="number"
        className={[
          general.inputBox,
          general.height50px,
          general.mgL10px,
          general.borderRadius10px,
          general.bgWhite,
        ].join(" ")}
        placeholder="Please enter phone number"
        autoComplete="off"
        onChange={(e) => onInputValChange("mobile", e.target.value)}
      ></input>
    </div>
            </div>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
              <svg class="icon-editPswIcon icon-phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#f95959">
  <path opacity="0.6" d="M24.0022 34.7C24.8668 34.7 25.696 34.3565 26.3074 33.7452C26.9187 33.1338 27.2622 32.3046 27.2622 31.44C27.2622 30.5754 26.9187 29.7462 26.3074 29.1348C25.696 28.5235 24.8668 28.18 24.0022 28.18C23.1376 28.18 22.3084 28.5235 21.697 29.1348C21.0857 29.7462 20.7422 30.5754 20.7422 31.44C20.7422 32.3046 21.0857 33.1338 21.697 33.7452C22.3084 34.3565 23.1376 34.7 24.0022 34.7Z" fill="#f95959"></path>
  <path d="M33.3 18.882H14.7C6.5 18.882 4 21.382 4 29.582V33.302C4 41.502 6.5 44.002 14.7 44.002H33.3C41.5 44.002 44 41.502 44 33.302V29.582C44 21.382 41.5 18.882 33.3 18.882ZM24 37.482C20.66 37.482 17.96 34.762 17.96 31.442C17.96 28.122 20.66 25.402 24 25.402C27.34 25.402 30.04 28.122 30.04 31.442C30.04 34.762 27.34 37.482 24 37.482Z" fill="#f95959"></path>
  <path opacity="0.6" d="M14.238 18.9V16.56C14.238 10.7 15.898 6.8 23.998 6.8C32.098 6.8 33.758 10.7 33.758 16.56V18.9C34.778 18.92 35.698 18.96 36.558 19.08V16.56C36.558 11.16 35.258 4 23.998 4C12.738 4 11.438 11.16 11.438 16.56V19.06C12.278 18.96 13.218 18.9 14.238 18.9Z" fill="#f95959"></path>
</svg>
                <label className="mg-l-10">Password</label>
              </div>

              <div className="ps-rl mg-t-10">
                <input
                  type={isPasswordTypeShow ? "text" : "password"}
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                  placeholder="Enter your password"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("password", e.target.value)}
                ></input>

                <div
                  className="ps-ab ps-tp-50 t-form-y ps-rgt-10"
                  onClick={(e) => updatePasswordtype(e)}
                >
                  {isPasswordTypeShow ? (
                    <img
                      className="h-w-22"
                      src={require("../icons/eye_open_icon.png")}
                    />
                  ) : (
                    <img
                      className="h-w-22"
                      src={require("../icons/eye_closed_icon.png")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
              <svg class="icon-editPswIcon icon-phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#f95959">
  <path opacity="0.6" d="M24.0022 34.7C24.8668 34.7 25.696 34.3565 26.3074 33.7452C26.9187 33.1338 27.2622 32.3046 27.2622 31.44C27.2622 30.5754 26.9187 29.7462 26.3074 29.1348C25.696 28.5235 24.8668 28.18 24.0022 28.18C23.1376 28.18 22.3084 28.5235 21.697 29.1348C21.0857 29.7462 20.7422 30.5754 20.7422 31.44C20.7422 32.3046 21.0857 33.1338 21.697 33.7452C22.3084 34.3565 23.1376 34.7 24.0022 34.7Z" fill="#f95959"></path>
  <path d="M33.3 18.882H14.7C6.5 18.882 4 21.382 4 29.582V33.302C4 41.502 6.5 44.002 14.7 44.002H33.3C41.5 44.002 44 41.502 44 33.302V29.582C44 21.382 41.5 18.882 33.3 18.882ZM24 37.482C20.66 37.482 17.96 34.762 17.96 31.442C17.96 28.122 20.66 25.402 24 25.402C27.34 25.402 30.04 28.122 30.04 31.442C30.04 34.762 27.34 37.482 24 37.482Z" fill="#f95959"></path>
  <path opacity="0.6" d="M14.238 18.9V16.56C14.238 10.7 15.898 6.8 23.998 6.8C32.098 6.8 33.758 10.7 33.758 16.56V18.9C34.778 18.92 35.698 18.96 36.558 19.08V16.56C36.558 11.16 35.258 4 23.998 4C12.738 4 11.438 11.16 11.438 16.56V19.06C12.278 18.96 13.218 18.9 14.238 18.9Z" fill="#f95959"></path>
</svg>
                <label className="mg-l-10">Confirm Password</label>
              </div>

              <input
                type="password"
                className={[
                  general.inputBox,
                  general.height50px,
                  general.mgT10px,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                placeholder="Enter your password again"
                autoComplete="new-password"
                onChange={(e) =>
                  onInputValChange("confirmPassword", e.target.value)
                }
              ></input>
            </div>

            {IS_OTP_AVAILABLE ? (
              <div
                className={[
                  styles.inputView,
                  general.colView,
                  general.width100,
                ].join(" ")}
              >
                <div className={[general.rowView]}>
                  <img
                    className="h-w-28"
                    src={require("../media/icons/otp_verify.png")}
                  />
                  <label className="mg-l-10">Verification Code</label>
                </div>

                <div className="row-view mg-t-10">
                  <input
                    type="text"
                    className={[
                      general.inputBox,
                      general.height50px,
                      general.borderRadius10px,
                      general.bgWhite,
                    ].join(" ")}
                    placeholder="Enter the confirmation code"
                    autoComplete="new-password"
                    onChange={(e) =>
                      onInputValChange("verificationCode", e.target.value)
                    }
                  >
                    
                  </input>
                  <div
                    className={[
                      general.height50px,
                      general.width125px,
                      general.viewCenter,
                      general.colorWhite,
                      general.fontS16Px,
                      general.mgL5px,
                      general.borderRadius,
                      general.otpverification,
                    ].join(" ")}
                    onClick={() => validateMyIP("OTP")}
                  >
                    {pageConst.isOTPSending
                      ? "Sending"
                      : resendOTPTimeLeft > 0
                        ? resendOTPTimeLeft
                        : "OTP"}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}


            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
              <svg className="icon-phone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#f95959">
  <g id="login_list_icon">
    <path d="M37.38 23.06C36.24 22.76 34.9 22.6 33.3 22.6C31.08 22.6 30.26 23.14 29.12 24C29.06 24.04 29 24.1 28.94 24.16L27.04 26.18C25.46 27.88 22.56 27.88 20.96 26.18L19.06 24.18C19.0105 24.1103 18.9496 24.0495 18.88 24C17.72 23.14 16.9 22.6 14.7 22.6C13.1 22.6 11.76 22.74 10.62 23.06C5.85998 24.34 5.85999 28.12 5.85999 31.44V33.3C5.85999 38.32 5.85998 44 16.56 44H31.44C38.54 44 42.14 40.4 42.14 33.3V31.44C42.14 28.12 42.14 24.34 37.38 23.06Z" fill="#f95959"></path>
    <path opacity="0.4" d="M29.58 4H18.42C9.58002 4 9.58002 8.7 9.58002 12.84V24.42C9.58002 24.86 9.78002 25.26 10.12 25.52C10.46 25.78 10.92 25.88 11.34 25.76C12.24 25.52 13.36 25.4 14.7 25.4C16.04 25.4 16.32 25.56 17.12 26.16L18.94 28.08C19.591 28.7751 20.3778 29.3293 21.2516 29.7081C22.1254 30.0869 23.0676 30.2824 24.02 30.2824C24.9724 30.2824 25.9146 30.0869 26.7884 29.7081C27.6622 29.3293 28.449 28.7751 29.1 28.08L30.92 26.16C31.72 25.56 32 25.4 33.34 25.4C34.68 25.4 35.8 25.52 36.7 25.76C37.12 25.88 37.56 25.78 37.92 25.52C38.26 25.26 38.46 24.84 38.46 24.42V12.84C38.42 8.7 38.42 4 29.58 4Z" fill="#f95959"></path>
    <path d="M27.1 19.824H20.9C20.12 19.824 19.5 19.204 19.5 18.424C19.5 17.644 20.12 17.024 20.9 17.024H27.1C27.88 17.024 28.5 17.644 28.5 18.424C28.5 19.184 27.86 19.824 27.1 19.824ZM28.658 14.238H19.338C18.558 14.238 17.938 13.618 17.938 12.838C17.938 12.058 18.558 11.438 19.338 11.438H28.638C29.0093 11.4354 29.3664 11.5803 29.6309 11.841C29.8953 12.1017 30.0453 12.4567 30.048 12.828C30.0506 13.1993 29.9057 13.5565 29.645 13.8209C29.3843 14.0853 29.0293 14.2354 28.658 14.238Z" fill="#f95959"></path>
  </g>
</svg>

                <label className="mg-l-10">Invite Code</label>
              </div>

              <input
                type="text"
                className={[
                  general.inputBox,
                  general.height50px,
                  general.mgT10px,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                placeholder="Invite Code"
                value={pageConst.inInviteCode}
                autoComplete="new-password"
                onChange={(e) => onInputValChange("inviteCode", e.target.value)}
              ></input>
            </div>
<div
      role="checkbox"
      className="van-checkbox"
      tabIndex="0"
      onClick={condition}
      aria-checked={isChecked}
    >
      <div
        className={`van-checkbox__icon van-checkbox__icon--round ${
          isChecked ? "van-checkbox__icon--checked" : ""
        }`}
      >
        <i
          className="van-badge__wrapper van-icon van-icon-success"
        ></i>
      </div>
      <span className="van-checkbox__label">I have read and agree <a
    href="#/user-agreement" 
    style={{ color: "#f95959", textDecoration: "none" }}
  >
    【Privacy Agreement】
  </a></span>
    </div>
            <div className={[general.pd15px15px50px15px]} >
              <div
                className={[
                  general.height50px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS20Px,
                  general.mgT10px,
                  general.borderRadius30px,
                  general.bgCustomColor2,
                ].join(" ")}
                onClick={() => validateMyIP("REGISTER")}
              >
                Register
              </div>

              <div
                className={[
                  general.textDecoNone,
                  general.height50px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS17Px,
                  general.mgT10px,
                  general.borderRadius30px,
                  general.bgCustomColor4,
                ].join(" ")}
              >
                <span>I have an account?</span>
                <Link
                  className={[
                    general.textDecoNone,
                    general.colorRed,
                    general.mgL5px,
                  ].join(" ")}
                  to={"/login"}
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
