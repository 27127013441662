import React from 'react';
import "../css/logout.css";

const Dialog = ({ isActive, onConfirm, onCancel }) => {
  return (
    <div className={`dialog ${isActive ? 'active' : 'inactive'}`}>
      <div className="dialog__container" role="dialog" tabIndex="0">
        <div 
        className="dialog__container-img">
          <img
            alt=""
            data-origin={require("../media/icons/warning-logout.png")}
            src={require("../media/icons/warning-logout.png")}
          />
        </div>
        <div className="dialog__container-title">
          <h1>Do you want to log out?</h1>
        </div>
        <div className="dialog__container-footer">
          <button onClick={onConfirm}>Confirm</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
      <div className="dialog__outside" onClick={onCancel}></div>
    </div>
  );
};

export default Dialog;