import React, { useState, useEffect } from "react";
import TopBar from "../other-components/TopBar2";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteStorage, getStorage } from "../modals/Storage";
function getURLParam(search, paramName) {
  const params = new URLSearchParams(search);
  return params.get(paramName);
}

const AllGamesList = () => {
  const [tabActive, setTabActive] = useState("LOTTERY");
  const [imageList, setImageList] = useState({});
  const navigate = useNavigate();
  const [pageConst, setPageConst] = useState({
    pageTitle: "All",
    isLoadingShow: false,
    recordList: [],
  });
  const [showLoading, setShowLoading] = useState(false);
  const location = useLocation();
  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 2,
    toastMessage: "",
  });

  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  useEffect(() => {
    const type = getURLParam(location.search, "type");
    if (type) {
      setTabActive(type.toUpperCase());
    }
  }, [location.search]);

  useEffect(() => {
    // Initialize image lists for each tab
    setImageList({
      SLOTS: [
        {
          id: 1,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/xGuraajrU9KTqWcDg1kT3g2mGije4QXM7hHKC6nQ.png",
        },
        {
          id: 2,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/zG3yxYNJvQTLJpMNbvHZawLbHIzIzXmStAYJZlbz.png",
        },
        {
          id: 30,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/cBnpBRaY11rdZjsBk0r5xnYF30smtkn7LwggSNSx.png",
        },
        {
          id: 31,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/zsD94pEK7R7XJQgPzcjECGgpnJ0D80LudxkmlqKy.png",
        },
        {
          id: 32,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/HATQOczYHAaTBMTevThuGPBJmGQTLoNpwKYCGJee.png",
        },
        {
          id: 33,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/5NRgrLmjiuySjpmvq5zxMli4WQph6nhCGwCSzCka.png",
        },
        {
          id: 34,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/ev52lnWrF6soryGTnt5qafnuPOdHzhDjXoPlTPqf.png",
        },
        {
          id: 35,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/3flwlSPsPtpqvPxZh2MKZuUNhGFvNzYO8YPucdi0.png",
        },
        {
          id: 36,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/N0ObtVvimsYAGsfVkhpnDiKjw9MyTd8rH4CpWuCj.png",
        },
        {
          id: 37,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/c5VgTbR3axH1Xv9noEJdJygBF8yn8dL2pGZ2f76w.png",
        },
        {
          id: 38,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/iVleim1PQh0ru4zigjdFheWL4okAc8869Nt3unkK.png",
        },
        {
          id: 39,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/AumHgFmKxZ6LpHgzvK5gqbLZyvgeryiYiGp3uigq.png",
        },
        {
          id: 40,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/PkNv3ZL7DNMRewU8nzMHGHjJoRihaopS5KSMliiD.png",
        },
        {
          id: 41,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/0NMm5znugdsgOagdTNGinTDLOCWmBljNVyKBuccK.png",
        },
        {
          id: 42,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/LGmOw0w4iklkLKXz81LfNjNFjDQw5IlZgRSULqy2.png",
        },
        {
          id: 43,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/VZRGHowsPgKVO4Wx1Cg1zaK6UBmN1MVjMHzBuHTA.png",
        },
        {
          id: 44,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/sGwnH7w0dmV98sYjXkGjH20zhziGYkiWqD3pvICz.png",
        },
      ],
      CRASH: [
        {
          id: 4,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/8nQsbKAVvDLXd1e3r66LYWuvXBxmIe0GqgAJK9Hq.png",
        },
        {
          id: 5,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/l2wF3J77OstjG7swSWxDj6Dt1LA2qSPMwGdy52Ts.png",
        },
        {
          id: 6,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/ggJpbsupiHgKSU9tjYcUZZVMkVE9JJs0J7RXK7tN.png",
        },
        {
          id: 26,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/Eyjfgo5I45y09wGjrscRi1Dqpv58DYdi8GMTSpEy.png",
        },
      ],
      MINI: [
        {
          id: 7,
          url: require("../media/jdb/9023.png"),
        },
        {
          id: 8,
          url: require("../media/jdb/9014.png"),
        },
        {
          id: 9,
          url: require("../media/jdb/9018.png"),
        },
        {
          id: 92,
          url: require("../media/jdb/9019.png"),
        },
        {
          id: 91,
          url: require("../media/jdb/9017.png"),
        },
        {
          id: 91,
          url: require("../media/jdb/30009.png"),
        },
        {
          id: 91,
          url: require("../media/jili/235.png"),
        },
        {
          id: 91,
          url: require("../media/jdb/500.png"),
        },
        {
          id: 91,
          url: require("../media/jdb/501.png"),
        },
        {
          id: 91,
          url: require("../media/jili/224.png"),
        },
        {
            id: 91,
            url: require("../media/jdb/9009.png"),
          },
        {
          id: 91,
          url: require("../media/jili/229.png"),
        },
        {
            id: 91,
            url: require("../media/jili/232.png"),
          },
          {
            id: 91,
            url: require("../media/jili/233.png"),
          },
          {
            id: 91,
            url: require("../media/jili/236.png"),
          },
          {
            id: 91,
            url: require("../media/jili/442.png"),
          },
          {
            id: 91,
            url: require("../media/jdb/9016.png"),
          },
      ],
      ROULETTE: [
        {
          id: 10,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/bVg82SVHgWMpKl3W8gW6irFmhHpnN2WOc3ThZV3k.png",
        },
      ],
      CASINO: [
        {
          id: 13,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/NVJ4ZZKj5qmLinIQYKX194xxCaufYFGirTOxAEKZ.png",
        },
        {
          id: 14,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/HgWx6MbSdLY4SmTEcMzYPU1bJHiuow1ZOuARCehN.png",
        },
        {
          id: 15,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/nZqvPRi41lwkbgKCEtv6SgeI8cJPr7aeffAJ7xB7.png",
        },
        {
          id: 16,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/V8PJFvojgsHM5RQZSXOjku5S7evIlPaeh3ZtSYKt.png",
        },
        {
          id: 17,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/uKU7o6jFKOu7JaXAtgH1kXtAp0Pkpg2cXTDK0OW0.png",
        },
        {
          id: 18,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/PSW6euZpSINykaR8oFyOdIZPdZlkowDAPwRRogY4.png",
        },
        {
          id: 16,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/nPx8kZFKWvD4ZLSQKW4PzeyAgL0KsO5oLbaumACM.png",
        },
        {
          id: 17,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/zTnjK2ymD9j3VKVJTHd4urstqG1FhMhtPYdOZDD1.png",
        },
        {
          id: 18,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/xMuyUS9shoFDKa2Cmf7OVS74VLQkHCzZBmBFCEQg.png",
        },
        {
          id: 16,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/2oGhGWYdFohWUbGcGdegLGWPmNenLlCl7ARh06eP.png",
        },
        {
          id: 17,
          url: "https://storage.googleapis.com/tada-cdn-asia/All-In-One/production/img/jiliPlusPlayer/games/004e7zdOZsF9GMIhgFyGQ8AKb4AHjXz0HSTEdUb6.png",
        },
      ],
      LOTTERY: [
        { id: 16, url: "image10.jpg" },
        { id: 17, url: "image11.jpg" },
        { id: 18, url: "image12.jpg" },
      ],
      TRENDING: [
        { id: 19, url: "image10.jpg" },
        { id: 20, url: "image11.jpg" },
        { id: 21, url: "image12.jpg" },
      ],
    });
  }, []);


    const openGame = (url) => {
      if (getStorage("uid")) {
        navigate("/" + url, { replace: false });
      } else {
        redirectToLogin();
      }
    };

  const updateTabActive = (tab) => {
    setTabActive(tab);
  };
  const topBarClickAction = () => {};

  return (
    <div className={`${general.viewCenter} ${general.appBackground}`}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <div className="game-manage-view col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view min-h">
              <div className="alGames__container-sticky">
                <div className="van-sticky">
                  <div className="fun-tabs tabs" activeclassname="tab_active">
                    <div
                      className="fun-tabs__tab-list"
                      style={{
                        transitionTimingFunction:
                          "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
                        transitionDuration: "360ms",
                        transform: "translate3d(0px, 0px, 0px)",
                      }}
                    >
                      {/* Lottery Tab */}
                      <div
                        className={`fun-tab-item ${
                          tabActive === "LOTTERY" ? "activeClassName" : ""
                        }`}
                        onClick={() => updateTabActive("LOTTERY")}
                        style={{
                          color:
                            tabActive === "LOTTERY" ? "rgb(22, 119, 255)" : "",
                        }}
                      >
                        <div className="fun-tab-item__wrap">
                          <div className="fun-tab-item__label">
                            <div
                              className={`tab_item ${
                                tabActive === "LOTTERY" ? "tab_active" : ""
                              }`}
                            >
                              <img
                                src="https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141426883l.png"
                                alt="Lottery"
                              />
                              <span>Lottery</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Mini Games Tab */}
                      <div
                        className={`fun-tab-item ${
                          tabActive === "MINI_GAMES" ? "activeClassName" : ""
                        }`}
                        onClick={() => updateTabActive("MINI_GAMES")}
                      >
                        <div className="fun-tab-item__wrap">
                          <div className="fun-tab-item__label">
                            <div
                              className={`tab_item ${
                                tabActive === "MINI_GAMES" ? "tab_active" : ""
                              }`}
                            >
                              <img
                                src="https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141435wkxx.png"
                                alt="Mini games"
                              />
                              <span>Mini games</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Other Tabs */}
                      {/* Repeat similar structure for each tab and update the active logic */}
                      {[
                        {
                          name: "POPULAR",
                          label: "Popular",
                          img: "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141445b3ka.png",
                        },
                        {
                          name: "SLOTS",
                          label: "Slots",
                          img: "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141457h3ts.png",
                        },
                        {
                          name: "FISHING",
                          label: "Fishing",
                          img: "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141515owja.png",
                        },
                        {
                          name: "PVC",
                          label: "PVC",
                          img: "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_202403111415086ujt.png",
                        },
                        {
                          name: "CASINO",
                          label: "Casino",
                          img: "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141522uvco.png",
                        },
                        {
                          name: "SPORTS",
                          label: "Sports",
                          img: "https://ossimg.91admin123admin.com/91club/gamecategory/gamecategory_20240311141531fugo.png",
                        },
                      ].map((tab) => (
                        <div
                          key={tab.name}
                          className={`fun-tab-item ${
                            tabActive === tab.name ? "activeClassName" : ""
                          }`}
                          onClick={() => updateTabActive(tab.name)}
                        >
                          <div className="fun-tab-item__wrap">
                            <div className="fun-tab-item__label">
                              <div
                                className={`tab_item ${
                                  tabActive === tab.name ? "tab_active" : ""
                                }`}
                              >
                                <img src={tab.img} alt={tab.label} />
                                <span>{tab.label}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* Active Line */}
                      <div
                        className="fun-tabs__active-line"
                        style={{
                          transition: "300ms",
                          width: "0px",
                          height: "3px",
                          transform: "translate3d(53.5px, 0px, 0px)",
                          backgroundColor: "rgb(22, 119, 255)",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-view AllGamesList">
                <div className={general.AllGamesList}>
                  {/* Render images for the active tab */}
                  {tabActive === "LOTTERY" && (
                    <div className="col-view mg-r-10 mg-b-10">
                      <div
                        className={[
                          styles.homeAdditionalGamesTitle,
                          general.Black,
                        ].join(" ")}
                      >
                        <span></span>
                        Lottery
                      </div>
                      <div
                        style={{ paddingLeft: "10px" }}
                        className={[styles.homeView, general.posRelative].join(
                          " "
                        )}
                      >
                        <div
                          className={[
                            styles.flex,
                            styles.colView,
                            styles.stickyView,
                          ].join(" ")}
                        >
                          <div
                            className="ps-rl txt-deco-n"
                            onClick={() =>
                              openGame("home/AllLotteryGames/WinGo?id=1")
                            }
                          >
                            <div className={[styles.homeGameViewBox]}>
                              <img
                                className="h-80-p ps-ab ps-rgt-10 max-height"
                                src={require("../Games/Wingo/icons/4.png")}
                              />
                              <div
                                className="col-view ps-ab ps-lf ps-tp pd-15"
                                style={{ top: "-5px" }}
                              >
                                <span className="ft-sz-20 ft-wgt-700 cl-white ">
                                  Win Go
                                </span>
                                <span className="ft-sz-12 cl-white mg-t-5 opacity-07">
                                  Guess Number
                                </span>
                                <span className="ft-sz-12 cl-white opacity-07">
                                  Green/Red/Purple to win
                                </span>
                              </div>
                            </div>
                          </div>

                          <div
                            className="ps-rl txt-deco-n mg-t-10"
                            onClick={() =>
                              openGame("home/AllLotteryGames/WinGo?id=2")
                            }
                          >
                            <div className={[styles.homeGameViewBox]}></div>
                            <img
                              className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                              src={require("../Games/Wingo/icons/4.png")}
                            />
                            <div
                              className="col-view ps-ab ps-lf ps-tp pd-15"
                              style={{ top: "-5px" }}
                            >
                              <span className="ft-sz-20 ft-wgt-700 cl-white ">
                                Win Go 3Min
                              </span>
                              <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                                Guess Number
                              </span>
                              <span className="ft-sz-12 cl-white opacity-07">
                                Big/Small/Odd/Even
                              </span>
                            </div>
                          </div>

                          <div
                            className="ps-rl txt-deco-n mg-t-10"
                            onClick={() =>
                              openGame("home/AllLotteryGames/K3?id=1")
                            }
                          >
                            <div className={[styles.homeGameViewBox]}></div>
                            <img
                              className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                              src={require("../icons/dice_lottery_icon.png")}
                            />
                            <div
                              className="col-view ps-ab ps-lf ps-tp pd-15"
                              style={{ top: "-5px" }}
                            >
                              <span className="ft-sz-20 ft-wgt-700 cl-white ">
                                K3 Lottery
                              </span>
                              <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                                Guess Number
                              </span>
                              <span className="ft-sz-12 cl-white opacity-07">
                                Green/Red/Purple to win
                              </span>
                            </div>
                          </div>
                          <div
                            className="ps-rl txt-deco-n mg-t-10"
                            onClick={() =>
                              openGame("home/AllLotteryGames/K3?id=2")
                            }
                          >
                            <div className={[styles.homeGameViewBox]}></div>
                            <img
                              className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                              src={require("../icons/dice_lottery_icon.png")}
                            />
                            <div
                              className="col-view ps-ab ps-lf ps-tp pd-15"
                              style={{ top: "-5px" }}
                            >
                              <span className="ft-sz-20 ft-wgt-700 cl-white ">
                                K3 Lottery 3Min
                              </span>
                              <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                                Drop balls
                              </span>
                              <span className="ft-sz-12 cl-white opacity-07">
                                Hit Multiplier to win
                              </span>
                            </div>
                          </div>
                          <div
                            className="ps-rl txt-deco-n mg-t-10"
                            onClick={() => openGame("Wheelocity")}
                          >
                            <div className={[styles.homeGameViewBox]}></div>
                            <img
                              className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                              src={require("../icons/trxlottery_icon.png")}
                            />
                            <div
                              className="col-view ps-ab ps-lf ps-tp pd-15"
                              style={{ top: "-5px" }}
                            >
                              <span className="ft-sz-23 ft-wgt-700 cl-white">
                                Wheelocity
                              </span>
                              <span className="ft-sz-12 cl-white mg-t-10 opacity-07">
                                Guess Animal Number
                              </span>
                              <span className="ft-sz-12 cl-white opacity-07">
                                Elephant/Tiger/Crown to win
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {tabActive === "MINI_GAMES" &&
                    imageList["MINI"] &&
                    imageList["MINI"].length > 0 && (
                      <>
                        <div className="scroll-container">
                          <div
                            className={[
                              styles.homeAdditionalGamesTitle,
                              general.Black,
                            ].join(" ")}
                          >
                            <span></span>
                            Mini games
                          </div>
                          <div className="All-Games-List">
                            {imageList["MINI"].map((image) => (
                              <div
                                key={image.id}
                                className="col-view mg-r-10 mg-b-10 All-Games-content"
                              >
                                <img
                                  style={{ height: "135px" }}
                                  src={image.url}
                                  alt={image.id}
                                  className={[general.imggames].join(" ")}
                                  onClick={() => handleImageClick(image.id)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                  {tabActive === "POPULAR" &&
                    imageList["MINI"] &&
                    imageList["MINI"].length > 0 && (
                      <>
                        <div className="scroll-container">
                          <div
                            className={[
                              styles.homeAdditionalGamesTitle,
                              general.Black,
                            ].join(" ")}
                          >
                            <span></span>
                            Popular
                          </div>
                          <div className="All-Games-List">
                            {imageList["MINI"].map((image) => (
                              <div
                                key={image.id}
                                className="col-view mg-r-10 mg-b-10 All-Games-content-popular"
                              >
                                <img
                                  src={image.url}
                                  alt={image.id}
                                  className={[general.imggames].join(" ")}
                                  onClick={() => handleImageClick(image.id)}
                                />
                                <div style={{letterSpacing:"3px"}} className="odds-calculation">
                                  odds of 9.4%
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                  {tabActive === "ROULETTE" &&
                    imageList["ROULETTE"] &&
                    imageList["ROULETTE"].map((image) => (
                      <div
                        key={image.id}
                        className="col-view mg-r-10 mg-b-10"
                        style={{ width: "calc(20% - 10px)" }}
                      >
                        <img
                          src={image.url}
                          alt={image.id}
                          className={[general.imggames].join(" ")}
                          onClick={() => handleImageClick(image.id)}
                        />
                      </div>
                    ))}

                  {/* Add more tabs here similarly */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllGamesList;
