import React, { useEffect } from "react";

const SliderCaptcha = ({ onSuccess, onFail, onRefresh }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/disk/longbow.slidercaptcha.min.js`;
    script.async = true;
    script.onload = () => {
      const captcha = window.sliderCaptcha({
        id: "slider-captcha",
        width: 317,
        height: 212,
        sliderL: 42, // Slider length
        sliderR: 9,  // Slider radius
        offset: 5,   // Offset for slider alignment
        setSrc: function () {
          return `${process.env.PUBLIC_URL}/images/Pic${Math.floor(Math.random() * 5)}.jpg`;
        },
        onSuccess: onSuccess, // Use the onSuccess passed from parent
        onFail: onFail || (() => alert("Try again!")), // Default to alert if not provided
        onRefresh: onRefresh || (() => console.log("Captcha refreshed")), // Default to console log if not provided
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [onSuccess, onFail, onRefresh]);

  return <div className="slider-body"> <div id="slider-captcha"></div> </div>;
};

export default SliderCaptcha;
