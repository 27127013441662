import React from 'react';
import "../css/GameLoaderDailog.css"; 

const GameLoaderDialog = ({ isActive, onConfirm, onCancel }) => {
    return (
        <div className={`dialog-container ${isActive ? 'dialog-active' : 'dialog-inactive'}`}>
            <div className="dialog-content" role="dialog" tabIndex="0">
                <div className="dialog-title">
                    <h1>Tips</h1>
                </div>
                <p className="dialog-message">Are you sure you want to join the game?</p>
                <div className="dialog-footer">
                    <button className='van-button' onClick={onCancel}>
                        <div>Cancel</div>
                    </button>
                    <button className='van-button' onClick={onConfirm} >
                        <div>Confirm</div>
                    </button>
                </div>
            </div>
            <div 
    className="dialog-outside" 
></div>
        </div>
    );
};

export default GameLoaderDialog;