import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import general from "../css/General.module.css";

function UserAgreement() {
  const navigate = useNavigate();
  const listViewRef = useRef();
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Risk Disclosure Agreement",
    pageCount: 1,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const onScroll = () => {
    if (listViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        let newPageVal = pageConst.pageCount + 1;
        setConstants((previousState) => {
          return { ...previousState, pageCount: newPageVal };
        });
      }
    }
  };



  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
        ref={listViewRef}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">
            <div className="about-container-content">
      <p className="MsoNormal">
        <span lang="EN-US">User Agreement</span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US"></span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US">
          1. To avoid betting disputes, members must read the company's rules
          before entering the app. Once the player "I agree" By entering this
          company to bet, you will be considered to be in agreement with the
          company's User Agreement.
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US"></span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US">
          2. It is the member's responsibility to ensure the confidentiality of
          their account and login information. Any online bets placed using
          your account number and member password will be considered valid.
          Please change your password from time to time. The company is not
          responsible for any compensation for bets made with a stolen account
          and password.
        </span>
        <br />
        <br />
        <span lang="EN-US">
          3. The company reserves the right to change this agreement or the
          game rules or confidentiality rules from time to time. The modified
          terms will take effect on the date specified after the change occurs,
          and the right to make final decisions on all disputes is reserved by
          the company.
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US"></span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US">
          4. Users must be of legal age according to the laws of the country of
          residence to use an online casino or application. Online bets that
          have not been successfully submitted will be considered void.
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US"></span>
      </p>
      <p className="MsoNormal">
        <span lang="EN-US">
          5. When a player is automatically or forcibly disconnected from the
          game before the game result is announced, it will not affect the game
          result.
        </span>
      </p>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAgreement;
