import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar2";
import { getStorage } from "../modals/Storage";
import "../../MainStyle.css";
import general from "../css/General.module.css";
import { API_ACCESS_URL } from "../modals/Constants";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import ToastDialog from '../dialogs/ToastDialog/toast';

function Feedback() {
  const navigate = useNavigate();
  const [pageConst] = useState({
    pageTitle: "Feedback",
    infeedbackmsg: "",
  });
  const toastDialogRef = useRef(null);
  const [feedbackMessage, setFeedbackMessage] = useState(""); // State for feedback message
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };


  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };
  
  const sendFeedback = () => {
    const fetchApiData = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-feedbacks",
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });
  
        const data = await res.json();
        updateLoadingStatus(false);
  
        if (data.status_code === "msg_empty") {
          showToast("Error: Message can't be empty");
        } else if (data.status_code === "success") {
          showToast("Feedback submitted successfully", 2500, 0, 1);
        }
        else if (data.status_code === "insufficient_words") {
        showToast("Feedback message must not less than 50 words", 2500, 0, 2);
      }
      } catch (error) {
        updateLoadingStatus(false);
        console.error("Error sending feedback:", error);
      }
    };
  
    if (feedbackMessage.trim() === "") {
      showToast("Error: 230 Message can't be empty", 2500, 0, 2);
    } else {
      updateLoadingStatus(true);
      const formData = {
        USER_ID: getStorage("uid"),
        MESSAGE: feedbackMessage,
      };
      fetchApiData(API_ACCESS_URL, formData);
    }
  };
  

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={() => {}}
        />
        <LoadingDialog intentData={showLoading} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <div className={[general.colView, general.mgB10px].join(" ")}>
          <div
            className={[
              general.colView,
              general.pd5px15px,
              general.mgT65px,
            ].join(" ")}
          >
            <div className="feedback-container-content">
              <div className="feedback-container-content-text">
                <div className="feedback-container-content-text-wrapper">
                  <textarea
                    name="feedback"
                    cols="30"
                    rows="10"
                    placeholder="Welcome to feedback, please give feedback-please describe the problem in detail when providing feedback, preferably attach a screenshot of the problem you encountered, we will immediately process your feedback!"
                    value={feedbackMessage} // Bind the textarea value to state
                    onChange={(e) => setFeedbackMessage(e.target.value)} // Update state on change
                  ></textarea>
                </div>
              </div>
              <div className="feedback-container-content-describe">
                <h5>Send helpful feedback</h5>
                <h5>Chance to win Mystery Rewards</h5>
                <div>
                  <img
                    src={require("../media/icons/feedbackimg.png")}
                    alt="Feedback"
                  />
                </div>
              </div>
              <div className="feedback-container-footer">
                <button onClick={sendFeedback}>Submit</button> {/* Call sendFeedback on click */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;