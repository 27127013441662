import React, { useEffect, useState, useRef } from "react";
import TopBar from "../other-components/TopBar2";
import ToastDialog from "../dialogs/ToastDialog/toast";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../modals/Storage";
import general from "../css/General.module.css";

function RedeemGiftcard() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [pageConst, setConstants] = useState({
    pageTitle: "Gift",
    isLoadingShow: false,
    availBalance: 0,
    inRedeemCardId: "",
    withdrawlMode: "W",
    recordList: [],
  });
  const [records, setRecords] = useState([]);
  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    } else if (data == "multiBtn2") {
      navigate("/withdrawrecords", { replace: false });
    }
  };


  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const onInputValChange = (source, data) => {
    if (source == "redeemcardid" && data.length >= 1) {
      setInValCorrect(true);
      setConstants((previousState) => {
        return { ...previousState, inRedeemCardId: data };
      });
    } else {
      setInValCorrect(false);
    }
  };

  const claimRedeemCard = () => {
    const fecthApiData = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-claim-giftcard",
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "code_not_exist") {
          showToast("Error: 230 Redemption code error");
        } else if (data.status_code == "balance_limit") {
          showToast(
            "Error: 502 Requirement not meet"
          );
        } else if (data.status_code == "already_applied") {
          showToast(
            "Error: 502 Already received"
          );
        } else if (data.status_code == "success") {
          setStorage("balance", data.account_balance);
          showToast("success", 2500, 0, 1);
          getGiftHistory()
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (isInputValCorrect && pageConst.isLoadingShow == false) {
      updateLoadingStatus(true);
      const formData = {
        USER_ID: getStorage("uid"),
        GIFTCARD_ID: pageConst.inRedeemCardId,
      };
      fecthApiData(API_ACCESS_URL, formData);
    }
  };

  const getGiftHistory = () => {
    const fecthApiData = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-giftcard-history",
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);
        setRecords(data.data);
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

      updateLoadingStatus(true);
      const formData = {
        USER_ID: getStorage("uid"),
      };
      fecthApiData(API_ACCESS_URL, formData);
  };

useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      getGiftHistory();
    }
  }, []);

  
  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className="zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={false}
            multiBtn1=""
            multiBtn2=""
            updateState={topBarClickAction}
          />

          <div className="col-view">
            <div
              className={[general.width100, general.bgCustomColor1].join(" ")}
            >
              <img
                className="w-70 mg-t-80"
                src={require("../icons/gift_box_icon.png")}
              />
            </div>

            <div className={[general.pd10px15px]}>
              <div
                className={[
                  general.pd10px15px,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
              >
                <span
                  className={[general.fontS15Px, general.colorLightGrey].join(" ")}
                >
                  Hi
                </span>
                <div className={[general.mgB15px].join(" ")}><span
                  className={[general.fontS15Px,general.mgB15px, general.colorLightGrey].join(" ")}
                >
                  We have a gift for you
                </span></div>
                <span
                  className={[general.fontS18Px, general.colorGrey].join(" ")}
                >
                  Please enter the gift code below
                </span>

                <input
                  type="text"
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.mgT15px,
                    general.borderRadius10px,
                    general.bgLightGrey,
                  ].join(" ")}
                  placeholder="Please enter redeem code"
                  autoComplete="off"
                  onChange={(e) =>
                    onInputValChange("redeemcardid", e.target.value)
                  }
                ></input>

                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT30px,
                    general.borderRadius30px,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => claimRedeemCard()}
                >
                  Receive
                </div>
              </div>
              <div className={general.redeemContainer}>
      <div className={general.titleContainer}>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill="#f95959"
      width="30"
      height="50"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12.0769C15 13.1282 15.8139 14 16.8498 14C17.8857 14 18.6996 13.1282 18.6996 12.0513V7H29.3005V12.0769C29.3005 13.1282 30.1144 14 31.1502 14C32.1861 14 33 13.1282 33 12.0513V7H33.24C34.5191 7 35.7856 7.25193 36.9673 7.74141C38.149 8.23089 39.2228 8.94834 40.1272 9.85278C41.0317 10.7572 41.7491 11.831 42.2386 13.0127C42.7281 14.1944 42.98 15.4609 42.98 16.74V34.26C42.98 35.5391 42.7281 36.8056 42.2386 37.9873C41.7491 39.169 41.0317 40.2428 40.1272 41.1472C39.2228 42.0517 38.149 42.7691 36.9673 43.2586C35.7856 43.7481 34.5191 44 33.24 44H14.74C9.36 44 5 39.64 5 34.24V16.74C5 14.1568 6.02618 11.6794 7.85278 9.85278C9.67938 8.02618 12.1568 7 14.74 7H15V12.0769Z"
        fill="#f95959"
      />
      <path
        d="M16.8438 13.6396C16.0038 13.6396 15.3438 12.9596 15.3438 12.1396V6.55957C15.3438 6.16175 15.5018 5.78021 15.7831 5.49891C16.0644 5.21761 16.4459 5.05957 16.8438 5.05957C17.2416 5.05957 17.6231 5.21761 17.9044 5.49891C18.1857 5.78021 18.3438 6.16175 18.3438 6.55957V12.1196C18.3438 12.9596 17.6837 13.6396 16.8438 13.6396Z"
        fill="#f95959"
      />
      <path
        d="M31.1406 13.5956C30.3006 13.5956 29.6406 12.9156 29.6406 12.0956V6.51562C29.6406 6.1178 29.7987 5.73627 30.08 5.45496C30.3613 5.17366 30.7428 5.01563 31.1406 5.01562C31.5384 5.01562 31.92 5.17366 32.2013 5.45496C32.4826 5.73627 32.6406 6.1178 32.6406 6.51562V12.0756C32.6406 12.9156 31.9806 13.5956 31.1406 13.5956Z"
      />
      <path
        d="M29.5588 27.4199H14.7188C14.3209 27.4199 13.9394 27.2619 13.6581 26.9806C13.3768 26.6993 13.2188 26.3177 13.2188 25.9199C13.2188 25.5221 13.3768 25.1406 13.6581 24.8593C13.9394 24.578 14.3209 24.4199 14.7188 24.4199H29.5588C29.9566 24.4199 30.3381 24.578 30.6194 24.8593C30.9007 25.1406 31.0588 25.5221 31.0588 25.9199C31.0588 26.3177 30.9007 26.6993 30.6194 26.9806C30.3381 27.2619 29.9566 27.4199 29.5588 27.4199ZM23.9987 34.8439H14.7188C14.3209 34.8439 13.9394 34.6859 13.6581 34.4046C13.3768 34.1233 13.2188 33.7417 13.2188 33.3439C13.2188 32.9461 13.3768 32.5646 13.6581 32.2833C13.9394 32.002 14.3209 31.8439 14.7188 31.8439H23.9987C24.3966 31.8439 24.7781 32.002 25.0594 32.2833C25.3407 32.5646 25.4987 32.9461 25.4987 33.3439C25.4987 33.7417 25.3407 34.1233 25.0594 34.4046C24.7781 34.6859 24.3966 34.8439 23.9987 34.8439Z"
        fill="#fff"
      />
    </svg>
        <span className={general.title}>History</span>
      </div>
      <div className={general.itemsBox}>
        {records.map((record, index) => (
          <div key={index} className={general.recordItem}>
            <div className={general.itemLeft}>
              <div className={general.itemTitle}>Successfully received</div>
              <span className={general.itemDate}>{record.date}</span>
            </div>
            <div className={general.itemRight}>
              <img
                className={general.itemImage}
                src={require('../media/icons/walletrechargeheader.png')}
                alt="wallet"
              />
              <span className={general.itemAmount}>{record.amount}</span>
            </div>
          </div>
        ))}
        </div>
        <div className="norecord">No more</div>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedeemGiftcard;
