import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar2";
import { getStorage } from "../modals/Storage";
import "../../MainStyle.css";
import general from "../css/General.module.css";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

function InvitationRewardRules() {
  const navigate = useNavigate();
  const [notifydata, setNotificationData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [pageConst] = useState({
    pageTitle: "System Notifications",
  });

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      getNotificationDetails();
    }
  }, []);

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const getNotificationDetails = async () => {
    updateLoadingStatus(true);
    try {
      const response = await fetch(
        `${API_ACCESS_URL}?USER_ID=${getStorage("uid")}&PAGE_NUM=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-notifications",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        }
      );

      const data = await response.json();
      updateLoadingStatus(false);
      if (data.status_code === "success") {
        setNotificationData(data.data || []);
      }
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
      updateLoadingStatus(false);
    }
  };

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={() => {}}
        />

        <div className={[general.colView, general.mgB10px].join(" ")}>
          <div
            className={[
              general.viewCenter,
              general.colView,
              general.pd5px15px,
              general.mgT50px,
            ].join(" ")}
          >
            <LoadingDialog intentData={showLoading} />
            {notifydata.length > 0 ? (
              notifydata.map((notification, index) => (
                <div key={index} className="sysMessage__container-msgWrapper__item">
                  <div className="sysMessage__container-msgWrapper__item-title">
                  <svg className="svg-icon icon-notification">
            <use xlinkHref="#icon-notification"></use>
          </svg>
                    <span>{notification.t_msg_header}</span>
                  </div>
                  <div className="sysMessage__container-msgWrapper__item-time">
                    {notification.t_time_stamp}
                  </div>
                  <div className="sysMessage__container-msgWrapper__item-content">
                    {notification.t_msg_body}
                  </div>
                </div>
              ))
            ) : (
              <div>No notifications available.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationRewardRules;
