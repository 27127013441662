import { useLocation } from 'react-router-dom';
import Wingo1Min from './Game';
import Wingo3Min from './Game2';
import Wingo5Min from './Game3';
import Wingo10Min from './Game4';

const WingoHandler = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  switch (id) {
    case '1':
      return <Wingo1Min />;
    case '2':
      return <Wingo3Min />;
    case '3':
      return <Wingo5Min />;
    case '4':
      return <Wingo10Min />;
    default:
      return;
  }
};

export default WingoHandler;
