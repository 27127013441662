import React, { useState, useEffect } from "react";
import VerticalPicker from "../other-components/VerticalPicker";
import { Box } from "@mui/material";

const DatePickerBody = ({ month, day, daysInMonth, setYear, setMonth, setDay }) => {

  const currentYear = new Date().getFullYear();

  const yearOptions = [currentYear - 3, currentYear - 2,currentYear - 1, currentYear];
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
      }}
    >
      <VerticalPicker
        initialValue={currentYear}
        onChange={setYear}
        options={yearOptions}
      />
      <VerticalPicker
        initialValue={month}
        onChange={setMonth}
        options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
      />
      <VerticalPicker
        initialValue={day}
        onChange={setDay}
        options={[0, ...daysInMonth]}
      />
    </Box>
  );
};

export default DatePickerBody;
