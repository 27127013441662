import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import "../../MainStyle.css";
import general from "../css/General.module.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import { Drawer, Button } from "@mui/material";
import DatePickerHeader from "../other-components/DatePickerHeader";
import DatePickerBody from "../other-components/DatePickerBody";
import TierPickerHeader from "../other-components/TierPickerHeader"; // Import TierPickerHeader
import TierPickerBody from "../other-components/TierPickerBody"; // Import TierPickerBody
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import { getStorage, deleteStorage } from "../modals/Storage";
import { copyText } from "../modals/Constants";
import ToastDialog from "../dialogs/ToastDialog/toast";

const getDaysInMonth = (year, month) => {
  return Array.from(
    { length: new Date(year, month, 0).getDate() },
    (_, i) => i + 1
  );
};

function TeamReport() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [pageConst, setConstants] = useState({
    pageTitle: "Subordinate Data",
    recordList: [],
  });

  // DatePicker states
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const initialYear = parseInt(today.split("-")[0], 10);
  const initialMonth = parseInt(today.split("-")[1], 10);
  const initialDay = parseInt(today.split("-")[2], 10);
  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState(initialMonth);
  const [day, setDay] = useState(initialDay);

  
  const [tierPickerOpen, setTierPickerOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState("All"); // Default tier selection
  const [tempSelectedTier, setTempSelectedTier] = useState("All");
  const [showLoading, setShowLoading] = useState(false);
  const [originalRecordList, setOriginalRecordList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [data, setData] = useState([]);
  const [userUID, setUserUID] = useState('');
  const onToastChange = (data, msg) => {};
  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const handleDateChange = () => {
    const formattedDate = `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
    setSelectedDate(formattedDate);
    setDatePickerOpen(false);
  };
  const copyID = (Id) => {
    copyText(Id);
    showToast("Copy successfully",2500,100,1);
  };
  const handleTierChange = () => {
    let tierValue = tempSelectedTier;
    if (tierValue !== "All") {
      tierValue = parseInt(tierValue.split(" ")[1], 10);
    }
    setSelectedTier(tierValue === "All" ? "All" : tierValue); // Set as "All" or the corresponding number
    setTierPickerOpen(false);
  };

  const handleTierDrawerToggle = () => {
    setTempSelectedTier("All");
    setTierPickerOpen(!tierPickerOpen);
  };

  const handleInputChange = (e) => {
    setUserUID(e.target.value);
  };

  const handleSearchClick = () => {
    if (userUID) {
      // Reset the filtered list to the original record list before filtering
      const filtered = originalRecordList.filter((record) =>
        record.userID.toString().toLowerCase().includes(userUID.toLowerCase())
      );
      setRecordList(filtered.length > 0 ? filtered : []);
    } else {
      // If no userUID is provided, show all records
      getAllMembers();
    }
  };
  
  function getAllMembers() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-team-reports",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });
  
        const data = await res.json();
        console.log(data);
        updateLoadingStatus(false);
        if (data.status_code === "success") {
          const filteredRecords = data.list.filter((record) => {
            const backendyr = record.userJoined.split("-")[0];
            const frontendyr = selectedDate.split("-")[0];
            const yearMatch = backendyr === frontendyr;
            const accountLevel =
              selectedTier === "All" || record.lv === parseInt(selectedTier);
            return yearMatch && accountLevel;
          });
          setOriginalRecordList(data.list); 
          setRecordList(filteredRecords);
          setData(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
        console.error("API request failed:", error);
      }
    };
  
    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }
  

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      getAllMembers();
    }
  }, [selectedDate, selectedTier]); 

  const handleDateDrawerToggle = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <div className="zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={true}
            multiBtn1=""
            multiBtn2=""
            updateState={() => {}}
          />
          <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
          <LoadingDialog intentData={showLoading} />
          <div className="col-view mg-b-15">
            <div className="col-view v-center w-100 pd-5-15 mg-t-45">
              <div className="TeamReport__C-head">
                <div className="TeamReport__C-head-fixed">
                  <div className="searchbar-container">
                    <input
                      onChange={handleInputChange}
                      type="text"
                      autoComplete="new-password"
                      className="searchbar-container__searchbar"
                      placeholder="Search subordinate UID"
                      maxLength="30"
                    />
                    <svg
                      onClick={handleSearchClick}
                      className="svg-icon icon-searchBtn searchIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 120 60"
                      fill="none"
                    >
                      <path
                        d="M0 30C0 13.4315 13.4315 0 30 0H90C106.569 0 120 13.4315 120 30C120 46.5685 106.569 60 90 60H30C13.4315 60 0 46.5685 0 30Z"
                        fill="#f95959"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M57.3763 10.166C47.8724 10.166 40.168 17.8705 40.168 27.3743C40.168 36.8782 47.8724 44.5827 57.3763 44.5827C60.5588 44.5827 63.5396 43.7187 66.0968 42.2126L72.6931 48.8089C74.0599 50.1757 76.276 50.1757 77.6428 48.8089C79.0097 47.442 79.0097 45.2259 77.6428 43.8591L71.2883 37.5046C73.3613 34.6626 74.5846 31.1613 74.5846 27.3743C74.5846 17.8705 66.8802 10.166 57.3763 10.166ZM44.8346 27.3743C44.8346 20.4478 50.4498 14.8327 57.3763 14.8327C64.3028 14.8327 69.918 20.4478 69.918 27.3743C69.918 34.3009 64.3028 39.916 57.3763 39.916C50.4498 39.916 44.8346 34.3009 44.8346 27.3743Z"
                        fill="#F7F8FF"
                      ></path>
                    </svg>
                  </div>
                  <div className="TeamReport__C-head-line2">
                    <div
                      className="TeamReport_head2_child"
                      onClick={handleTierDrawerToggle}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="default">
                        {selectedTier === "All"
                          ? "All"
                          : `Tier ${selectedTier}`}
                      </span>

                      <i className="fas fa-chevron-down"></i>
                    </div>
                    <div
                      className="TeamReport_head2_child2"
                      onClick={handleDateDrawerToggle}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="default">{selectedDate}</span>
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
                <div className="TeamReport_body">
                  <div className="header-container">
                    <div>
                      <div className="num">{data[0]?.rechargeCount || 0}</div>
                      <div>Deposit number</div>
                    </div>
                    <div>
                      <div className="num">
                        {data[0]?.rechargeAmountSum || "0.00"}
                      </div>
                      <div>Deposit amount</div>
                    </div>
                    <div>
                      <div className="num">{data[0]?.betCountSum || 0}</div>
                      <div>Number of bettors</div>
                    </div>
                    <div>
                      <div className="num">
                        {data[0]?.betAmountSum || "0.00"}
                      </div>
                      <div>Total bet</div>
                    </div>
                    <div>
                      <div className="num">
                        {data[0]?.firstRechargeCount || 0}
                      </div>
                      <div>Number of people making first deposit</div>
                    </div>
                    <div>
                      <div className="num">
                        {data[0]?.firstRechargeAmount || "0.00"}
                      </div>
                      <div>First deposit amount</div>
                    </div>
                  </div>
                  <div
                    className="infiniteScroll"
                    id="refreshec8bd3f47ea14415aa9e2b72ac79f1bd"
                  >
                    <div className="TeamReport__C-body">
                      {recordList.map((record, index) => (
                        <div className="TeamReport__C-body-item" key={index}>
                          <div className="TeamReport__C-body-item-head">
                            <div className="title">UID: {record.userID}</div>
                            <svg
                              onClick={() => copyID(record.userID)}
                              className="svg-icon icon-copy"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 48 48"
                              fill="none"
                            >
                              <path
                                d="M13 12.4316V7.8125C13 6.2592 14.2592 5 15.8125 5H40.1875C41.7408 5 43 6.2592 43 7.8125V32.1875C43 33.7408 41.7408 35 40.1875 35H35.5163"
                                stroke="currentColor"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M32.1875 13H7.8125C6.2592 13 5 14.2592 5 15.8125V40.1875C5 41.7408 6.2592 43 7.8125 43H32.1875C33.7408 43 35 41.7408 35 40.1875V15.8125C35 14.2592 33.7408 13 32.1875 13Z"
                                stroke="currentColor"
                                strokeWidth="4"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                          <div className="TeamReport__C-body-item-detail">
                            <div className="TeamReport__C-body-item-detail-lv">
                              Level<span>{record.lv}</span>
                            </div>
                            <div className="TeamReport__C-body-item-detail-commission">
                              Deposit amount<span>{record.rechargeAmount}</span>
                            </div>
                            <div className="TeamReport__C-body-item-detail-commission">
                              Bet amount<span>{record.lotteryAmount}</span>
                            </div>
                            <div className="TeamReport__C-body-item-detail-commission">
                              Commission<span>{record.userComission}</span>
                            </div>
                            <div className="TeamReport__C-body-item-detail-time">
                              Time<span>{selectedDate}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="TeamReport__C-body-end">No more</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Drawer
          anchor="bottom"
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              minHeight: "320px",
              margin: "0 auto",
              maxWidth: "430px",
              backgroundColor: "white",
              color: "black",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              overflowY: "auto",
            },
          }}
        >
          <DatePickerHeader
            onCancel={() => setDatePickerOpen(false)}
            onConfirm={handleDateChange}
          />
          <DatePickerBody
            year={year}
            month={month}
            day={day}
            daysInMonth={getDaysInMonth(year, month)}
            setYear={setYear}
            setMonth={setMonth}
            setDay={setDay}
            sx={{ width: "100%" }}
          />
        </Drawer>
        <Drawer
          anchor="bottom"
          open={tierPickerOpen}
          onClose={() => setTierPickerOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              minHeight: "320px",
              margin: "0 auto",
              maxWidth: "430px",
              backgroundColor: "white",
              color: "black",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              overflowY: "auto",
            },
          }}
        >
          <TierPickerHeader
            onCancel={() => setTierPickerOpen(false)}
            onConfirm={handleTierChange}
          />
          <TierPickerBody
            Tier={tempSelectedTier} // Use temporary tier
            setTier={setTempSelectedTier} // Update temporary tier
          />
        </Drawer>
      </div>
    </div>
  );
}

export default TeamReport;
