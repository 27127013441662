import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import general from "../css/General.module.css";
const GameTopBar = ({
  intentData,
  multiBtn,
  multiBtn1,
  multiBtn2,
  updateState,
  background = null,
}) => {
  const navigate = useNavigate();

  return (
    <nav
      className={`ps-fx ps-tp res-wth h-55-p pd-10 row-view sb-view z-i-120 ${
        background === 1
          ? general.bgCustomColor1
          : general.bgWhite
      }`}
    >
      <div className="row-view">
        <Link
          className={`${multiBtn1 === "" ? "" : "hide-v"}`}
          onClick={() => navigate(-1)}
        >
          <svg
            height="30"
            width="30"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            style={{ enableBackground: "new 0 0 512 512" }}
          >
            <polygon
              points="352,128.4 328,96 160,256 328,416 352,383.6 200,256"
              fill={background === 1 ? '#ffffff' : '#1E2637'}
            />
          </svg>
        </Link>
      </div>

      {/* Center Title */}
      <div className="nav-title">
        <span
          className={`ft-sz-20 mg-l-15 ${
            intentData.pageTitle === "" ? "hide-v" : "",background === 1
            ? general.colorWhite
            : general.colorBlack
        }
          }`}
        >
          {intentData.pageTitle}
        </span>
      </div>

      {/* Button for multiBtn2 */}
      <span
        className={`cl-black ft-sz-18 ${multiBtn ? "" : "hide-v"}`}
        onClick={() => updateState("multiBtn2")}
      >
        {multiBtn2}
      </span>
    </nav>
  );
};

export default GameTopBar;
