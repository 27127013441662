import React, { useEffect, useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import rechargeCardBackground from '../media/icons/backgrounds/rechargecard.png';
import Grid from "@mui/material/Grid";
import '../../MainStyle.css';
import Typography from "@mui/material/Typography";
import { getStorage } from "../modals/Storage";
import general from "../css/General.module.css";
import {
  Box,
  Button,
  SvgIcon,
  TextField,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
const RhombusIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 2L22 12L12 22L2 12L12 2Z" />
  </SvgIcon>
);

const depositAmounts = [
  100, 500, 800, 1000, 2000, 3000, 5000, 10000, 20000, 30000, 40000, 50000,
];

const PromotionMain = ({ children }) => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setVh);
    setVh();

    return () => window.removeEventListener("resize", setVh);
  }, []);

  const [remainingTime, setRemainingTime] = useState(300); // 5 minutes in seconds
  const timerRef = useRef(null);
  const [amount, setAmount] = useState("");
  const [walletAmount, setWalletAmount] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const conversionRate = 91; // 1 USDT = 91 Rs
  const [depositHistories, setDepositHistories] = useState([]);

  // Function to determine the color based on deposit status
  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "#0F6518"; // Green for completed
      case "pending":
        return "#FFA500"; // Orange for pending
      case "failed":
        return "#FF0000"; // Red for failed
      default:
        return "#757575"; // Grey for unknown statuses
    }
  };

  useEffect(() => {
    // Fetching deposit history data from the API
    const fetchDepositHistory = async () => {
    
    };

    fetchDepositHistory();
  }, []);

  const [walletData, setWalletData] = useState(0);
  const [openDepositDialog, setOpenDepositDialog] = useState(false);
  const [utr, setUtr] = useState("");
  const [utrAlert, setUtrAlert] = useState(false);
  const [duplicateUtrAlert, setDuplicateUtrAlert] = useState("");
  const [depositRequests, setDepositRequests] = useState([]);
  const [usdtAmount, setUsdtAmount] = useState("");
  const handleUtrChange = (event) => {
    setUtr(event.target.value);
  };
  const handleUsdtInputChange = (event) => {
    const value = event.target.value;
    setUsdtAmount(value);
    if (value !== "") {
      setAmount((parseFloat(value) * conversionRate).toFixed(2));
    } else {
      setAmount("");
    }
  };

  // Define the function to close the dialog
  const closeDepositDialog = () => {
    setOpenDepositDialog(false);
  };

  const [paymentMode, setPaymentMode] = useState("UPI x QR");
  const [userData, setUserData] = React.useState(null);
  useEffect(() => {
    if (paymentMode === "USDT" && amount !== "") {
      setUsdtAmount((parseFloat(amount) / conversionRate).toFixed(2));
    }
  }, [paymentMode]);

  const handleButtonClick = (value) => {
    setAmount(value.toString());
    if (paymentMode === "USDT") {
      setUsdtAmount((value / conversionRate).toFixed(2));
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setAmount(value);
    if (paymentMode === "USDT" && value !== "") {
      setUsdtAmount((parseFloat(value) / conversionRate).toFixed(2));
    }
  };
  const sendDepositRequest = async () => {
    setUtrAlert(false);
    setDuplicateUtrAlert("");
    if (!utr) {
      setUtrAlert(true);
      return;
    }

    // Check if the UTR is already used by the current user
    if (
      depositRequests.some(
        (request) => request.utr === utr && request.userId === userData.userId
      )
    ) {
      setDuplicateUtrAlert(
        "This UTR has already been used. Please enter a unique UTR."
      );
      return;
    }

  };

  useEffect(() => {
    if (openDepositDialog) {
      timerRef.current = setInterval(() => {
        setRemainingTime((time) => time - 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setRemainingTime(300);
    }

    return () => clearInterval(timerRef.current);
  }, [openDepositDialog, imageUrl]);

  useEffect(() => {
    if (remainingTime === 0) {
      setOpenDepositDialog(false);
    }
  }, [remainingTime]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  // make a random 10 digit transaction id
  const transaction = Math.floor(1000000000 + Math.random() * 9000000000);
  const [paymentUrl, setPaymentUrl] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    
  };

  const [upiId, setUpiId] = useState("best4world6677@okaxis");
  const [usdtWalletAddress, setUsdtWalletAddress] = useState("");
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(-1);
  };

  const handlePage = () => {
    navigate("/rechargerecords");
  };

  const paymentModes = {
    "UPI x QR": [
      { name: "UPI-Ceco", balance: "500 - 50K" },
      { name: "UPI-Super", balance: "300 - 50K" },
      { name: "UPI-OoPay", balance: "100 - 50K" },
    ],
    UPIxPAYTM: [
      { name: "PAYTM-Fast", balance: "100 - 10K" },
      { name: "PAYTM-Safe", balance: "500 - 20K" },
      { name: "PAYTM-Safe", balance: "500 - 20K" },
    ],
    USDT: [
      { name: "USDT-Tether", balance: "50 - 100 USDT" },
      { name: "USDT-Binance", balance: "100 - 200 USDT" },
    ],
  };
  const defaultChannelMap = {
    "UPI x QR": 0, 
    "UPIxPAYTM": 0, 
    "USDT": 0, 
  };

  const [selectedChannel, setSelectedChannel] = useState(defaultChannelMap[paymentMode] || 0);
  useEffect(() => {
    setSelectedChannel(defaultChannelMap[paymentMode] || 0);
  }, [paymentMode]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
   
    };

    fetchUserData();
  }, []);

  const [get1, setGet1] = useState("");
  const [get2, setGet2] = useState("");
  useEffect(() => {
    const handleGet = () => {
    };

    handleGet();
  }, []);
  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
    <div
      className={[
        general.height100vh,
        general.respWidth,
        general.overflowScrollY,
        general.appContentsBackground,
        general.hideScrollBar,
      ].join(" ")}
    >
    <div>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
        >
          <Box flexGrow={1}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#ffffff ",
                padding: "7px 4px",
                color: "white",
              }}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  <IconButton
                    sx={{ color: "black", mr: 8 }}
                    onClick={handleRedirect}
                  >
                    <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "black",
                      flexGrow: 1,
                      textAlign: "center",
                      mr: 3,
                      lineHeight: 1.5,
                    }}
                  >
                    Deposit
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: "left",
                      color: "black",
                      fontSize: "13px",
                      flexGrow: 1,
                      fontfamily: "Roboto",
                    }}
                    onClick={handlePage}
                  >
                    Deposit history
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              mt={0}
              style={{
                backgroundImage: `url(${rechargeCardBackground})`,
                borderRadius: 8,
                padding: 8,
                backgroundSize: "cover",
                width: "97%",
                marginLeft: "auto",
                marginRight: "auto",
                height: "165px",
                marginTop: "1rem",
                position: "relative",
              }}
            >
              <Grid container item alignItems="center">
                <Grid item xs={1.5} align="center">
                  <img
                    src={require("../media/icons/walletrechargeheader.png")}
                    alt="Your Image"
                    style={{ maxWidth: "50%" }}
                  />
                 <img
        src={require("../media/icons/recharge_header_tool1.png")}
        style={{
          height: "20px",
          width: "32px",
          position: "absolute",
          bottom: "13px", // Adjust this value for vertical positioning
          left: "6%", // Use percentage for responsive margin
        }}
      />
                    
                </Grid>
                <Grid item xs={10.5}>
                  <Typography
                    fontSize="15px"
                    sx={{ color: "#ffffff" }}
                    align="left"
                  >
                    Balance
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item alignItems="center" mt={-3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Typography
                    fontSize="30px"
                    sx={{ color: "#ffffff", fontWeight: "bold" }}
                  >
                    ₹ {Number(getStorage("balance")).toFixed(2).toLocaleString()}
                  </Typography>
                  <img
                      src={require("../media/icons/refresh_icon.png")}
                      style={{
                        height: "20px",
                        width: "30px",
                        marginLeft : "15px"
                      }}
                    />
                </Grid>
              </Grid>

              <Grid
                container
                item
                alignItems="center"
                style={{ marginTop: 16 }}
              >
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9e9c9b" }}
                  ></Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              mt={0}
              style={{
                width: "97%",
                marginLeft: "auto",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              {Object.keys(paymentModes).map((mode) => (
                <Grid item xs={4} key={mode}>
                  <div
                    style={{
                      textAlign:"center",
                      background:
                        paymentMode === mode
                          ? "linear-gradient(90deg, rgb(255, 142, 138) 0%, rgb(255, 191, 160) 100%)"
                          : "#ffffff",
                      borderRadius: 8,
                      color: paymentMode === mode ? "#ffffff" : "black",
                      padding: 16,
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={() => {
                      setPaymentMode(mode);
                      setSelectedChannel(null); // Reset selected channel when changing mode
                    }}
                  >
                    <img
                      src={`channels/${mode}.png`}
                      alt={mode}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        height: "55px",
                        width: "64px",
                      }}
                    />
                    <Typography
                      variant="caption"
                      align="center"
                      style={{ marginTop: 8 }}
                    >
                      {mode}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>

            {/* Channels Based on Payment Mode */}
            <Box
              sx={{
                borderRadius: "12px",
                padding: "12px",
                paddingBottom: "15px",
                paddingTop: "15px",
                backgroundColor: "#fff",
                boxShadow: "rgb(208 208 237 / 36%) 0px 0.05333rem 0.21333rem",
                width: "95%",
                marginLeft: "10px",
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <img
                      src={require("../media/icons/lightning.png")}
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                <Typography
                  variant="h6"
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  Select channel
                </Typography>
              </Box>

              <div className="grid-container">
      {paymentModes[paymentMode].map((channel, index) => (
        <div
          key={index}
          className={`grid-item ${selectedChannel === index ? "selected" : ""}`}
          onClick={() => setSelectedChannel(index)}
        >
          <div className="card-items">
            <div className={`card-content ${selectedChannel === index ? "selected" : ""}`}>
              <p className="channel-name">
                {channel.name}
              </p>
              <p className="channel-balance">
                Balance: {channel.balance}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
            </Box>

            <Box
              sx={{
                bgcolor: "#ffffff",
                p: 2,
                borderRadius: 2,
                margin: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <img
                      src={require("../media/icons/walletrecharge.png")}
                      style={{
                        height: "30px",
                        width: "30px",
                      }} />
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Deposit amount
                </Typography>
              </Box>

              <Grid container spacing={1}>
                {depositAmounts.map((value) => (
                  <Grid item xs={4} key={value}>
                    <Button
                      variant="outlined"
                      onClick={() => handleButtonClick(value)}
                      startIcon={
                        <Typography sx={{ color: "#666", fontsize: "16px" }}>₹</Typography>
                      }
                      sx={{
                        width: "100%",
                        bgcolor: "white",
                        color: "#000000",
                        border: "0.01333rem solid rgb(223, 222, 222)",
                        justifyContent: "center", // Center the content
                        "&:active": {
                          bgcolor: "#f0f0f0",
                          borderColor: "gray",
                        },
                        "& .MuiButton-startIcon": {
                          position: "absolute", // Keep the icon at the start
                          left: "16px", // Adjust to your preference
                        },
                      }}
                    >
                      <Typography variant="h7">
                        {value >= 1000 ? `${value / 1000}K` : value}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
              {paymentMode === "USDT" && (
                <TextField
                  fullWidth
                  placeholder="Please enter USDT amount"
                  value={usdtAmount}
                  onChange={handleUsdtInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">USDT</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleUsdtInputChange({ target: { value: "" } })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "1rem",
                    bgcolor: "grey.200",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              )}

              <Box sx={{ position: "relative", mt: 2, color:"red" }}>
                <TextField
                  fullWidth
                  placeholder="Please enter the amount"
                  value={amount}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <div className="depositamount-currency" position="start">₹</div>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleInputChange({ target: { value: "" } })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    bgcolor: "grey.200",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Box>

              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  textTransform: "initial",
                  bgcolor: "rgb(250, 90, 90)",
                  borderRadius: "16px",
                  color: "white",
                  "&:hover": { bgcolor: "rgb(250, 90, 90)" },
                  boxShadow: "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (amount < 100) {
                    alert("Amount must be at least 100");
                  } else {
                    if (paymentMode === "UPIxPAYTM") {
                      handleSubmit(e);
                    } else {
                      setOpenDepositDialog(true);
                    }
                  }
                }}
              >
                Deposit
              </Button>
            </Box>
            <div>
              {" "}
              {paymentUrl && <a href={paymentUrl}>Proceed to Payment</a>}
            </div>

            <Box
              sx={{
                margin: "10px auto", // Centered horizontally with automatic margins
                borderRadius: 2,
                bgcolor: "white",
                // boxShadow: 3, // Adding shadow for a more defined appearance
                mt: 2, // Increased top margin for better spacing
                width: "94%",
              }}
            >
              <Box sx={{ padding: 2 }}>
                {" "}
                {/* Adjusted padding for better spacing */}
                <Box display="flex" alignItems="center" mb={2}>
                  {" "}
                  {/* Increased bottom margin */}
                  <img
                      src={require("../media/icons/recharge_instructions.png")}
                      style={{
                        height: "30px",
                        width: "30px",
                      }} />
                  <Typography variant="body1" fontWeight="bold" align="left">
                    Recharge Instructions
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #E0E0E0", // Light grey border color
                    borderRadius: 1, // Rounded corners
                    padding: 2, // Increased padding inside the border
                    ml: 0, // No left margin for alignment
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1.5, // Increased bottom margin
                      color: "grey",
                    }}
                    align="left"
                  >
                    <RhombusIcon
                      sx={{ fontSize: 12, color: "rgb(250, 90, 90)", mr: 1 }} // Slightly larger icon for better visibility
                    />
                    If the transfer time is up, please fill out the deposit form
                    again.
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1.5, // Increased bottom margin
                      color: "grey",
                    }}
                    align="left"
                  >
                    <RhombusIcon
                      sx={{ fontSize: 12, color: "rgb(250, 90, 90)", mr: 1 }} // Slightly larger icon for better visibility
                    />
                    The transfer amount must match the order you created,
                    otherwise the money cannot be credited successfully.
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1.5, // Increased bottom margin
                      color: "grey",
                    }}
                    align="left"
                  >
                    <RhombusIcon
                      sx={{ fontSize: 12, color: "rgb(250, 90, 90)", mr: 1 }} // Slightly larger icon for better visibility
                    />
                    If you transfer the wrong amount, our company will not be
                    responsible for the lost amount!
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "grey",
                    }}
                    align="left"
                  >
                    <RhombusIcon
                      sx={{ fontSize: 12, color: "rgb(250, 90, 90)", mr: 1 }} // Slightly larger icon for better visibility
                    />
                    Note: Do not cancel the deposit order after the money has
                    been transferred.
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Dialog
              open={openDepositDialog}
              onClose={closeDepositDialog} // Handle close event
              disableBackdropClick
              disableEscapeKeyDown
              sx={{
                "& .MuiDialog-paper": {
                  backgroundColor: "#e8f5e9", // Light green background
                  borderRadius: "16px",
                },
              }}
            >
              <DialogTitle
                sx={{
                  backgroundColor: "#1f6e1b", // Green header
                  color: "white",
                  fontWeight: "bold",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                }}
              >
                Deposit
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography
                      variant="h6"
                      sx={{ color: "#2e7d32", paddingTop: "1rem" }}
                    >
                      Remaining Time
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      align="right"
                      sx={{ color: "#d32f2f", paddingTop: "1rem" }} // Red color for the countdown
                    >
                      {Math.floor(remainingTime / 60)}:
                      {remainingTime % 60 < 10 ? "0" : ""}
                      {remainingTime % 60}
                    </Typography>
                  </Grid>
                  {paymentMode === "UPI x QR" && (
                    <>
                      {imageUrl ? (
                        <Grid item xs={12}>
                          <img
                            src={imageUrl}
                            alt="QR Code"
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              display: "block",
                              margin: "0 auto",
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Typography>Loading QR Code...</Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          UPI ID: {get1 ? get1 : "Loading"}
                          <IconButton
                            onClick={() =>
                              copyToClipboard(get1 ? get1 : "Loading")
                            }
                          >
                            <FileCopyIcon sx={{ color: "#2e7d32" }} />
                          </IconButton>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="utr"
                          label="UTR"
                          value={utr}
                          onChange={handleUtrChange}
                          sx={{
                            width: "100%",
                            mb: 2,
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#f1f8e9", // Light green background
                              borderRadius: "8px",
                              "& fieldset": {
                                border: "1px solid #c8e6c9", // Light green border
                              },
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {paymentMode === "USDT" && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          USDT Wallet Address: {get2 ? get2 : "Loading"}
                          <IconButton
                            onClick={() =>
                              copyToClipboard(get2 ? get2 : "Loading")
                            }
                          >
                            <FileCopyIcon sx={{ color: "#2e7d32" }} />
                          </IconButton>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          Conversion Rate: 1 USDT = 83.42 INR
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="utr"
                          label="UTR"
                          value={utr}
                          onChange={handleUtrChange}
                          sx={{
                            width: "100%",
                            mb: 2,
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "#f1f8e9", // Light green background
                              borderRadius: "8px",
                              "& fieldset": {
                                border: "1px solid #c8e6c9", // Light green border
                              },
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {paymentMode === "UPIxPAYTM" && (
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Currently this payment option is not available.
                      </Typography>
                    </Grid>
                  )}
                  {utrAlert && (
                    <Grid item xs={12}>
                      <Alert severity="error" sx={{ marginBottom: 2 }}>
                        UPI ID or QR Scan is required
                      </Alert>
                    </Grid>
                  )}
                  {duplicateUtrAlert && (
                    <Grid item xs={12}>
                      <Alert severity="error" sx={{ marginBottom: 2 }}>
                        {duplicateUtrAlert}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "center",
                  borderTop: "1px solid #c8e6c9", // Light green border
                  padding: "16px",
                }}
              >
                <Button
                  onClick={closeDepositDialog} // Cancel button to close the dialog
                  sx={{
                    backgroundColor: "#e0e0e0", // Red color for cancel button
                    color: "black",
                    textTransform: "initial",
                    "&:hover": {
                      backgroundColor: "#e0e0e0", // Darker red on hover
                    },
                    mr: 2, // Margin-right for spacing
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={sendDepositRequest}
                  sx={{
                    backgroundColor: "#1f6e1b",
                    color: "white",
                    textTransform: "initial",

                    "&:hover": {
                      backgroundColor: "#1f6e1b", // Darker green on hover
                    },
                  }}
                >
                  Send Request
                </Button>
              </DialogActions>
            </Dialog>

            <br />
            {/* content end */}

            <Box sx={{ paddingX: "1rem" }}>
              {/* Heading */}
              <Typography
                variant="h6"
                sx={{
                  textAlign: "left",
                  marginBottom: "10px",
                  fontWeight: "bold",
                  color: "#333",
                  display: "flex",
                  // textAlign: "center",
                  ml: 1,
                }}
              >
                <img
        src={require("../media/icons/deposit_history_rechargepage.png")}
        style={{
          height: "30px",
          width: "30px",
        }}
      />
                Recent Deposit History
              </Typography>

              <div>
                {depositHistories.length > 0 ? (
                  depositHistories.map((deposit) => (
                    <Card
                      key={deposit.depositId}
                      sx={{
                        marginBottom: "16px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      <CardContent
                        sx={{ padding: "16px", position: "relative" }}
                      >
                        <Grid
                          container
                          mt={-1}
                          mb={1}
                          sx={{ borderBottom: "1px solid #eee" }}
                        >
                          <Grid item xs={3}>
                            <Box
                              sx={{
                                backgroundColor: getStatusColor(
                                  deposit.depositStatus
                                ),
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                padding: "4px 0px",
                                marginBottom: "10%",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              Deposit
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body2"
                              sx={{
                                position: "absolute",
                                right: "16px",
                                top: "13px",
                                fontSize: "14px",
                                color: getStatusColor(deposit.depositStatus),
                                fontWeight: "bold",
                              }}
                            >
                              {deposit.depositStatus}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={3} textAlign="left">
                            <Typography
                              variant="body2"
                              sx={{ color: "#757575" }}
                            >
                              Balance
                            </Typography>
                          </Grid>
                          <Grid item xs={9} textAlign="end">
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "500", color: "orange" }}
                            >
                              ₹{deposit.depositAmount}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} textAlign="left">
                            <Typography
                              variant="body2"
                              sx={{ color: "#757575" }}
                            >
                              Type
                            </Typography>
                          </Grid>
                          <Grid item xs={9} textAlign="end">
                            <Typography variant="body2">
                              {deposit.depositMethod}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} textAlign="left">
                            <Typography
                              variant="body2"
                              sx={{ color: "#757575" }}
                            >
                              Time
                            </Typography>
                          </Grid>
                          <Grid item xs={9} textAlign="end">
                            <Typography variant="body2">
                              {new Date(deposit.depositDate).toLocaleString()}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} textAlign="left">
                            <Typography
                              variant="body2"
                              sx={{ color: "#757575" }}
                            >
                              Order number
                            </Typography>
                          </Grid>
                          <Grid item xs={8} textAlign="end">
                            <Typography variant="body2">
                              {deposit.depositId}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                    <img
                      src={require("../media/icons/recordempty.png")} // Replace with the correct path to your image file
                      alt="No data available"
                      style={{ width: "150px", marginBottom: "10px" }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        color: "#757575",
                        marginBottom:"1rem"
                      }}
                    >
                      No deposit history available.
                    </Typography>
                  </Box>
                )}
              </div>
            </Box>
          </Box>

          {children}
        </Box>
      </div>
      </div>
    </div>
  );
};

export default PromotionMain;
